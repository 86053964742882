// userReducer.js

import {
    GET_OTHER_USER_DETAIL,
    LOGOUT,
    SET_OTHER_USER_DETAIL,
    SET_USER_DATA,
    SET_INFLUENCER_DETAIL,
    GET_INFLUENCER_DETAIL
} from "../actions/userActions";


const initialState = {
    userData: null,
    otherUserDetail: null,
    influencerDetail: null
};

const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.payload,
            };
        case LOGOUT:
            return {
                ...state,
                userData: null
            }
        case GET_OTHER_USER_DETAIL:
            return state.otherUserDetail
        case SET_OTHER_USER_DETAIL:
            return {
                ...state,
                otherUserDetail: action.payload
            }
        case SET_INFLUENCER_DETAIL:
            return {
                ...state,
                influencerDetail: action.payload
            }
        case GET_INFLUENCER_DETAIL:
            return state.influencerDetail
        default:
            return state;
    }
};

export default userReducer;
