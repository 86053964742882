import { assets_path, getUserInfo, isTokenExpired } from "../../utils/Utils";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ROUTES } from "../../constants/Routes";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { endpoints } from "../../interceptors/endPoints";
import FormikInput from "../../components/FormikInput";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../store/actions/userActions";
import { useEffect, useState } from "react";
import { setAuthToken } from "../store/actions/tokenAction";
import { useTranslation } from "react-i18next";

const emailValidationSchema = Yup.object({
    email: Yup.string().email('Enter a valid email').required('Email is required'),
    password: Yup.string().min(3, 'Must be at least 6 characters long').required('Password is required'),
});

const usernameValidationSchema = Yup.object({
    email: Yup.string('Enter a valid username').required('Username is required'),
    password: Yup.string().min(3, 'Must be at least 6 characters long').required('Password is required'),
});

const LoginForm = (
    {
        onSubmit,
        tab = 1,
        error = "",
        t
    }
) => {

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={tab === 2 ? emailValidationSchema : usernameValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit(values, setSubmitting);
            }}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form className="flex flex-col items-center justify-start w-full gap-4 mt-4">
                    <FormikInput
                        type={tab === 2 ? 'email' : 'text'}
                        name="email"
                        placeholder={tab === 2 ? t('enter_email') : t('enter_username')}
                        icon="user"
                        onChange={(e) => {
                            setFieldValue('email', e.target.value);
                        }}
                        value={values.email}
                    />
                    <ErrorMessage name="email" component="span" className="text-red-500 text-xs text-start w-full" />
                    <FormikInput
                        type="password"
                        name="password"
                        placeholder={t('enter_password')}
                        icon="lock"
                        onChange={(e) => {
                            setFieldValue('password', e.target.value);
                        }}
                        value={values.password}
                    />
                    <ErrorMessage name="password" id="password" component="span" className="text-red-500 text-xs text-start  w-full" />
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-pink-600 text-white p-2 text-center w-full mt-4 rounded-sm text-sm flex flex-row items-center mx-auto gap-2"
                    >
                        <span className="w-full text-center">{t('log_in')} {isSubmitting && <i className="pi pi-spin pi-spinner text-sm"></i>} </span>
                    </button>
                    {
                        error && <span className="text-xs text-red-500 w-full text-start">{error}</span>
                    }
                </Form>
            )}
        </Formik>
    )
};

function Login() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    // const { requestInterceptor } = useAuthInterceptor();

    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(1);
    const [error, setError] = useState("");

    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.user.userData);

    useEffect(() => {
        console.log(isTokenExpired(), isAuthenticated === undefined);
        if (!isTokenExpired() && isAuthenticated !== undefined) {
            navigate('/');
        }
    }, []);

    const onChangeTab = (tab) => {
        setTab(tab);
    }
    // const axiosInstance = useAxiosInterceptor();

    const onSubmit = async (values, setSubmitting) => {


        const encodedEmail = values.email;
        const encodedPassword = values.password;
        const encodedLanguage = "en";
        let queryString = "";
        setError("");

        if (tab === 2) {
            queryString = `?email=${encodedEmail}&password=${encodedPassword}&language=${encodedLanguage}`;
        }
        else if (tab === 1) {
            queryString = `?name=${encodedEmail}&password=${encodedPassword}&language=${encodedLanguage}`;
        }

        const fullUrl = endpoints.LOGIN + queryString;

        const response = await axiosInstance.post(fullUrl);
        if (response.data.code == 400) {
            setError("Email or password is incorrect!");
        }
        else {
            const { access_token, expires_in } = response.data.data.login_info.original;
            const { user_info } = response.data.data;

            const expirationTime = Date.now() + expires_in * 1000;
            localStorage.setItem("token", JSON.stringify(access_token));
            dispatch(setAuthToken(access_token));
            localStorage.setItem('token_expires_in', JSON.stringify(expirationTime));
            // dispatch(setTokenTime(Date.now().toString()));
            fetchUserInfo(user_info.id);
        }

        setSubmitting(false);
    }

    async function fetchUserInfo(user_id) {
        const res = await getUserInfo(user_id);
        if (res.data) {
            dispatch(setUserData(res.data));
        }
        navigate('/');
    }

    // if (loading) {
    //     return (
    //         <div className="card flex flex-col items-center justify-center min-h-screen main-bg pb-12">
    //             <ProgressSpinner />
    //             <span className="text-sm text-gray-500 mt-2">Loading...</span>
    //         </div>
    //     )
    // }
    // else 
    return (
        <div className="flex flex-col items-center justify-center w-full text-dark min-h-screen h-full pb-12 main-bg text-gray-900">
            <div className="flex flex-col items-center justify-center h-[100vh] w-full">
                <img src={assets_path('imgs/logo.svg')} className="w-1/4 mb-4" />

                <div className="flex flex-col items-start justify-start gap-4 mt-4 w-full px-4">
                    <div className="flex flex-row items-start justify-between w-full px-4">
                        <div className="flex flex-col cursor-pointer" onClick={() => onChangeTab(1)}>
                            <span className={`text-sm font-semibold ${tab == 1 ? 'text-pink-600' : 'text-white'}`}>{t('account_login')}</span>
                            {tab == 1 && <div className="border-b-2 border-w-10 border-h-2 border-pink-600" />}
                        </div>
                        <div className="flex flex-col cursor-pointer" onClick={() => onChangeTab(2)}>
                            <span className={`text-sm font-semibold ${tab == 2 ? 'text-pink-600' : 'text-white'}`}>{t('email_login')}</span>
                            {tab == 2 && <div className="border-b-2 border-w-10 border-h-2 border-pink-600" />}
                        </div>
                    </div>

                    <LoginForm onSubmit={onSubmit} tab={tab} error={error} t={t} />

                    <div className="flex flex-col sm:flex-row items-start justify-between w-full px-4 gap-2 md:gap-4 mt-2">
                        <Link to={ROUTES.REGISTER} className="text-sm text-white">{t('register')}</Link>
                        <Link to={ROUTES.FORGET_PASSWORD} className="text-sm text-white">{t('forget_password')}</Link>
                    </div>

                    <Link to={ROUTES.HOME} className="text-sm text-white mx-auto mt-8">
                        {t('lets_go_shopping')} <i className="pi pi-chevron-right text-xs"></i>
                    </Link>

                </div>
            </div>
        </div>
    );
}

export default Login;