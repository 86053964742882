import { Link } from "react-router-dom";
import { assets_path } from "../utils/Utils";
import { ROUTES } from "../constants/Routes";
import { mediaBaseURL } from "../interceptors/axiosInterceptor";

function FollowedUsersList(
    {
        watchlist
    }
) {
    return (
        <div className="flex flex-row items-center justify-between gap-2 p-2 overflow-x-auto">
            <div className="flex flex-row items-start justify-start gap-2 overflow-x-auto w-full">
                {watchlist && watchlist.length
                    ? watchlist.map((user, i) =>
                        <Link
                            to={{
                                pathname: '/personal-details/' + user.user_info.id,
                                state: { userId: user.user_info.id }
                            }}
                            className="flex flex-col items-center justify-start gap-2 p-2 cursor-pointer" key={user._id}>
                            {user.user_info.avatar
                                ? <img src={mediaBaseURL + user.user_info.avatar} alt={user.name} className="w-[30px] rounded-full" />
                                : <img src={assets_path('avatar.jpg')} alt={user.name} className="w-[40px] rounded-full" />
                            }
                            <span className="text-white text-xs">{user.user_info.name}</span>
                        </Link>
                    )
                    : <>
                        <span className="text-sm text-gray-500">No following</span>
                    </>
                }
            </div>
            <Link to={ROUTES.FOLLOW_PAGE} className="flex flex-col items-center justify-center p-2 gap-2">
                <i className="pi pi-th-large w-[20px]" />
                <span className="text-[10px]">More</span>
            </Link>
        </div>
    );
}

export default FollowedUsersList;