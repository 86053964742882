export const SET_CURRENT_LANGUAGE = "SET_CURRENT_LANGUAGE";
// export const EN = "English";
// export const CN = "Chinese";
// export const FR = "French";
// export const AR = "Arabic";

export const setCurrentLanguage = (payload) => {
    return {
        type: SET_CURRENT_LANGUAGE,
        payload,
    }
}
