import { useNavigate, useParams } from "react-router";
import lang from "../locale/LocalizedString";
import { useEffect, useState } from "react";
import { assets_path, attentionUser, cancelAttentionUser, getUserInfo, getUsersVideos, getVideoList } from "../utils/Utils";
import VideoPreviewComponent from "../components/VideoPreviewComponent";
import { mediaBaseURL } from "../interceptors/axiosInterceptor";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { addToWatchlist, removeFromWatchlist } from "./store/actions/watchlistAction";


function PersonalInfo(
    {
        user,
        t
    }
) {
    return (
        <div className="flex flex-col items-start justify-start gap-1 w-full px-2 my-4">
            <span className="text-sm">{t('personal_info')}</span>
            <span className="text-sm text-gray-500">{user?.introduction}</span>
        </div>
    )
}

function TabView({ onChangeTab, activeTab, t }) {

    return (
        <div className="flex flex-row gap-2 px-2 items-center justify-start font-semibold w-full mb-2 border-t border-gray-800">
            <div className="cursor-pointer" onClick={() => onChangeTab(1)}>
                <span className={`text-xs ${activeTab === 1 ? 'text-pink-600 border-b-2 border-pink-600 font-semibold' : 'text-gray-400'} p-1 m-0 font-light`}>
                    {t('comprehensive')}
                </span>
            </div>
            <div className="cursor-pointer" onClick={() => onChangeTab(2)}>
                <span className={`text-xs ${activeTab === 2 ? 'text-pink-600 border-b-2 border-pink-600 font-semibold' : 'text-gray-400'} p-1 m-0 font-light`}>
                    {t('up_to_date')}
                </span>
            </div>
            <div className="cursor-pointer" onClick={() => onChangeTab(3)}>
                <span className={`text-xs ${activeTab === 3 ? 'text-pink-600 border-b-2 border-pink-600 font-semibold' : 'text-gray-400'} p-1 m-0 font-light`}>
                    {t('most_played')}
                </span>
            </div>
            <div className="cursor-pointer" onClick={() => onChangeTab(4)}>
                <span className={`text-xs ${activeTab === 4 ? 'text-pink-600 border-b-2 border-pink-600 font-semibold' : 'text-gray-400'} p-1 m-0 font-light`}>
                    {t('most_favorite')}
                </span>
            </div>
        </div>
    );
}

function PersonalDetails() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useParams();

    const [activeTab, setActiveTab] = useState(1);
    const [userDetails, setUserDetails] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const watchlist = useSelector((state) => state.watchlist);
    const { influencerDetail } = useSelector((state) => state.user);
    const [following, setFollowing] = useState(false);
    const [videosList, setVideosList] = useState([]);

    const dispatch = useDispatch();


    async function getPersonalInfo(user_id) {
        const info = await getUserInfo(user_id);
    }

    function isFollowing(currentUser) {
        // console.log(userDetails);
        let following = false;
        if (watchlist.length) {
            following = watchlist.filter((user) => parseInt(user.target_user_id) === currentUser?.id);
            console.log(following);
        }
        return following.length > 0;
    }

    // async function cancelAttention() {
    //     // const res = await cancelAttentionUser(userDetails._id);
    //     // console.log(res);

    //     // const { id } = selectedVideo.user_info;
    //     const res = await cancelAttentionUser(userDetails.id);
    //     console.log(res);
    //     if (res.code === 400) {
    //         toast.success(res.msg);
    //     }
    //     else {
    //         toast.success(t('User removed from watchlist'));
    //         dispatch(removeFromWatchlist(userDetails._id));
    //         setFollowing(false);
    //     }
    // }

    async function cancelAttention() {
        // const { _id } = selectedVideo;
        const { id } = userDetails;
        console.log(id);
        const res = await cancelAttentionUser(id);
        dispatch(removeFromWatchlist(id));
        console.log(res);
    }

    async function onAttentionUser() {
        const res = await attentionUser(userDetails.id);
        console.log(res.data.data);
        if (res.code === 400) {
            toast.error(res.msg);
        }
        else if (res.code === 200) {
            toast.success("User added to focus list");
            dispatch(addToWatchlist(res.data.data));
        }
    }

    // 1 Comprehensive 2 Latest 3 Most played 4 Most collected
    async function fetchVideos() {
        const videosList = await getUsersVideos(activeTab, influencerDetail.id);
        console.log(videosList);
        setVideosList(videosList.list);
        // setCollection(videosList.list);
    }

    const onChangeTab = value => {
        setActiveTab(value);
        fetchVideos();
    }

    useEffect(() => {
        console.log(influencerDetail);
    }, [])

    useEffect(() => {
        setLoaded(true);
        if (loaded) {
            const user = localStorage.getItem('follower') ? JSON.parse(localStorage.getItem('follower')) : null;
            setUserDetails(user);
            fetchVideos();
            setFollowing(isFollowing(user));
            const { id } = location || {};
            // console.log(user);
        }


    }, [loaded])

    if (!loaded)
        return (
            <span className="text-black">{t('loading')}...</span>
        )
    else return (
        <div className="flex flex-col items-center main-bg pb-8 w-full mb-8 min-h-screen">
            <div className="flex flex-row items-center justify-between py-2 w-full mb-2 px-2 shadow-md">
                <i className="pi pi-arrow-left text-white p-2 cursor-pointer text-sm" onClick={() => { navigate(-1) }} />
                <span className="text-sm">{t('personal_details')}</span>
                <div className="p-2"></div>
            </div>
            <div className="flex flex-col items-center justify-center">
                {influencerDetail?.avatar
                    ?
                    <img src={mediaBaseURL + influencerDetail?.avatar} alt="user avatar" className="w-3/12 my-4 rounded-full" />
                    :
                    <img src={assets_path('avatar.jpg')} alt="user avatar" className="w-3/12 my-4 rounded-full" />
                }
                <div className="flex flex-row items-center justify-between gap-4">
                    <div className="flex flex-col items-center justify-center gap-1">
                        <span className="text-xs">{influencerDetail?.harbor_number}</span>
                        <span className="text-xs text-gray-500">{t('Fan')}</span>
                    </div>
                    <div className="border-r border-gray-600 h-2"></div>
                    <div className="flex flex-col items-center justify-center gap-1">
                        <span className="text-xs">{influencerDetail?.attention}</span>
                        <span className="text-xs text-gray-500">{t('Liked')}</span>
                    </div>
                    <div className="border-r border-gray-600 h-2"></div>
                    <div className="flex flex-col items-center justify-center gap-1">
                        <span className="text-xs">{influencerDetail?.video_number}</span>
                        <span className="text-xs text-gray-500">{t('Videos')}</span>
                    </div>
                </div>
                <button
                    className={`${following ? 'bg-gray-600' : 'bg-pink-600'} text-white px-4 py-1 text-center mt-4 rounded-sm text-sm flex flex-row items-center mx-auto gap-2`}
                    onClick={() => {
                        if (following) {
                            cancelAttention();
                        }
                        else {
                            onAttentionUser();
                        }
                    }}
                >
                    {following ? t('unsubscribe') : t('follow')}
                </button>
            </div>

            <PersonalInfo user={influencerDetail} t={t} />

            <TabView onChangeTab={onChangeTab} activeTab={activeTab} t={t} />

            <div className="grid grid-cols-2 gap-2 p-2 mb-24 w-full">
                {
                    videosList.length > 0 ? videosList.map((video, key) => (
                        <div key={key} className="cursor-pointer">
                            <VideoPreviewComponent video={video} />
                        </div>
                    ))
                        :
                        <>
                            <div className="flex flex-row w-full items-center justify-center">
                                <span className="text-sm text-gray-700 w-full text-center">{t("no_content")}</span>
                            </div>
                        </>
                }
                {/* <VideoPreviewComponent path={assets_path("hero.png")} title="午夜看片剧场午夜看片午夜看片剧场午夜看片午夜看片剧场午夜看片" user="麻豆传媒" obj={null} />
                <VideoPreviewComponent path={assets_path("hero.png")} title="午夜看片剧场午夜看片午夜看片剧场午夜看片午夜看片剧场午夜看片" user="麻豆传媒" obj={null} />
                <VideoPreviewComponent path={assets_path("hero.png")} title="午夜看片剧场午夜看片午夜看片剧场午夜看片午夜看片剧场午夜看片" user="麻豆传媒" obj={null} />
                <VideoPreviewComponent path={assets_path("hero.png")} title="午夜看片剧场午夜看片午夜看片剧场午夜看片午夜看片剧场午夜看片" user="麻豆传媒" obj={null} /> */}
            </div>

        </div>
    );
}

export default PersonalDetails;