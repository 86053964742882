import { SET_PERSONAL_DETAILS } from "../actions/personalDetailsAction";

const initialState = {
    personalDetails: null
}

const personalDetailsReducer = (state = initialState, action) => {

    switch(action.type) {
        case SET_PERSONAL_DETAILS: {
            return {
                ...state,
                personalDetails: action.payload
            }
        }
        default:
            return state;
    }
}

export default personalDetailsReducer;