import { SET_ERRORS } from "../actions/apiActions"

const initialState = {
    errors: null
}

const apiReducer = (state = initialState, action) => {

    switch (action.type) {
        case SET_ERRORS: {
            return {
                ...state,
                errors: action.payload
            }
        }
        default:
            return state;
    }
}

export default apiReducer;
