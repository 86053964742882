import React, { createContext, useEffect, useState } from 'react';
import { fetchMyHarborVideos, getVideoList } from '../../utils/Utils';
import { useSelector } from 'react-redux';


const VideoContext = createContext();

const VideoContextProvider = ({ children }) => {
    const { userData } = useSelector((state) => state.user);
    // State to be shared
    const [collection, setCollection] = useState([]);
    const [watchlist, setWatchlist] = useState([]);
    const [harborVideosList, setHarborVideosList] = useState([]);
    const [activeInnerTab, setActiveInnerTab] = useState(4);
    const [resetList, ResetList] = useState(false);

    // pagination states
    const [page, setPage] = useState(1);
    const [limit] = useState(3);


    async function setVideoCollection(type) {
        if (collection.length) {
            return collection;
        }
        // const res = await getVideoList(type);
        // setCollection(res.list);
    }

    async function fetchHarborVideos(user_id = '') {
        if (userData === null) {
            setHarborVideosList([]);
        }
        else {
            const res = await fetchMyHarborVideos(userData.id);
            setHarborVideosList(res.data.list);
        }
    }

    useEffect(() => {
        console.log('inner tab changed');
        async function fetchVideos() {
            const res = await getVideoList(activeInnerTab, 1, 10);
            ResetList(true);
            console.log(res);
            setCollection(res.list);
            setWatchlist(activeInnerTab);
        }
        fetchVideos();
    }, [activeInnerTab]);

    // Context value object
    const contextValue = {
        collection,
        harborVideosList,
        activeInnerTab,
        resetList,
        ResetList: (value) => ResetList(value),
        setCollection: (videos) => setCollection(videos),
        page,
        // limit,
        setPage: (value) => setPage(value),
        setVideoList: (type) => setVideoCollection(type),
        fetchMyCollection: (user_id) => fetchHarborVideos(user_id),
        setActiveInnerTab: (index) => setActiveInnerTab(index),
    };

    return (
        <VideoContext.Provider value={contextValue}>
            {children}
        </VideoContext.Provider>
    );
};

export { VideoContext, VideoContextProvider };
