import { useNavigate } from "react-router";
import lang from "../locale/LocalizedString";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import SuccessPage from "./SuccessPage";
import { ROUTES } from "../constants/Routes";
import Popup from "../components/Popup";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

function AccountPasswordForm({ loading, onSubmitForm, t, setFormData, formData, onChangeInput, error }) {
    return (
        <div className="flex flex-col items-center justify-center w-full p-2">
            <div className="flex flex-col items-start justify-center w-full bg-[#262A32] rounded-sm p-2 px-2">
                <div className="flex flex-row items-center gap-1">
                    <span className="text-xs">{t('Login Account')}:</span>
                    <InputText placeholder={t("Enter login account")} className="bg-transparent text-xs" name="username" value={formData.username}
                        onChange={onChangeInput} />
                </div>
                <div className="flex flex-row items-center gap-1">
                    <span className="text-xs">{t('Login Password')}:</span>
                    <InputText placeholder={t("Login Password")} type="password" className="bg-transparent text-xs" name="password" value={formData.password}
                        onChange={onChangeInput} />
                </div>
            </div>
            <span className="text-xs my-2 mr-auto">{t('change_password_note')}</span>
            <button className="text-xs py-2 w-full bg-[#2F363E] rounded-sm" onClick={onSubmitForm}>
                {t("Change Password")}
                {loading && <i className="pi pi-spin pi-spinner text-sm ms-2"></i>}
            </button>
            {error &&
                <span className="text-red-500 text-sm my-2 mr-auto">{t('Invalid form data')}</span>
            }
        </div>
    )
}

function ChangePassword() {

    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [successPageVisible, setSuccessPageVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const [error, setError] = useState('');

    const navigate = useNavigate();

    function onSubmitForm() {
        setError(false);
        if (formData.username === '' || formData.password === '') {
            setError(true);
            return;
        }
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            // setVisible(true);
            toast.warning(t("Work in progress"));
            // setSuccessPageVisible(true);
        }, 1000);
    }

    function onChangeInput(e) {
        const { value, name } = e.target;
        let data = null;
        if (name === 'username') {
            data = {
                ...data,
                username: value
            }
        }
        else {
            data = {
                ...data,
                password: value
            }
        }
        setFormData(data);
    }

    if (!successPageVisible) {
        return (
            <div className="flex flex-col items-center main-bg pb-8 w-full mb-8 min-h-screen">
                <div className="flex flex-row items-center justify-between py-2 w-full mb-2 px-2 shadow-md">
                    <i className="pi pi-arrow-left text-white p-2 cursor-pointer text-sm" onClick={() => { navigate(-1) }} />
                    <span className="text-sm">{t('account_password')}</span>
                    <div className="p-2"></div>
                </div>

                <AccountPasswordForm loading={loading} onSubmitForm={onSubmitForm} t={t} setFormData={setFormData} formData={formData}
                    onChangeInput={onChangeInput} error={error} />

                {visible && <Popup onClose={() => setVisible(false)} />}

                <ToastContainer />
            </div>
        );
    }
    else {
        return (
            <SuccessPage title="Password updated successfully" btnText="Return to Profile" path={ROUTES.PROFILE} onNavigate={() => navigate(ROUTES.LOGIN)} />
        );
    }
}

export default ChangePassword;