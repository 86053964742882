// import axiosInstance from "../interceptors/axiosInterceptor";
// import AxiosInstance from "../interceptors/axiosInterceptor";
// import { useEffect } from "react";
// import useAxiosInterceptor from "../interceptors/axiosInterceptor";
import axiosInstance from "../interceptors/axiosInterceptor";
import { ROUTES, UN_AUTHORIZED } from "../constants/Routes";
import { endpoints } from "../interceptors/endPoints";
import axios from "axios";
// import useAxiosInterceptor from "../../interceptors/axiosInterceptor";

const language = localStorage.getItem('i18nextLng');

export const VIDEO_TYPE = {
    all: 1,
    collection: 1,
    most_likes: 2,
    featured: 2,
    original: 3,
    most_views: 3,
    latest: 4,
    new_arrival: 4,
    popular: 5,
    most_collected: 5
}

export function assets_path(assetName) {
    // Define the base path to your assets folder
    const basePath = process.env.PUBLIC_URL; // Assuming you have assets in the public folder

    // Return the full path to the asset
    return `${basePath}/${assetName}`;
}

export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regular expression
    return emailRegex.test(email);
}

// const RefreshAuth = () => {

//     const axiosInstance = useAxiosInterceptor();

//     const refreshAuth = async () => {
//         console.log(axiosInstance);
//         const token = localStorage.getItem('token');
//         if (token) {
//             const res = await axiosInstance.post(endpoints.REFRESH_TOKEN, null, { headers: { requiresAuth: true } });
//             console.log(res.data);
//         }
//     }

//     useEffect(() => {
//         refreshAuth();
//     })
// }

export async function refreshAuth() {
    // const axiosInstance = useAxiosInterceptor();
    const token = localStorage.getItem('token');
    const queryString = `?language=${language}`;
    if (token) {
        const res = await axios.post(endpoints.REFRESH_TOKEN + queryString, null, { headers: { Authorization: `Bearer ${JSON.parse(token)}` } });
        console.log(res.data);
        if (res.data.length > 0) {
            const { access_token } = res.data.original;

            if (res.data.code === 200) {
                localStorage.setItem("token", JSON.stringify(access_token));
                return access_token;
            }
        }
        if (res.data.code === 400) {
            return UN_AUTHORIZED;
        }
    }
}

export async function getOTP(email) {

    const queryString = `?email=${email}&language=${language}`;
    const fullUrl = endpoints.GET_EMAL + queryString;

    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        return response.data.data;
    }
    return response.data;
}

export async function generateChatId(type = "") {
    if (localStorage.getItem('chat_id') && type !== 'register') {
        return JSON.parse(localStorage.getItem('chat_id'));
    }
    else {
        const fullUrl = endpoints.REGISTER_CHAT;
        const response = await axiosInstance.post(fullUrl);

        if (response.data.code === 200) {
            const { _id } = response.data.data.data.chatInfo;
            localStorage.setItem('chat_id', JSON.stringify(_id));
            return _id;
        }
        else {
            console.log(response.data.msg);
        }
    }
}

export async function getHeroImage(type, lang = 'en') {
    const queryString = `?type=${type}&language=${language}`;
    const fullUrl = endpoints.GET_BANNER + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        const banners = response.data.data;
        return banners;
    }
    else {
        return "Banners not found";
    }
}

export async function getVIPList(lang = 'en') {
    const queryString = `?language=${language}`;
    const fullUrl = endpoints.GET_VIP_LIST + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        const banners = response.data.data;
        return banners;
    }
    else {
        return "No VIP yet.";
    }
}

export function wrapText(ctx, text, maxWidth) {
    var words = text.split(' ');
    var lines = [];
    var currentLine = words[0];

    for (var i = 1; i < words.length; i++) {
        var testLine = currentLine + ' ' + words[i];
        var metrics = ctx.measureText(testLine);
        var testWidth = metrics.width;

        if (testWidth <= maxWidth) {
            currentLine = testLine;
        } else {
            lines.push(currentLine);
            currentLine = words[i];
        }
    }

    lines.push(currentLine);
    return lines;
}

export function limitString(string, size) {
    if (typeof string !== 'string' || typeof size !== 'number') {
        throw new Error('Invalid input types. Please provide a string and a maximum length.');
    }

    return string.length > size ? string.slice(0, size) + '...' : string;
}

// Get video => 1 = collection, 2 = selection, 3 = original, 4 = latest, 5 = popular
export async function getVideoList(type = 4, page = 1, limit = 10, lang = 'en') {
    const queryString = `?type=${type}&page=${page}&limit=${limit}&language=${language}`;
    const fullUrl = endpoints.GET_VIDEO_LIST + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: type === 1 ? true : false } });

    console.log('====>>>', response.data);
    if (response.data.code === 200) {
        const videos = await response.data.data;
        return videos;
    }
    return await response.data;
}

// 1 Comprehensive 2 Latest 3 Most played 4 Most collected
export async function getUsersVideos(type = 1, user_id, lang = 'en') {
    const queryString = `?type=${type}&user_id=${user_id}&page=1&limit=10&language=${language}`;
    const fullUrl = endpoints.GET_USERS_VIDEOS + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    if (response.data.code === 200) {
        const videos = await response.data.data;
        return videos;
    }
    return await response.data;
}

export async function getInfluencerVideoList(type = 1, limit = 10, page = 1, lang = 'en') {
    const queryString = `?type=${type}&page=${page}&limit=${limit}&language=${language}`;
    const fullUrl = endpoints.GET_INFLUENCER_VIDEOS + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: type === 1 ? true : false } });

    if (response.data.code === 200) {
        const videos = await response.data.data;
        return videos;
    }
    return await response.data;
}

export async function likeVideo(user_id, video_id, lang = 'en') {
    const queryString = `?user_id=${user_id}&video_id=${video_id}&language=${language}`;
    const fullUrl = endpoints.LIKE_VIDEO + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else {
        return "";
    }
}

export async function addToFavoriteVideo(user_id, video_id, lang = 'en') {
    const queryString = `?user_id=${user_id}&video_id=${video_id}&language=${language}`;
    const fullUrl = endpoints.HARBOR_VIDEO + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else {
        return "";
    }
}

export async function fetchMyFavoriteVideos() {
    const fullUrl = endpoints.GET_MY_FAVORITE_VIDEOS; // + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    if (response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else {
        return "";
    }
}

export async function fetchMyHarborVideos(user_id, lang = 'en') {
    const queryString = `?user_id=${user_id}&language=${language}`;
    const fullUrl = endpoints.GET_MY_HARBOR_VIDEOS + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    if (response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else {
        return "";
    }
}

export async function fetchRelatedVideos(type_id, page = 1, lang = 'en') {
    const queryString = `?type=${type_id}&page=${page}&limit=10&language=${language}`;
    const fullUrl = endpoints.GET_VIDEO_LIST + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        const videos = await response.data.data;
        return videos;
    }

    return response.data;
}

export async function fetchVideoTags(tag_ids = [], lang = "en") {
    const queryString = `?video_tag_id[]=${tag_ids}&language=${language}`;
    const fullUrl = endpoints.GET_VIDEO_TAGS_BY_ID + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        const videos = await response.data.data;
        return videos;
    }
    else {
        return "No content yet.";
    }
}

export async function fetchAllVideoTags(lang = "en") {
    const queryString = `?language=${language}`;
    const fullUrl = endpoints.GET_VIDEO_TAGS + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        const videos = await response.data.data;
        return videos;
    }
    else {
        return "No content yet.";
    }
}

// type = category
export async function fetchVideoTypes(lang = "en") {
    console.log(lang);
    const queryString = `?language=${language}`;
    const fullUrl = endpoints.GET_VIDEO_TYPE + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data.code === 200) {
        const videos = await response.data.data;
        return videos;
    }
    else {
        return "No content yet.";
    }
}

export async function attentionUser(user_id, lang = "en") {
    const queryString = `?id=${user_id}&language=${language}`;
    const fullUrl = endpoints.ATTENTION_USER + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    if (response.data && response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data;
    }
}

export async function cancelAttentionUser(user_id, lang = "en") {
    const queryString = `?id=${user_id}&language=${language}`;
    const fullUrl = endpoints.CANCEL_ATTENTION + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    if (response.data && response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export async function getMyWatchlist(type = 1) {
    const queryString = `?sort=${type}&page=${1}&limit=${10}&language=${language}`;
    const fullUrl = endpoints.GET_MY_WATCHLIST + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    if (response.data && response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export async function getUserInfo(user_id, lang = 'en') {
    const queryString = `?language=${language}&user_id=${user_id}`;
    const fullUrl = endpoints.GET_USER_INFO + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data && response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export async function browseVideo(user_id, video_id, lang = 'en') {
    const queryString = `?language=${language}&user_id=${user_id}&video_id=${video_id}`;
    const fullUrl = endpoints.BROWSE_VIDEO + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data && response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export async function getCheckinList(lang = 'en') {
    console.log(language);
    const queryString = `?current=${lang}`;
    const fullUrl = endpoints.CHECK_IN_LIST + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    if (response.data && response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export async function checkin(checkin_id, lang = 'en') {
    const queryString = `?language=${language}&check_in_id=${checkin_id}`;
    const fullUrl = endpoints.CHECK_IN + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    if (response.data && response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export async function searchVideo(content, user_id = "", lang = 'en') {
    const queryString = `?language=${language}&content=${content}&user_id=${user_id}`;
    const fullUrl = endpoints.SEARCH_VIDEO + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data && response.data.code === 200) {
        const res = await response.data;
        return res;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export const isTokenExpired = () => {
    const token = localStorage.getItem('token');
    const loginTime = localStorage.getItem('token_expires_in');

    if (token && loginTime) {
        // const expirationTime = parseInt(loginTime, 10) * 1000 + 3600 * 1000; // 3600 seconds in milliseconds
        console.log(loginTime, Date.now());
        return Date.now() >= loginTime;
    }

    return true; // Treat as expired if either token or login time is missing
};

export const buyVip = async (vip_id, type) => {
    const queryString = `?language=${language}&vip_id=${vip_id}&type=${type}`;
    const fullUrl = endpoints.OPEN_VIP + queryString;
    const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });

    let res = null;
    res = await response.data;
    return res;
    if (response.data && response.data.code === 200) {
    }
    else {
        return res;
    }
    // else if (response.data.code === 500) {
    //     return "Something went wrong";
    // }
    // else {
    //     return response.data.msg;
    // }
};

export async function logoutUser() {
    // const queryString = `?language=${language}&content=${content}&user_id=${user_id}`;
    const fullUrl = endpoints.LOGOUT; // + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data && response.data.code === 200) {
        return true;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export async function getContestBanner(type) {
    const queryString = `?language=${language}&type=${type}`;
    const fullUrl = endpoints.GET_CONTEST_BANNER + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data && response.data.code === 200) {
        return response.data;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export async function getContestVideo(type) {
    const queryString = `?language=${language}&type=${type}`;
    const fullUrl = endpoints.GET_CONTEST_VIDEO + queryString;
    const response = await axiosInstance.post(fullUrl);

    if (response.data && response.data.code === 200) {
        return response.data;
    }
    else if (response.data.code === 500) {
        return "Something went wrong";
    }
    else {
        return response.data.msg;
    }
}

export function isMoreThan24HoursAgo(timestamp) {
    const lastCheckInTime = new Date(timestamp).getTime();
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastCheckInTime;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference > 24;
}