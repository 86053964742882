import { useNavigate } from "react-router";
import lang from "../locale/LocalizedString";
import moment from 'moment';
import { useTranslation } from "react-i18next";

function ExchangeCard(
    {
        status = 'success',
        redemptionTime = 3,
        time = 1700032264,
        diamonds = '-200',
        t
    }
) {
    return (
        <div className="grid mx-2 my-1 p-2 bg-[#262A32] rounded-sm gap-2">
            <div className="grid-cols-2 flex flex-row items-start justify-between">
                <span className="text-xs font-semibold">{t('vip_member_redemption')}</span>
                <span className={`text-xs ${status === 'success' ? 'text-green-600' : 'text-red-500'}`}>{status === 'success' ? t('Success') : t('Fail')}</span>
            </div>
            <div className="grid-cols-2 flex flex-row items-start justify-between">
                <span className="text-xs text-gray-400">{t('redemption_time')}:</span>
                <span className="text-xs text-gray-400">{redemptionTime} {t('days')}</span>
            </div>
            <div className="grid-cols-2 flex flex-row items-start justify-between">
                <span className="text-xs text-gray-400">{t('diamonds')}:</span>
                <span className="text-xs text-gray-400 font-light">{diamonds}</span>
            </div>
            <div className="grid-cols-2 flex flex-row items-start justify-between">
                <span className="text-xs text-gray-400">{t('time')}:</span>
                <span className="text-xs text-gray-400 font-light">{moment.utc(time * 1000).format('YYYY-MM-DD HH:mm')}</span>
            </div>
        </div>
    )
}

function ExchangeRecord() {

    const { t } = useTranslation();

    const exchangeCardData = [
        { status: 'success', diamonds: '-200', redemptionTime: 3, time: 1700032264 },
        { status: 'failed', diamonds: '-150', redemptionTime: 2, time: 1700032265 },
        { status: 'success', diamonds: '-100', redemptionTime: 1, time: 1700032266 },
        { status: 'failed', diamonds: '-50', redemptionTime: 5, time: 1700032267 },
        { status: 'success', diamonds: '-300', redemptionTime: 4, time: 1700032268 },
        { status: 'failed', diamonds: '-250', redemptionTime: 2, time: 1700032269 },
        { status: 'success', diamonds: '-180', redemptionTime: 3, time: 1700032270 },
        { status: 'failed', diamonds: '-90', redemptionTime: 6, time: 1700032271 },
        { status: 'success', diamonds: '-210', redemptionTime: 2, time: 1700032272 },
        { status: 'failed', diamonds: '-120', redemptionTime: 4, time: 1700032273 },
    ];

    const navigate = useNavigate();

    return (
        <div className="card flex flex-col align-items-end justify-content-end bg-[#191C22] pb-12">
            <div className="flex flex-row items-center justify-between py-2 w-full px-2 shadow-md">
                <i className="pi pi-arrow-left text-gray-400 p-2 text-sm cursor-pointer" onClick={() => { navigate(-1) }} />
                <span className="text-sm">{t('exchange_record')}</span>
                <span className="text-sm p-2"></span>
            </div>

            <div className="card flex flex-col align-items-end justify-content-end">
                {exchangeCardData.length
                    ?
                    exchangeCardData.map(
                        (exchange, i) =>
                            <ExchangeCard status={exchange.status} diamonds={exchange.diamonds} redemptionTime={exchange.redemptionTime} time={exchange.time} t={t} />
                    )
                    :
                    <></>
                }
            </div>
        </div>
    );
}

export default ExchangeRecord;