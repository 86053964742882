import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import useLanguage from "../hooks/useLanguage";
import i18next from "i18next";

const initialLanguages = [
    { code: 'zh_CN', name: '简体中文', selected: false },
    { code: 'zh_TW', name: '繁體中文', selected: false },
    { code: 'en', name: 'English', selected: false },
    { code: 'ar', name: 'العربية', selected: false },
    { code: 'cs', name: 'Čeština', selected: false },
    { code: 'fr', name: 'Français', selected: false },
    { code: 'el', name: 'Ελληνικά', selected: false },
    { code: 'de', name: 'Deutsch', selected: false }
];

function LanguagePage() {

    const language = useLanguage();
    const [languages, setLanguages] = useState(initialLanguages);

    function onChangeLang(code, index) {
        if (index <= 2) {
            language.changeLanguage(code);
            window.location.reload();
        }
    }

    useEffect(() => {
        setLanguages((prevLanguages) =>
            prevLanguages.map((lang) => ({
                ...lang,
                selected: lang.code === language.language,
            }))
        );
    }, [language.language]);

    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div className="flex flex-col items-center justify-start w-full text-dark min-h-screen h-full pb-12 main-bg text-gray-900">
            <div className="flex flex-row items-center justify-between py-2 w-full mb-4 px-2 shadow-md border-b-1 border-b-gray-300">
                <i className="pi pi-arrow-left text-gray-400 p-2 cursor-pointer text-sm" onClick={() => { navigate(-1) }} />
                <span className="text-sm text-white">{t('language_selection')}</span>
                <div className="p-2"></div>
            </div>
            <div className="flex flex-col items-center justify-start gap-3 w-full">
                {
                    languages.map((language, i) => (
                        <div className={`flex flex-row items-center justify-between ${i<= 2 ? 'cursor-pointer' : ''} w-full px-2`} onClick={() => onChangeLang(language.code, i)} key={i}>
                            <span className={`text-sm ${i > 2 ? 'text-gray-400' : 'text-white'}`}>{language.name}</span>
                            {language.selected ? <i className="pi pi-check text-pink-600 text-sm"></i> : <div className="p-2"></div>}
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default LanguagePage;