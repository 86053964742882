import { SET_REWARD, SET_WHEEL_VISIBLE } from "../actions/wheelAction";

const initialState = {
    wheelVisible: false,
    reward: 0
}

const wheelReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WHEEL_VISIBLE:
            return {
                ...state,
                wheelVisible: action.payload
            }
        case SET_REWARD:
            return {
                ...state,
                reward: state.reward + action.payload
            }
        default:
            return state;
    }
}

export default wheelReducer;
