import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants/Routes';
import { useEffect } from 'react';
import { BottomNavigation } from "reactjs-bottom-navigation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeAlt, faPlay, faUserAlt, faVideo } from '@fortawesome/free-solid-svg-icons';
import { assets_path } from '../utils/Utils';
import { useTranslation } from 'react-i18next';

function BottomNav() {
    const location = useLocation();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const bottomNavItems = [
        {
            title: t("nav.home"),
            id: ROUTES.HOME,
            onClick: ({ id }) => navigate(id),
            icon: <img src={assets_path("imgs/navbar/home-default.svg")} alt="home icon" />,
            activeIcon: <img src={assets_path("imgs/navbar/home-icon.svg")} alt="home icon" />
        },
        {
            title: t("nav.video"),
            id: ROUTES.VIDEO,
            onClick: ({ id }) => navigate(id),
            icon: <img src={assets_path("imgs/navbar/video-default.svg")} alt="video icon" />,
            activeIcon: <img src={assets_path("imgs/navbar/video-icon.svg")} alt="video icon" />
        },
        {
            title: t("nav.daily_contest"),
            id: ROUTES.DAILY_CONTEST,
            onClick: ({ id }) => navigate(id),
            icon: <img src={assets_path("imgs/navbar/play-default.svg")} alt="daily contest" />,
            activeIcon: <img src={assets_path("imgs/navbar/play-icon.svg")} alt="daily contest" />
        },
        {
            title: t("nav.account"),
            id: ROUTES.PROFILE,
            onClick: ({ id }) => navigate(id),
            icon: <img src={assets_path("imgs/navbar/user-default.svg")} alt="user account" />,
            activeIcon: <img src={assets_path("imgs/navbar/user-icon.svg")} alt="user account" />
        },
    ];

    if (location.pathname !== ROUTES.EXCHANGE_RECORD
        && location.pathname !== ROUTES.FOLLOW_PAGE
        && location.pathname !== ROUTES.LOGIN
        && location.pathname !== ROUTES.REGISTER
        && location.pathname !== ROUTES.FORGET_PASSWORD
        && location.pathname !== ROUTES.SUCCESS_PAGE
        && location.pathname !== ROUTES.SUPPORT
    ) {
        return (
            <BottomNavigation
                items={bottomNavItems}
                selected={0}
                // onItemClick={(item) =>  console.log(item)}
                activeBgColor="#4a494a"
                activeTextColor="white"
            />
        )
    }

}

export default BottomNav;