import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import persistConfig from './persistConfig';
import rootReducer from './RootReducer';
import watchlistMiddleware from './middlewares/watchlistMiddleware';
import thunk from 'redux-thunk';

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [watchlistMiddleware, thunk];

const store = configureStore({
  reducer: persistedReducer,
  middleware: middleware
  // other configuration options if needed
});

const persistor = persistStore(store);

export { store, persistor };
