import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

/**
 * Hook for managing language in the application.
 * @returns {{ language: 'ar' | 'en'; changeLanguage: (language: 'ar' | 'en') => void; }}
 */
const useLanguage = () => {
    const { i18n } = useTranslation();

    const changeLanguage = useCallback(
        (locale) => {
            if (i18n.language === locale) return;

            // Simulate router behavior by updating the URL
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('locale', locale);

            const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
            window.history.replaceState({ path: newUrl }, '', newUrl);

            // Update i18n language
            i18n.changeLanguage(locale);
        },
        [i18n]
    );

    return {
        language: i18n.language || 'ar',
        changeLanguage,
    };
};

export default useLanguage;
