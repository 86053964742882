import { ADD_TO_WATCHLIST, REMOVE_FROM_WATCHLIST, SET_WATCHLIST } from "../actions/watchlistAction";


const watchlistReducer = (state = [], action) => {
    switch (action.type) {
        case SET_WATCHLIST: {
            return [
                ...state,
                action.payload
            ]
        }
        case ADD_TO_WATCHLIST: {
            return [...state, action.payload];
        }
        case REMOVE_FROM_WATCHLIST: {
            const d = state.filter(item => parseInt(item.target_user_id) !== action.payload);
            console.log(d, action.payload);
            return d;
        }
        case 'CLEAR_WATCHLIST':
            return [];
        default:
            return state;
    }
}

export default watchlistReducer;