import { REQUEST_CODE } from "../actions/verificationCode";


const initialState = {
    verificationCodeEnabled: true,
};

const verificationCodeReducer = (state = initialState, action) => {
    switch (action.type) {
        case REQUEST_CODE: {
            return {
                ...state,
                verificationCodeEnabled: action.payload
            }
        }
        default: {
            return state;
        }
    }
}

export default verificationCodeReducer;
