import { useLocation, useNavigate } from "react-router-dom";
import { assets_path, browseVideo } from "../utils/Utils";
import { ROUTES } from "../constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import { selectVideo } from "../screens/store/actions/videoAction";
import { useEffect, useState } from "react";
import { mediaBaseURL } from "../interceptors/axiosInterceptor";
import moment from "moment/moment";

function VideoPreviewComponent(
    {
        path,
        className = "",
        title = "",
        user = "",
        obj = null,
        video = null
    }
) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const selectedVideo = useSelector((state) => state.video.selectedVieo);


    useEffect(() => {

        if (video) {
            setLoading(false);
        }
    }, [selectedVideo, video])

    let daysDifference = null;
    useEffect(() => {
        // setTimeout(() => {
        //     window.scrollTo(0, 1);
        // }, 0);
        const videoCreatedAt = video.updated_at; // Replace with your actual video data

        // Convert the timestamp to a moment object
        const videoCreatedMoment = moment(videoCreatedAt);

        // Calculate the difference in days between now and the video creation time
        daysDifference = moment().diff(videoCreatedMoment, 'days');
    }, []);

    const onSelectVideo = async () => {
        dispatch(selectVideo(video));
        const res = await browseVideo(video.user_info.id, video._id);
        const { pathname } = location;
        console.log(pathname);

        if (pathname === "/channel") {
            window.location.reload();
        }

        navigate(ROUTES.CHANNEL_PAGE);
    }


    if (loading) {
        <span>Loading</span>
    }
    else return (
        <div className={`flex flex-col cursor-pointer items-start relative ${className !== "" ? className : "gap-1"}`} onClick={() => onSelectVideo()}>
            {video?.video_img ?
                <img src={mediaBaseURL + video?.video_img} className="video-img" alt={video.video_name} />
                :
                <img src={assets_path('hero.png')} className="" alt={video.video_name} />
            }

            {daysDifference === 1 && <img src={assets_path('imgs/new-tag.svg')} alt="new video tag" className="absolute right-1 top-1 w-4" />}
            <div className="gradient-overlay w-full" />
            <div className="absolute bottom-9 left-1 flex flex-row items-center justify-center bg-gradient-to-t from-gray-400 to-white">
                <img src={assets_path('imgs/play-icon.svg')} alt="play icon" className="w-3 me-1" />
                <span className="text-xs">{video.play_number}</span>
                <img src={assets_path('imgs/fire-icon.svg')} alt="fire icon" className="w-3 mx-1" />
                <span className="text-xs">{video.hot_number}</span>
            </div>
            <p className="text-xs text-white whitespace-nowrap overflow-hidden overflow-ellipsis max-w-full">{video?.video_name || ""}</p>
            {video?.user_id !== null && <div className="flex flex-row items-start justify-start">
                {video?.user_info.avatar
                    ? <img src={mediaBaseURL + video?.user_info.avatar} className="pi pi-user text-white text-xs me-2 w-[20px] rounded-full" />
                    :
                    <img src={assets_path('avatar.jpg')} className="pi pi-user text-white text-xs me-2 w-[20px] rounded-full" />
                }
                <span className="text-xs text-gray-400">{video?.user_info.name || ""}</span>
            </div>}
        </div>
    );
}

export default VideoPreviewComponent;