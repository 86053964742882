import { ErrorMessage, Form, Formik, useFormik } from "formik";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import lang from "../../locale/LocalizedString";
import FormikInput from "../../components/FormikInput";
import { navigateWithMessage, validateEmail } from "../../utils/Utils";
import { endpoints } from "../../interceptors/endPoints";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { useEffect, useState } from "react";
import { disableVerificationCode, enableVerificationCodeAfterDelay } from "../store/actions/verificationCode";
import { useDispatch, useSelector } from "react-redux";
import { ROUTES } from "../../constants/Routes";
import { useTranslation } from "react-i18next";
import SuccessPage from "../SuccessPage";

const initialValues = {
    email: "",
    verificationCode: ""
}

const validationSchema = Yup.object().shape({
    email: Yup.string().email("Enter valid email").required("Email is required"),
    verificationCode: Yup.string().min(5, "Verification code must at least 5 characters long").required("Verification code is required")
})

function BindEmailForm(
    {
        dispatch,
        onBindEmail,
        message,
        t,
        setEmailBound
    }
) {

    const [email, setEmail] = useState("");
    const [v_code, setV_code] = useState("");

    const { verificationCodeEnabled } = useSelector((state) => state.v_code);
    const { userData } = useSelector((state) => state.user);

    useEffect(() => {
        if (userData) {
            if (userData.email !== "") {
                setEmailBound(true);
            }
        }
    }, [])


    const onGetCode = async (email) => {
        console.log(email);

        if (email !== "" && validateEmail(email)) {
            const queryString = `?email=${email}&language=${'en'}`;
            const fullUrl = endpoints.GET_EMAL + queryString;

            const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });
            // const response = await axiosInstance.post(fullUrl);
            console.log(response.data);
            if (response.data.code === 200) {
                setV_code(response.data.data.v_code);
            }
            dispatch(disableVerificationCode());
            if (verificationCodeEnabled) {
                dispatch(enableVerificationCodeAfterDelay());
            }
        }
        else {
            console.log('invalid email');
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                onBindEmail(values, setSubmitting);
                console.log(values);
            }}
        >
            {({ isSubmitting, errors, touched, setFieldValue, values }) => (
                <Form className="flex flex-col items-center justify-start w-full gap-4 mt-4">
                    <FormikInput
                        type="email"
                        name="email"
                        placeholder={t('enter_email')}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setFieldValue('email', e.target.value);
                        }}
                        value={values.email}
                        icon="user"
                    />
                    <ErrorMessage name="email" component="span" className="text-red-500 text-xs text-start w-full" />
                    <FormikInput
                        type="text"
                        name="verificationCode"
                        placeholder={t('enter_verification_code')}
                        onChange={(e) => {
                            setFieldValue('verificationCode', e.target.value);
                        }}
                        value={values.verificationCode}
                        icon="shield"
                        code="true"
                        codeEnabled={verificationCodeEnabled}
                        onClick={() => onGetCode(email)}
                    />
                    <ErrorMessage name="verificationCode" component="span" className="text-red-500 text-xs text-start w-full" />
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-pink-600 text-white p-2 text-center w-full mt-4 rounded-sm text-sm flex flex-row items-center mx-auto gap-2"
                    >
                        <span className="w-full text-center">{t('bind_email')} {isSubmitting && <i className="pi pi-spin pi-spinner text-sm"></i>} </span>
                    </button>
                    {
                        message && <span className="text-xs text-red-600 w-full">{message}</span>
                    }
                </Form>
            )}
        </Formik>
    )
}

function BindEmail() {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [message, setMessage] = useState("");
    const [emailBound, setEmailBound] = useState(false);

    const onClickFunction = () => navigate(ROUTES.PROFILE);

    const onBindEmail = async (values, setSubmitting) => {
        // console.log(values);
        // navigate(ROUTES.SUCCESS_PAGE, { state: { message: "Email binding was successful", btnText: "Go to Profile", binding: true } });
        // return;

        const queryString = `?email=${values.email}&code=${values.verificationCode}&language=${'en'}`;
        const fullUrl = endpoints.BOUND_EMAIL + queryString;

        const response = await axiosInstance.post(fullUrl, null, { headers: { requiresAuth: true } });
        if (response.data.code === 200) {
            navigate(ROUTES.SUCCESS_PAGE, { state: { message: "Email binding was successful", btnText: "Go to Profile", binding: true } });
            // navigateWithMessage(history, ROUTES.SUCCESS_PAGE, { message: "Email binding was successful", btnText: "Go to Profile" });
        }
        else if (response.data.code === 400) {
            setMessage(response.data.msg);
            setSubmitting(false);
        }
        console.log(response.data);

    }

    if (!emailBound) {
        return (
            <div className="flex flex-col items-center main-bg pb-8 w-full mb-8 min-h-screen">
                <div className="flex flex-row items-center justify-between py-2 w-full mb-4 px-2 shadow-md">
                    <i className="pi pi-arrow-left text-gray-400 p-2 cursor-pointer text-sm" onClick={() => { navigate(-1) }} />
                    <span className="text-sm">{t('bind_email')}</span>
                    <div className="p-2"></div>
                </div>
                <div className="my-4 flex flex-col items-start justify-start w-full px-2">
                    <span className="text-sm text-white">{t('bind_email')}</span>
    
                    <BindEmailForm dispatch={dispatch} onBindEmail={onBindEmail} message={message} setMessage={setMessage} t={t} setEmailBound={setEmailBound} />
                    {/* <SignupForm /> */}
                </div>
    
            </div>
        );
    }
    else {
        return <SuccessPage title="Password updated successfully" btnText="Return to Profile" resetPassword={false} onNavigate={() => navigate(ROUTES.PROFILE)} />
    }
}

export default BindEmail;
