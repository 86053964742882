export const REQUEST_CODE = "REQUEST_CODE";


export const requestCode = () => ({
    type: REQUEST_CODE
});

export const disableVerificationCode = () => ({
    type: REQUEST_CODE,
    payload: false
});

export const enableVerificationCode = () => ({
    type: REQUEST_CODE,
    payload: true
});

export const enableVerificationCodeAfterDelay = () => {
    return (dispatch) => {

        const timerStart = Date.now();
        sessionStorage.setItem('verificationCodeTimerStart', timerStart);

        setTimeout(() => {
            dispatch({
                type: REQUEST_CODE,
                payload: true,
            });
            sessionStorage.removeItem('verificationCodeTimerStart');
        }, 10 * 1000);
    };
};

export const checkVerificationCodeTimer = () => {
    return dispatch => {
        // Get values from localStorage
        const verificationCodeEnabled = localStorage.getItem('verificationCodeEnabled');
        const timerStart = localStorage.getItem('verificationCodeTimerStart');

        // Check if values exist and have the expected format
        if (verificationCodeEnabled === 'false' && timerStart && !isNaN(timerStart)) {
            const elapsed = Date.now() - parseInt(timerStart, 10);
            const remainingTime = Math.max(3 * 60 * 1000 - elapsed, 0);

            setTimeout(() => {
                dispatch(enableVerificationCodeAfterDelay());
            }, remainingTime);
        } else {
            dispatch(enableVerificationCode());            
        }
    };
};
