import { useEffect, useState } from "react";
import { assets_path, getUserInfo, logoutUser } from "../utils/Utils";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link, useNavigate } from "react-router-dom";
import {
    faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { Button } from "primereact/button";
import { ROUTES } from "../constants/Routes";
import { useDispatch, useSelector } from "react-redux";
import { logout, setUserData } from "./store/actions/userActions";
import lang from "../locale/LocalizedString";
import LanguagePopup from "../components/LanguagePopup";
import { useTranslation } from "react-i18next";


function TabView(
    {
        userData,
        t
    }
) {

    const [activeTab, setActiveTab] = useState(1);
    const [search, setSearch] = useState("");

    useEffect(() => {

    }, [activeTab, search]);

    const onChangeTab = value => {
        setActiveTab(value);
    }

    return (
        // <div className="flex flex-row gap-1 items-center justify-around font-semibold w-full">
        //     <Link to={ROUTES.VIP_USER} className="flex flex-row items-center justify-between w-full bg-gray-700 border-gray-500 border-1 rounded-tr-md px-2 bg-gradient-to-b from-[#424242] to-[#1c1c1c]">
        //         <div className="flex flex-col items-start justify-between w-full py-1">
        //             <span className="text-xs text-white">Active VIP</span>
        //             <span className="text-xs text-gray-400">Active now</span>
        //         </div>
        //         <span className="text-xs whitespace-nowrap bg-gray-400 rounded-sm px-1 text-gray-700">active</span>
        //     </Link>
        //     <Link to={ROUTES.CHECK_IN} className="flex flex-row items-center justify-between w-full bg-gray-700 border-gray-500 border-1 rounded-tl-md px-2 bg-gradient-to-b from-[#424242] to-[#1c1c1c]">
        //         <div className="flex flex-col items-start justify-between w-full py-1">
        //             <span className="text-[9.8px] text-white">Check-in, get free diamonds</span>
        //             <span className="text-xs text-gray-400">Current diamonds: 12</span>
        //         </div>
        //         <span className="text-xs whitespace-nowrap bg-gray-400 rounded-sm px-1 text-gray-700">Go check-in</span>
        //     </Link>
        // </div>
        <div className="flex flex-row gap-1 items-center justify-around font-semibold w-full my-2">
            <Link to={ROUTES.VIP_USER} className="vip-tab">
            </Link>
            <Link to={ROUTES.CHECK_IN} className="get-vip-tab flex flex-col items-start justify-end pb-2">
                <div className="w-[80%] flex flex-row items-center justify-center">
                    <span className="text-[18px] font-light">{t('diamonds')}: {userData?.diamonds}</span>
                </div>
            </Link>
        </div>
    );
}

function SettingCard(
    {
        t,
        title = t("share"),
        content = t("Share to get free VIP"),
        icon = faShareNodes,
        path = '',
        onClick = null
    }
) {
    return (
        <Link to={path} className="flex flex-row items-center justify-between mt-2 px-2 py-1 w-full" onClick={onClick}>
            <div className="flex flex-row items-center justify-start">
                <img src={icon} alt={title} className="text-xs" />
                <span className="text-xs ms-1">{title}</span>
            </div>
            <div className="flex flex-row items-center justify-start">
                <span className="text-xs me-1">{content}</span>
                <i className="pi pi-chevron-right text-xs font-light"></i>
            </div>
        </Link>
    );
}

function Profile() {

    const { t } = useTranslation();

    const [copied, setCopied] = useState(false);
    const [value, setValue] = useState("");
    const [userId, setuserId] = useState("3344556");
    const [loading, setLoading] = useState(true);
    const [langPopupVisible, setLangPopupVisible] = useState(false);

    const onToggleLanguage = () => setLangPopupVisible(!langPopupVisible);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    // const auth = useAuth();

    const isAuthenticated = useSelector((state) => state.user.userData !== null);
    const language = useSelector((state) => state.language.currentLanguage);
    const userData = useSelector((state) => state.user.userData);

    async function fetchUserInfo(user_id) {
        // dispatch(setUserData(userData));
        // const res = await getUserInfo(user_id);
        // if (res.data) {
        //     dispatch(setUserData(res.data));
        // }
    }

    useEffect(() => {
        if (userData)
            fetchUserInfo(userData.id);
        else navigate('/login');
    }, []);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
        setLoading(false);
    }, [isAuthenticated]);

    const onLogout = async () => {
        dispatch(logout());
        localStorage.removeItem("reduxState");
        localStorage.removeItem("token");
        localStorage.removeItem("token_expires");
        await logoutUser();
        navigate('/login');
    }

    if (loading) {
        return (
            <></>
        )
    }
    else return (
        <div className="flex flex-col items-center main-bg pb-8 w-full mb-8 min-h-screen">
            <div className="flex flex-col items-center profile-bg bg-gradient-to-r from-gray-300 to-gray-700
                relative
                overflow-hidden
                z-10
                bg-cover
                bg-no-repeat
                bg-center
                before:content-['']
                before:absolute
                before:inset-0
                before:block
                before:bg-gradient-to-b
                before:from-gray-100
                before:to-black
                before:opacity-75
                before:z-[-5]
            ">
                <div className="flex flex-col mx-2 rounded-md bg-gray-800 h-24">
                    <div className="-mt-6 mb-6 ms-2 flex flex-row items-center gap-2">
                        {/*   */}
                        <img src={assets_path("avatar.jpg")} alt="" className="rounded-full w-2/12" />
                        <div className="flex flex-col items-start gap-1">
                            <div className="flex flex-row items-center gap-1">
                                <span className="text-sm">{userData?.name || "Guest"}</span>
                                <img src={assets_path("imgs/diamond.svg")} alt="" className="w-3" />
                            </div>
                            <CopyToClipboard text={value}
                                onCopy={() => setValue(userData?.id)}>
                                <span className="text-sm">ID: {userData?.id || ""} <i className="pi pi-copy text-sm cursor-pointer"></i></span>
                            </CopyToClipboard>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-between gap-2 px-4">
                        <div className="flex flex-col items-center">
                            <span className="text-sm">{userData?.harbor_number}</span>
                            <span className="text-sm text-gray-400">{t('collect')}</span>
                        </div>
                        <div className="border-r-2 border-gray-600 h-3" />
                        <div className="flex flex-col items-center">
                            <span className="text-sm">{userData?.attention}</span>
                            <span className="text-sm text-gray-400">{t('focus_on')}</span>
                        </div>
                        <div className="border-r-2 border-gray-600 h-3" />
                        <div className="flex flex-col items-center">
                            <span className="text-sm">{userData?.video_number}</span>
                            <span className="text-sm text-gray-400">{t('recent')}</span>
                        </div>
                        <div className="border-r-2 border-gray-600 h-3" />
                        <div className="flex flex-col items-center">
                            <span className="text-sm">1234</span>
                            <span className="text-sm text-gray-400">{t('share')}</span>
                        </div>
                    </div>
                </div>
            </div>

            <TabView userData={userData} t={t} />
            <SettingCard t={t} path={""} icon={assets_path('imgs/profile/top-up.svg')} title={t("top_up")} content="" />
            <SettingCard t={t} path={""} icon={assets_path('imgs/profile/withdraw.svg')} title={t("withdraw")} content="" />
            <SettingCard t={t} path={ROUTES.INVITE_TO_SHARE} icon={assets_path('imgs/profile/share.svg')} />
            <SettingCard t={t} title={t("account_password")} content={t("manage_account")} icon={assets_path('imgs/profile/lock.svg')} path={ROUTES.ACCOUNT_PASSWORD} />
            <SettingCard t={t} title={t("exchange_record")} content="" icon={assets_path('imgs/profile/exchange.svg')} path={ROUTES.EXCHANGE_RECORD} />
            <SettingCard t={t} title={t("my_focus")} content="" icon={assets_path('imgs/profile/heart.svg')} path={ROUTES.FOLLOW_PAGE} />
            <SettingCard t={t} title={t("cooperation")} content="" icon={assets_path('imgs/profile/cooperation.svg')} />
            <SettingCard t={t} title={t("bind_email")} content="" path={ROUTES.BIND_EMAIL} icon={assets_path('imgs/profile/mail.svg')} />
            <SettingCard t={t} title={t("verified")} content="" icon={assets_path('imgs/profile/verified.svg')} />
            <SettingCard t={t} title={t("language")} content="" icon={assets_path('imgs/profile/verified.svg')} path={ROUTES.LANGUAGE_SELECTION} />

            <Button onClick={onLogout} className="block bg-pink-700 w-11/12 my-2 text-sm py-2">{t('logout')}</Button>

            {langPopupVisible && <LanguagePopup onHide={onToggleLanguage} visible={langPopupVisible} />}
        </div>
    );
}

export default Profile;