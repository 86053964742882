import { useTranslation } from "react-i18next";

function TabView(
    {
        activeTab,
        data,
        onChangeTab,
        tabStyle = "border"
    }
) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-row gap-2 px-2 items-center justify-around font-semibold w-full">
            {
                data.length
                    ? data.map((tab, i) => (
                        <div className="cursor-pointer" onClick={() => onChangeTab(i + 1)} key={i}>
                            <span className={`text-xs capitalize ${activeTab === i + 1 && tabStyle === 'border' ? 'border-b-2 border-pink-600' : ''} p-1 m-0`}>
                                {t(`${(tab.label).toLowerCase()}`)}
                            </span>
                        </div>
                    ))
                    : <></>
            }
        </div>
    );
}

export default TabView;