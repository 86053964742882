import { useNavigate } from "react-router";
import WheelComponent from "../components/wheel/src";
import { assets_path, limitString } from "../utils/Utils";
import lang from "../locale/LocalizedString";
import { ProgressBar } from 'primereact/progressbar';
import { useState } from "react";
import TabView from "../components/TabView";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { onSetReward } from "./store/actions/wheelAction";
import { RWebShare } from "react-web-share";
import { useEffect } from "react";

function WheelOfFortune() {

    const { t } = useTranslation();
    const { reward } = useSelector((state) => state.wheel);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(1);
    const [segmentValue, setSegmentValue] = useState(0);
    const [shareURL, setShareURL] = useState("");

    const userData = useSelector((state) => state.user?.userData);

    useEffect(() => {
        if (userData) {
            const { invitation_code } = userData;
            const url = `https://video-streaming-app-407214.web.app/register?invitation_code=${invitation_code}`;
            setShareURL(url);
        }
    }, []);


    var img = new Image();
    img.src = assets_path('imgs/cash.png');


    var img2 = new Image();
    img2.src = assets_path('imgs/coin.svg');


    const segments = [
        { text: img, value: 0 },
        { text: '1', value: 1 },
        { text: img, value: 0 },
        { text: '100', value: 100 },
        { text: img, value: 0 },
        { text: '1000', value: 1000 },
        { text: img, value: 0 },
        { text: '500', value: 500 }
    ]
    const segColors = [
        '#FFEAF9',
        '#FFFFFF',
        '#FFEAF9',
        '#FFFFFF',
        '#FFEAF9',
        '#FFFFFF',
        '#FFEAF9',
        '#FFFFFF',
    ]
    const onFinished = (winner) => {
        // setSegmentValue((value) => value + winner.segment.value);
        // let segment = reward + winner.segment.value;
        dispatch(onSetReward(winner.segment.value));
    }

    const reportData = [
        {
            backup: "5588**772备份",
            text: "Just got a backup",
            value: "+100"
        },
        {
            backup: "1234**567备份",
            text: "Another backup received",
            value: "-50"
        },
        {
            backup: "9999**888备份",
            text: "Received a backup",
            value: "+75"
        },
        {
            backup: "4567**321备份",
            text: "New backup added",
            value: "-30"
        },
        {
            backup: "7777**111备份",
            text: "Backup completed",
            value: "+120"
        }
    ];

    const tabData = [
        {
            label: lang.report,
            onChange: () => setActiveTab(1),
            activeTab
        },
        {
            label: lang.rules,
            onChange: () => setActiveTab(2),
            activeTab
        }
    ]

    const onChangeTab = value => {
        setActiveTab(value)
    };

    return (
        <div className='flex flex-col items-center justify-center mx-auto main-bg min-h-screen pb-12'>
            <div className="flex flex-row items-center justify-between py-2 w-full mb-2 px-2 shadow-md">
                <i className="pi pi-arrow-left text-white p-2 cursor-pointer text-sm" onClick={() => { navigate(-1) }} />
                <span className="text-sm">{t('wheel_of_fortune')}</span>
                <div className="p-2"></div>
            </div>
            <div className="flex flex-row items-center justify-between my-2 w-full px-4">
                <span className="text-md">{t('wheel_of_fortune')}</span>
                <div className="flex flex-row items-center justify-center">
                    <div className="flex flex-row items-center justify-end bg-[#262A33] border border-gray-500 rounded-md min-w-[6em] p-1 relative">
                        <img src={assets_path('imgs/coin.svg')} className="absolute -left-2 w-12" />
                        <span className="text-pink-500 font-bold">{reward}</span>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-between my-2 w-full px-4">
                <div className="bg-[#262A33] rounded-md w-full py-2">
                    <div className="flex flex-col items-center justify-between px-2 gap-2">
                        <span className="text-sm w-full text-right">
                            {reward > 100 ? '100%' : `${reward}%`}
                        </span>
                        <ProgressBar value={reward} className="w-full h-[10px]"></ProgressBar>
                        {reward < 100 ?
                            <>
                                <span className="text-sm text-gray-500">{t('Only')} <span className="text-sm text-white me-1">
                                    ￥{Math.abs(reward > 0 ? reward - 100 : 100)}
                                </span>
                                    {t('left to withdraw money')}
                                </span>
                            </>
                            :
                            <span className="text-sm text-gray-500">Current balance is <span className="text-white text-sm">{reward}</span> </span>
                        }
                    </div>
                </div>
            </div>
            <div className="w-full bg-contain bg-center bg-no-repeat flex flex-row items-center justify-center"
                style={{ backgroundImage: `url(${assets_path('imgs/spin-bg.svg')})` }}
            >
                <WheelComponent
                    segments={segments}
                    segColors={segColors}
                    // winningSegment='won 70'
                    onFinished={(winner) => onFinished(winner)}
                    primaryColor='#FB2B7B'
                    contrastColor='black'
                    buttonText='Spin'
                    isOnlyOnce={false}
                    size={180} // give value in a percentage, in this case 90%
                    sizePercent={90}
                    upDuration={100}
                    downDuration={1000}
                    fontFamily='Arial'
                />
            </div>

            <RWebShare
                data={{
                    text: "Visit video streaming platform for fun",
                    url: shareURL,
                    title: "Video Streaming Platform",
                    sites: ['telegram', 'facebook', 'twitter']
                }}
                onClick={() => console.log("shared successfully!")}
            >
                <div style={{ backgroundImage: `url(${assets_path('imgs/spin-btn.svg')})` }}
                    className="bg-center bg-contain bg-no-repeat text-sm rounded-full w-full py-2 mt-2 cursor-pointer flex flex-row items-center justify-center">
                    {t('invite_to_withdrawals')}
                </div>
            </RWebShare>

            <div
                className="flex flex-col items-start justify-start bg-no-repeat bg-center bg-contain py-8 gap-2"
                style={{ backgroundImage: `url(${assets_path('imgs/fortune-report-bg.svg')})`, width: '90%', minHeight: 400 }}>
                <TabView data={tabData} activeTab={activeTab} onChangeTab={onChangeTab} tabStyle="border" />
                {activeTab === 1 ? <div className="grid w-full text-xs px-2">
                    {reportData.length
                        ? reportData.map((data, i) => (
                            <div key={i}
                                className={`flex flex-row items-center justify-around bg-[#B6356744] border-t border-l border-r 
                                ${i == reportData.length - 1 && 'border-b'} border-pink-600`}>
                                <div className="border-r border-pink-600 m-0 p-1 w-[35.3%]">
                                    <span className="text-xs">{data.backup}</span>
                                </div>
                                <div className="border-r border-pink-600 m-0 p-1 w-[50.3%]">
                                    <span className="text-xs">{limitString(data.text, 10)}</span>
                                </div>
                                <div className="m-0 p-1 w-[25.3%]">
                                    <span className="text-xs">{data.value}</span>
                                </div>
                            </div>
                        ))
                        : <></>
                    }
                </div>
                    : <div className="flex flex-col items-start justify-start gap-1 px-2">
                        <span className="text-sm">1: {lang.withdrawal_rules.rule_1}</span>
                        <span className="text-sm">2: {lang.withdrawal_rules.rule_2}</span>
                        <span className="text-sm">3: {lang.withdrawal_rules.rule_3}</span>
                        <span className="text-sm">4: {lang.withdrawal_rules.rule_4}</span>
                        <span className="text-sm">5: {lang.withdrawal_rules.rule_5}</span>
                    </div>
                }
            </div>
        </div>
    )
}

export default WheelOfFortune;