import { getUserInfo } from "../../../utils/Utils";

export const SET_USER_DATA = 'SET_USER_DATA';
export const LOGOUT = "LOGOUT";
export const GET_OTHER_USER_DETAIL = 'GE_OTHER_USER_DETAIL';
export const SET_OTHER_USER_DETAIL = 'SET_OTHER_USER_DETAIL';
export const SET_INFLUENCER_DETAIL = 'SET_INFLUENCER_DETAIL';
export const GET_INFLUENCER_DETAIL = 'GET_INFLUENCER_DETAIL';

// // Action creators
export const setUserData = (userData) => {
    console.log('setUserData called with:', userData);
    return {
        type: SET_USER_DATA,
        payload: userData,
    }
}

export const logout = () => ({
    type: LOGOUT
});

export const clearUserData = () => ({
    type: LOGOUT
});

export const getOtherUserDetail = () => {
    return {
        type: GET_OTHER_USER_DETAIL
    }
}

export const setOtherUserDetail = (data) => {
    return {
        type: SET_OTHER_USER_DETAIL,
        payload: data
    }
}

export const setInfluencerDetail = (value) => ({
    type: SET_INFLUENCER_DETAIL,
    payload: value
});

export const getInfluencerDetail = () => ({
    type: GET_INFLUENCER_DETAIL
});
