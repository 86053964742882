import { useEffect, useState } from "react";
import { assets_path, fetchMyHarborVideos, getMyWatchlist, getVideoList } from "../../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { fetchWatchlist } from "../store/actions/watchlistAction";
import { getAllVideos } from "../store/actions/videoAction";
import VideoPageContainer from "./VideoPageContainer";
import { useTranslation } from "react-i18next";

export const VIDEO_TYPE = {
    allVideos: "allVideos",
    myCollection: "myCollection"
}

function VideoPage() {

    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(1);
    const [collection, setCollection] = useState([]);
    const [watchlist, setWatchlist] = useState([]);
    const [loading, setLoading] = useState(true);
    const [harborVideoList, setHarborVideoList] = useState([]);
    const [videoType, setVideoType] = useState(1);

    // const { id: user_id } = useSelector((state) => state.user.userData);
    const { id: user_id } = useSelector((state) => state.user.userData) ?? { id: null };
    const watchlist_redux = useSelector((state) => state.watchlist);
    const dispatch = useDispatch();

    const tabsData = [
        {
            label: "Videos",
            onChange: () => setActiveTab(1),
            activeTab
        },
        {
            label: "Collect",
            onChange: () => setActiveTab(2),
            activeTab
        }
    ];

    const onChangeTab = value => {
        setActiveTab(value);
        console.log(value);
    }


    async function fetchVideos() {
        const videosList = await getVideoList(videoType);
        console.log(videosList);
        return videosList.list;
        // setCollection(videosList.list);
    }

    async function getFavoriteVideos() {
        if (user_id === null) {
            return [];
        }
        const res = await fetchMyHarborVideos(user_id);
        console.log(res);
        return res.data.list;
        if (res.code === 200) {
            // setHarborVideoList(res.data.list);
        }
    }

    async function getWatchlist() {
        const res = await getMyWatchlist();
        console.log('watchlist', res);
        return res.data;
        // setWatchlist(res.data);
        // setLoading(false);
    }

    async function fetchData() {
        try {
            const [videos, favoriteVideos, watchlist] = await Promise.all([
                fetchVideos(),
                getFavoriteVideos(),
                getWatchlist(),
            ]);
            setCollection(videos);
            setHarborVideoList(favoriteVideos);
            setWatchlist(watchlist);
            setLoading(false);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // Dispatch the fetchWatchlist action when the component mounts

        dispatch(getAllVideos());
        dispatch(fetchWatchlist());
    }, [dispatch]);

    useEffect(() => {
        fetchData();
    }, []);

    if (loading && (watchlist.length === 0 || harborVideoList.length === 0)) {
        return (
            <div className="card flex flex-col items-center justify-center main-bg p-2 pb-[4rem] min-h-screen">
                <div className="card flex flex-col items-center justify-center">
                    <img src={assets_path('imgs/no-content-icon.svg')} alt="no content found" />
                    <span className="text-sm text-gray-500 mt-2">{t("loading")}...</span>
                </div>
            </div>
        )
    }
    // else if (!loading && (watchlist.length === 0 || harborVideoList.length === 0)) {
    //     return (
    //         <div className="card flex flex-col items-center justify-center main-bg p-2 pb-[4rem] min-h-screen">
    //             <div className="card flex flex-col items-center justify-center">
    //                 <img src={assets_path('imgs/no-content-icon.svg')} alt="no content found" />
    //                 <span className="text-sm text-gray-500 mt-2">{t("no_content")}</span>
    //             </div>
    //         </div>
    //     )
    // }
    else return (
        <VideoPageContainer
            // videoList={videoCollection}
            watchlist={watchlist}
            harborVideoList={harborVideoList}
            tabsData={tabsData}
            onChangeTab={onChangeTab}
            activeTab={activeTab}
        />
    );
}

export default VideoPage;