// PrivateRoute.js

import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './screens/auth/Login';

const PrivateRoute = ({ path, element: Element, ...rest }) => {
    const isAuthenticated = useSelector((state) => state.user.userData !== null);

    return (
        <Route
            {...rest}
            path={path}
            element={
                isAuthenticated ? (
                    <Element />
                ) : (
                    <Login />
                )
            }
        />
    );
};

export default PrivateRoute;
