import React, { useEffect, useState } from 'react'
import { assets_path } from '../../../utils/Utils'

const WheelComponent = ({
  segments,
  segColors,
  winningSegment,
  onFinished,
  primaryColor = 'black',
  contrastColor = 'white',
  buttonText = 'Spin',
  isOnlyOnce = true,
  sizePercent,
  size = (sizePercent / 100) * Math.min(window.innerWidth, window.innerHeight),
  upDuration = 100,
  downDuration = 1000,
  fontFamily = 'proxima-nova'
}) => {
  let currentSegment = ''
  let isStarted = false
  const [isFinished, setFinished] = useState(false)
  let timerHandle = 0
  const timerDelay = segments.length
  let angleCurrent = 0
  let angleDelta = 0
  let canvasContext = null
  let maxSpeed = Math.PI / `${segments.length}`
  const upTime = 2000; //segments.length * upDuration
  const downTime = 4000;// segments.length * downDuration
  let spinStart = 0
  let frames = 0
  const centerX = 200
  const centerY = 300
  useEffect(() => {
    size = ((((sizePercent / 100) * Math.min(window.innerWidth, window.innerHeight)) / 2) - 20) > 180
      ? 180
      : (((sizePercent / 100) * Math.min(window.innerWidth, window.innerHeight)) / 2) - 20;
    wheelInit()
    setTimeout(() => {
      // window.scrollTo(0, 1);
    }, 0)
    console.log(size, (sizePercent / 100) * Math.min(window.innerWidth, window.innerHeight));
  }, [])
  const wheelInit = () => {
    initCanvas()
    wheelDraw()
  }

  const initCanvas = () => {
    let canvas = document.getElementById('canvas')
    console.log(navigator)
    if (navigator.userAgent.indexOf('MSIE') !== -1) {
      canvas = document.createElement('canvas')
      canvas.setAttribute('width', 400)
      canvas.setAttribute('height', 580)
      canvas.setAttribute('id', 'canvas')
      document.getElementById('wheel').appendChild(canvas)
    }
    canvas.addEventListener('click', spin, false)
    canvasContext = canvas.getContext('2d')
  }
  const spin = () => {
    isStarted = true
    if (timerHandle === 0) {
      spinStart = new Date().getTime()
      // maxSpeed = Math.PI / ((segments.length*2) + Math.random())
      maxSpeed = Math.PI / segments.length
      frames = 0
      timerHandle = setInterval(onTimerTick, timerDelay)
    }
  }
  const onTimerTick = () => {
    frames++
    draw()
    const duration = new Date().getTime() - spinStart
    let progress = 0
    let finished = false
    if (duration < upTime) {
      progress = duration / upTime
      angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2)
    } else {
      if (winningSegment) {
        if (currentSegment === winningSegment && frames > segments.length) {
          progress = duration / upTime
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
          progress = 1
        } else {
          progress = duration / downTime
          angleDelta =
            maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
        }
      } else {
        progress = duration / downTime
        angleDelta = maxSpeed * Math.sin((progress * Math.PI) / 2 + Math.PI / 2)
      }
      if (progress >= 1) finished = true
    }

    angleCurrent += angleDelta

    while (angleCurrent >= Math.PI * 2) angleCurrent -= Math.PI * 2
    if (finished) {
      setFinished(true)
      onFinished({ segment: currentSegment, index: segments.indexOf(currentSegment) });
      clearInterval(timerHandle)
      timerHandle = 0
      angleDelta = 0
    }
  }

  const wheelDraw = () => {
    clear()
    drawWheel()
    drawNeedle()
  }

  const draw = () => {
    clear()

    drawWheel()
    drawNeedle()
  }


  const drawSegment = (key, lastAngle, angle) => {
    const ctx = canvasContext;
    const value = segments[key].text;


    // Calculate the rotation angle based on the segment's position
    const rotationAngle = (lastAngle + angle) / 2;

    ctx.save();
    ctx.beginPath();
    ctx.moveTo(centerX, centerY);
    ctx.arc(centerX, centerY, size, lastAngle, angle, false);
    ctx.lineTo(centerX, centerY);
    ctx.closePath();
    ctx.fillStyle = segColors[key];
    ctx.fill();
    ctx.stroke();
    ctx.save();
    ctx.translate(centerX, centerY);

    // Introduce the rotation animation
    ctx.rotate(rotationAngle);

    // Check if the segment is an image
    if (value instanceof Image) {
      // Clear any existing onload handler
      // value.onload = null;
      // ctx.arc(centerX + 156, centerY + 150, size, lastAngle, angle, false);
      value.onload = null;

      // Load the image asynchronously
      value.onload = function () {
        // ctx.drawImage(value, size + 56, 150); // Draw the image at its center

        ctx.translate(0, size); // Adjust the translation based on the wheel size
        ctx.drawImage(value, size - 90, 0); // Draw the image at its center
        // ctx.translate(0, size); // Adjust the translation based on the wheel size

        ctx.restore(); // Restore the canvas state

        drawWheel();
      };

      // Handle the case where the image is already loaded
      if (value.complete || value.width + value.height > 0) {
        ctx.drawImage(value, size - 90, -20); // Draw the image at its center

        // ctx.translate(centerX + 156, centerY - 150);
        // ctx.restore(); // Restore the canvas state
      }
    } else {
      ctx.fillStyle = contrastColor;
      ctx.font = 'bold 0.7em ' + fontFamily;
      ctx.fillText(value.substr(0, 21), size / 2 + 20, 0);
    }

    // ctx.restore();
    ctx.restore();
  };

  // const drawSegment = (key, lastAngle, angle) => {
  //   const ctx = canvasContext
  //   const value = segments[key]
  //   ctx.save()
  //   ctx.beginPath()
  //   ctx.moveTo(centerX, centerY)
  //   ctx.arc(centerX, centerY, size, lastAngle, angle, false)
  //   ctx.lineTo(centerX, centerY)
  //   ctx.closePath()
  //   ctx.fillStyle = segColors[key]
  //   ctx.fill()
  //   ctx.stroke()
  //   ctx.save()
  //   ctx.translate(centerX, centerY)
  //   ctx.rotate((lastAngle + angle) / 2)
  //   ctx.fillStyle = contrastColor
  //   ctx.font = 'bold 1em ' + fontFamily
  //   if(value.endsWith(".png")){
  //     var img = new Image();
  //     img.src = value;
  //     img.onload = function() {
  //       ctx.drawImage(img, 0, 0); // Draw the image at the top-left corner (0, 0)
  //     };

  //     // ctx.drawImage(value, centerX - size, centerY - size, size * 2, size * 2); // Corrected arguments

  //   }
  //   else{
  //     ctx.fillText(value.substr(0, 21), size / 2 + 20, 0)
  //   }
  //   ctx.restore()
  // }

  var dots = new Image();
  dots.src = assets_path('imgs/dots.svg');
  const drawWheel = () => {
    const ctx = canvasContext
    let lastAngle = angleCurrent
    const len = segments.length
    const PI2 = Math.PI * 2
    ctx.lineWidth = 1
    ctx.strokeStyle = primaryColor
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.font = '1em ' + fontFamily
    // Clear the canvas before redrawing
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    for (let i = 1; i <= len; i++) {
      const angle = PI2 * (i / len) + angleCurrent
      drawSegment(i - 1, lastAngle, angle)

      // customWheel(ctx, PI2, (lastAngle + angle) / 2);
      lastAngle = angle
    }

    // Draw a center circle
    // ctx.drawImage(needle, centerX - 40, centerY - 55, 80, 100);
    // ctx.restore();


    // ctx.beginPath()
    // ctx.arc(centerX, centerY, 50, 0, PI2, false)
    // ctx.closePath()
    // ctx.fillStyle = primaryColor
    // ctx.lineWidth = 10
    // ctx.strokeStyle = '#FFF'
    // ctx.fill()
    // ctx.font = 'bold 1em ' + fontFamily
    // ctx.fillStyle = contrastColor
    // ctx.textAlign = 'center'
    // ctx.fillText(buttonText, centerX, centerY + 3)
    // ctx.stroke()

    // Draw outer circle
    ctx.beginPath()
    ctx.arc(centerX, centerY, size, 0, PI2, false)
    ctx.closePath()

    ctx.lineWidth = 25
    ctx.strokeStyle = primaryColor
    ctx.stroke()

  }

  // Move this outside drawWheel to create the image only once
  var customTextureImage = new Image();
  customTextureImage.src = assets_path('imgs/wheel-bg.svg');

  var customWheel = function drawCustomWheel(ctx, PI2, newAngle) {
    ctx.save(); // Save the current context state
    ctx.beginPath();
    ctx.arc(centerX, centerY, size, 0, 0, false);
    ctx.closePath();
    ctx.clip(); // Clip the circle path
    ctx.rotate(newAngle);
    ctx.drawImage(customTextureImage, centerX - size, centerY - size, size * 2, size * 2); // Corrected arguments
    ctx.restore(); // Restore the context state
  };

  const lang = localStorage.getItem('i18nextLng');
  const needle = new Image();
  needle.src = lang === 'en' ? assets_path('imgs/inner-circle-en.svg') : assets_path('imgs/inner-circle-ch.svg');
  const drawNeedle = () => {
    const ctx = canvasContext
    ctx.lineWidth = 1
    ctx.strokeStyle = contrastColor
    ctx.fileStyle = contrastColor
    ctx.beginPath();

    needle.onload = function () {
      ctx.drawImage(needle, centerX - 40, centerY - 55, 80, 100);
      ctx.restore();
    }

    if (needle.complete || needle.width + needle.height > 0) {
      ctx.drawImage(needle, centerX - 40, centerY - 55, 80, 100);
    }

    // ctx.moveTo(centerX + 20, centerY - 50);
    // ctx.lineTo(centerX - 20, centerY - 50);
    // ctx.lineTo(centerX, centerY - 70);
    ctx.closePath();
    ctx.fill();
    ctx.restore();
    const change = angleCurrent + Math.PI / 2
    let i =
      segments.length -
      Math.floor((change / (Math.PI * 2)) * segments.length) -
      1
    if (i < 0) i = i + segments.length
    // ctx.textAlign = 'center'
    // ctx.textBaseline = 'middle'
    // ctx.fillStyle = primaryColor
    // ctx.font = 'bold 1.5em ' + fontFamily
    currentSegment = segments[i]
    // isStarted && ctx.fillText(currentSegment, centerX + 10, centerY + size + 50)
  }
  const clear = () => {
    const ctx = canvasContext
    ctx.clearRect(0, 0, 500, 500)
  }
  return (
    <div id='wheel'>
      <canvas
        id='canvas'
        width='400'
        height='580'
        style={{
          pointerEvents: isFinished && isOnlyOnce ? 'none' : 'auto'
        }}
      />
    </div>
  )
}
export default WheelComponent