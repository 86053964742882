
export const ROUTES = {
    HOME: '/',
    ABOUT: '/about',
    PROFILE: '/profile',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGET_PASSWORD: '/forget-password',
    DAILY_CONTEST: '/daily-contest',
    VIDEO: '/video',
    SEARCH: '/search',
    VIP_USER: '/vip-user',
    EXCHANGE_RECORD: '/exchange-record',
    FOLLOW_PAGE: '/follow',
    CHANNEL_PAGE: '/channel',
    CHECK_IN: '/check-in',
    INVITE_TO_SHARE: '/invite-to-share',
    SHARE_QR: '/share-qr',
    BIND_EMAIL: '/bind-email',
    SUCCESS_PAGE: '/success',
    SUPPORT: '/support',
    FOLLOW_UP: '/follow-up',
    PERSONAL_DETAILS: '/personal-details/:id',
    ACCOUNT_PASSWORD: '/account-password',
    WHEEL_OF_FORTUNE: '/fortune-wheel',
    LANGUAGE_SELECTION: '/language-selection',
    CATEGORY_PAGE: '/category-page'
}

export const UN_AUTHORIZED = "UN_AUTHORIZED";