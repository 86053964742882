
function VideoPlayer(
    {
        src
    }
) {
    return (
        <video className="w-full" controls>
            <source src={src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    )
}

export default VideoPlayer;