import { useNavigate } from "react-router";
import { assets_path, buyVip, getVIPList } from "../utils/Utils";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/Routes";
import lang from "../locale/LocalizedString";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { ProgressSpinner } from "primereact/progressspinner";

function VipItem(
    {
        vip,
        baseURL,
        t,
        onClick,
        isActive
    }
) {
    const url = baseURL + vip.bg_img_url;
    return (
        <div className={`vip-container col-2 flex flex-col flex-wrap items-center justify-center gap-2 rounded-sm p-2 text-center bg-cover bg-center cursor-pointer ${isActive ? 'border-2 border-pink-800' : ''} `
        }
            onClick={onClick}
            style={{ backgroundImage: `url(${url})` }}
        >
            <span className="text-xs">{vip.diamond_number} {t('diamonds')}</span>
            <span className="text-xs">{vip.name}</span>
            <span className="text-xs">{t('share_with_registered_vip')}</span>
            <span className="text-xs">{vip.share_number} {t('people')}</span>
        </div>
    )
}

function VIPCard({ vipData, baseURL, t, onClick, userData }) {
    console.log(vipData, baseURL);
    return (
        <div className="flex flex-row flex-nowrap overflow-x-auto">
            <div className="flex flex-row flex-nowrap sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2 p-2">
                {
                    vipData.length
                        ? vipData.map((vip, i) =>
                            <div key={i} onClick={() => onClick(vip)}>
                                <VipItem vip={vip} baseURL={baseURL} t={t} isActive={userData.user_vip._id === vip._id} />
                            </div>
                        )
                        : <></>
                }
            </div>
        </div>

    )
}

function RedeemNow({ t }) {
    return (
        <div className="flex flex-row items-center justify-between p-1 rounded-full bg-gray-800 m-2">
            <div className="flex flex-col items-start justify-start ps-2">
                <span className="text-xs">{lang.formatString(t('actual_payment_and_diamonds'), { 1: '1,000,000' })}</span>
                <span className="text-gray-400 text-xs">{lang.formatString(t('available_balance'), { 1: '10,000' })}</span>
            </div>
            <Button className="rounded-full text-[11px] bg-pink-600 px-3 py-2 font-light" label="Redeem now" />
        </div>
    )
}

function VIPFeatures({ t }) {
    return (
        <div className="flex flex-col items-center justify-start bg-[#262A32] pb-12 h-full">
            <div className="collection-bg">
                <span className="text-xs">{t('vip_privileges')}</span>
            </div>
            <div className="flex flex-row flex-wrap items-center justify-between px-2">
                <div className="flex flex-row items-center justify-start w-[230px]">
                    <img src={assets_path("imgs/vip-watch.svg")} className="p-2 w-8" alt="Unlimited watching" />

                    <div className="flex flex-col items-start justify-between gap-1 p-2">
                        <span className="text-xs">{t('exclusive_videos')}</span>
                        <span className="text-xs text-gra-400">{t('unlimited_watching')}</span>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start w-[230px]">
                    <img src={assets_path("imgs/vip-support.svg")} className="p-2 w-8" alt="" />

                    <div className="flex flex-col items-start justify-between gap-1 p-2">
                        <span className="text-xs">{t('dedicated_customer_service')}</span>
                        <span className="text-xs text-gra-400">{t('personal_support')}</span>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start w-[230px]">
                    <img src={assets_path("imgs/vip-superfast.svg")} className="p-2 w-8" />

                    <div className="flex flex-col items-start justify-between gap-1 p-2">
                        <span className="text-xs">{t('adjust_dedicated_line')}</span>
                        <span className="text-xs text-gra-400">{t('faster_line')}</span>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start w-[230px]">
                    <img src={assets_path("imgs/vip-benefits.svg")} className="p-2 w-8" />

                    <div className="flex flex-col items-start justify-between gap-1 p-2">
                        <span className="text-xs">{t('exclusive_benefits')}</span>
                        <span className="text-xs text-gra-400">{t('be_first_viewer')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

function VIPUser() {

    const { userData } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [vipList, setVipList] = useState([]);
    const [baseURL, setBaseURL] = useState("");
    const [loading, setLoading] = useState(true);

    async function getVipData() {
        const data = await getVIPList();

        const { address, list } = data;
        setVipList(list);
        setBaseURL(`https://${address}/`);
        setLoading(false);
    }

    async function onGetVIP(vip) {
        const { _id } = vip;
        const res = await buyVip(_id, 1);
        console.log(res);
        if (res.code === 400) {
            toast.error(res.msg);
        }
        else {
            toast.success(t("Package purchased successfully"));
        }
    }

    useEffect(() => {
        if (userData === null) {
            navigate(ROUTES.LOGIN);
        }
        getVipData();
    }, [])

    if (loading) {
        return (
            <div className="card flex flex-col items-center justify-center min-h-screen main-bg pb-12">
                <ProgressSpinner />
                <span className="text-sm text-gray-500 mt-2">Loading users...</span>
            </div>
        )
    }
    else return (
        <div className="card flex flex-col align-items-end justify-content-end main-bg min-h-screen">
            <div className="vip-header w-full bg-gray-400 h-auto">
                <div className="flex flex-row items-center justify-between py-1 w-full px-2 shadow-md">
                    <i className="pi pi-arrow-left text-gray-400 text-sm p-2 cursor-pointer" onClick={() => { navigate(-1) }} />
                    <span className="text-sm">{t('vip_user')}</span>
                    <Link to={ROUTES.EXCHANGE_RECORD} className="text-sm cursor-pointer">{t('exchange')}</Link>
                </div>

                <div className="flex flex-row items-center justify-start p-2 gap-4 mx-2 mb-4 rounded-md">
                    <img src={assets_path("avatar.jpg")} className="w-2/12 rounded-full" alt="user avatar" />
                    <div className="flex flex-col items-start justify-start">
                        <span className="text-sm text-yellow-300">{userData?.name || 'Username'}</span>
                        <span className="text-xs bg-yellow-700 rounded-full px-1 text-yellow-300">{t('active_now')}</span>
                    </div>
                </div>
                <div className="flex flex-col items-start justify-start p-2 gap-2 mx-2 mb-4 rounded-md">
                    <span className="text-xs text-gray-400">{t("You are currently not logged in, xxx VIP activated immediately")}</span>
                    <span className="text-xs text-gray-200">{t('vip_validity_period')}: {userData.user_vip
                        ? moment.utc(userData.user_vip.end_time * 1000).format('YYYY-MM-DD')
                        : t('not_activated')
                    }
                    </span>
                </div>
            </div>

            <div className="flex flex-row items-start justify-start h-auto">
                <VIPCard vipData={vipList} baseURL={baseURL} t={t} userData={userData} onClick={onGetVIP} />
            </div>

            <RedeemNow t={t} />

            <VIPFeatures t={t} />

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default VIPUser;