export const FETCH_WATCHLIST = 'FETCH_WATCHLIST';
export const SET_WATCHLIST = 'SET_WATCHLIST';
export const ADD_TO_WATCHLIST = 'ADD_TO_WATCHLIST';
export const REMOVE_FROM_WATCHLIST = 'REMOVE_FROM_WATCHLIST';

export const setWatchlist = (watchlist) => {
    return {
        type: SET_WATCHLIST,
        payload: watchlist,
    }
};

export const fetchWatchlist = () => {
    return {
        type: FETCH_WATCHLIST,
    }
};

export const addToWatchlist = (item) => {
    return {
        type: ADD_TO_WATCHLIST,
        payload: item,
    };
};

export const removeFromWatchlist = (itemId) => {
    return {
        type: REMOVE_FROM_WATCHLIST,
        payload: itemId,
    };
};
