import { Link } from "react-router-dom";
import TabView from "../../components/TabView";
import TabContent from "./TabContent";
import { useEffect } from "react";
import { ROUTES } from "../../constants/Routes";

function VideoPageContainer({ videoList, watchlist, harborVideoList, tabsData, onChangeTab, activeTab }) {


    return (
        <>
            <div className="flex flex-row gap-4 p-1 items-center justify-start  bg-[#1c1c1c]">
                <TabView activeTab={activeTab} onChangeTab={onChangeTab} tabStyle="border" data={tabsData} />
                <Link to={ROUTES.SEARCH} >
                    <i className="pi pi-search text-white p-2 text-sm"></i>
                </Link>
            </div>

            <TabContent activeTab={activeTab} videoList={videoList} watchlist={watchlist} harborVideoList={harborVideoList} onChangeTab={onChangeTab} />
        </>
    );
}

export default VideoPageContainer;