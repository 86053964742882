import { Field } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../App.css";

function FormikInput(
    {
        label,
        placeholder,
        type,
        icon,
        onChange,
        name,
        required = true,
        code = false,
        onClick = null,
        codeEnabled = true,
        value = "",
        disabled = false
    }
) {
    const [isFocused, setFocused] = useState(false);
    const [show, setShow] = useState(false);
    const [isComponentReady, setComponentReady] = useState(false);

    useEffect(() => {
        // Set a short delay to ensure the initial layout is painted
        const timeoutId = setTimeout(() => {
            setComponentReady(true);
        }, 500);

        // Clear the timeout to avoid memory leaks
        return () => clearTimeout(timeoutId);
    }, []);

    const togglePasswordVisibility = () => setShow(!show);
    const { t } = useTranslation();

    return (
        <span className={`formik-input-container border-1 border-gray-700 text-white relative w-full ${isFocused ? 'border-gray-300' : ''}`}>
            {/* <span className={`formik-input-container p-input-icon-left text-white w-full border-gray-700 flex flex-row items-center border-1 relative justify-between ${isFocused ? 'border-gray-300' : ''
            }`}> */}
            <i className={`pi pi-${icon} text-sm left-icon text-white px-2`} />
            <Field
                className="p-1 formik-input text-sm rounded-sm flex flex-row items-center py-[10px] bg-transparent focus:border-0"
                // className="p-1 py-[10px] formik-input text-sm rounded-sm flex flex-row items-center bg-transparent focus:border-0"
                type={type === 'password' ? show ? 'text' : 'password' : type}
                name={name}
                placeholder={placeholder}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={onChange}
                value={value}
                disabled={disabled}
            />
            {type == "password" && <i className={`px-2 right-icon pi pi-${show ? 'eye-slash' : 'eye'} text-sm`} onClick={togglePasswordVisibility} />}
            {
                code &&
                // text-gray-400 bg-gray-800 text-xs px-1 py-1 rounded-sm absolute right-1 bottom-[7px] cursor-pointer
                <span
                    className={`${codeEnabled ? 'bg-[#2F363E] text-white cursor-pointer' : 'bg-gray-800 text-gray-400'}  text-xs px-1 py-1 rounded-sm absolute right-1 bottom-[6px]`}
                    onClick={onClick}
                >
                    {t('Get verification code')}
                </span>
            }
        </span>
    )
}

export default FormikInput;