import { useNavigate } from "react-router";
import lang from "../locale/LocalizedString";
import { assets_path } from "../utils/Utils";
import { useEffect, useState } from "react";
import "./support.css";
import { InputText } from "primereact/inputtext";
import useLanguage from "../hooks/useLanguage";
import { ProgressSpinner } from "primereact/progressspinner";
import { useSelector } from "react-redux";
import { ROUTES } from "../constants/Routes";

function ChatMessage(
    {
        message = {}
    }
) {
    return (
        <div className={`flex ${message.sender === 'user' ? 'flex-row' : 'flex-row-reverse'} items-start justify-start gap-2 w-full`}>
            <img src={assets_path('avatar.jpg')} alt="user avatar" className="w-[40px] rounded-full" />
            <span className="text-sm text-white p-2 bg-gray-700 w-fit rounded-sm">{message.text}</span>
        </div>
    );
}

function InputContainer(
    {
        handleInputChange,
        handleSendMessage,
        newMessage
    }
) {
    return (
        <div className="flex flex-row items-center justify-between bg-[#262A32] py-2 px-2 gap-2 w-full">
            <img src={assets_path('imgs/support/img-icon.svg')} className="min-w-[35px] cursor-pointer" />
            <InputText placeholder="Write message" className="w-full h-[45px] text-sm rounded-none bg-gray-600 text-white"
                onChange={handleInputChange}
                value={newMessage}
            />
            <img src={assets_path('imgs/support/emoji-icon.svg')} className="min-w-[35px] cursor-pointer" />
            <img src={assets_path('imgs/support/send-icon.svg')} className="min-w-[35px] cursor-pointer" onClick={handleSendMessage} />
        </div>
    )
}

function SupportPage() {

    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [chatLoaded, setChatLoaded] = useState(false);
    const [chatId, setChatId] = useState(null);
    // const [chatURL, setChatURL] = useState(`https://yvmmss.top/#/chat`);
    const [chatURL, setChatURL] = useState(`https://tmlemgran.com/#/chat`);

    const navigate = useNavigate();

    const userData = useSelector((state) => state.user.userData);

    const { language } = useLanguage();

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (newMessage.trim() !== '') {
            setMessages([...messages, { text: newMessage, sender: 'user' }]);
            setNewMessage('');
        }
    };

    useEffect(() => {
        if (userData === null) {
            navigate(ROUTES.LOGIN);
        }
        else {
            setChatId(userData.chat_id);
            setChatURL(`${chatURL}?chat_id=${userData.chat_id}&language=${language}`)
        }
    }, [messages])

    const [iframeHeight, setIframeHeight] = useState(''); // Initial height

    const calculateIframeHeight = () => {
        const headerHeight = document.querySelector('.header')?.offsetHeight || 0;
        const windowHeight = window.innerHeight;
        const calculatedHeight = windowHeight - headerHeight;

        setIframeHeight(`${calculatedHeight}px`);
    };

    useEffect(() => {
        calculateIframeHeight();
        window.addEventListener('resize', calculateIframeHeight);

        return () => {
            window.removeEventListener('resize', calculateIframeHeight);
        };
    }, []);

    useEffect(() => {
        if (iframeHeight !== '') {
            setTimeout(() => {
                setChatLoaded(true);
                setLoading(false);
            }, 1000)
        }
    }, [iframeHeight])

    const iframeStyle = {
        width: '100%',
        height: iframeHeight,
        border: 'none',
    };

    if (loading || !chatLoaded) {
        return <div className="card flex flex-col items-center justify-center min-h-screen main-bg pb-12">
            <ProgressSpinner />
            <span className="text-sm text-gray-500 mt-2">Loading chat...</span>
        </div>
    }
    else return (
        <div className="card flex flex-col align-items-end justify-content-end min-h-screen bg-[#191C22] relative">
            <iframe
                title="Chat Window"
                src={chatURL}
                style={iframeStyle}
            />
            {/* <div className="flex flex-row items-center justify-between py-2 w-full mb-4 px-2 shadow-md border-b border-gray-800 bg-[#191C22]">
                <i className="pi pi-arrow-left text-gray-400 p-2 cursor-pointer text-sm" onClick={() => { navigate(-1) }} />
                <span className="text-sm text-white">{lang.customer_support}</span>
                <div className="p-2"></div>
            </div>

            <div className="flex flex-col items-start justify-between w-full relative h-full">
                <div className="flex flex-col items-start justify-start w-full px-2 gap-2 mt-12">
                    {
                        messages.length > 0
                            ? messages.map((message, i) => {
                                return <ChatMessage user={null} message={message} key={i} />
                            })
                            : <></>
                    }
                </div>
            </div>
            <div className="absolute bottom-0 min-w-full">
                <InputContainer handleInputChange={handleInputChange} handleSendMessage={handleSendMessage} newMessage={newMessage} />
            </div> */}
        </div>
    );
}

export default SupportPage;