import { Dialog } from "primereact/dialog";
import lang from "../locale/LocalizedString";
import { assets_path } from "../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentLanguage } from "../screens/store/actions/languageAction";

function LanguagePopup(
    {
        onHide
    }
) {

    const language = useSelector((state) => state.language.currentLanguage);
    const dispatch = useDispatch();

    const onChanegLanguage = (lang) => {
        dispatch(setCurrentLanguage(lang));
    }

    return (
        <Dialog visible={true} header={lang.change_language} onHide={onHide} closeIcon={false}
            className="w-full px-4 max-w-[18rem]"
            draggable={false}
        >
            <div className="flex flex-col items-start justify-start gap-2">
                <div className="flex flex-row items-center gap-2 justify-between w-full" onClick={() => onChanegLanguage('en')}>
                    <div className="flex flex-row items-center gap-2 justify-between">
                        <img src={assets_path('imgs/lang/us.png')} alt="US flag" className="w-6" />
                        <span className="text-sm">United States</span>
                    </div>
                    <i className={`pi ${language === 'en' ? 'pi-check-circle' : 'pi-circle'} text-md text-pink-500`}></i>
                </div>
                <div className="flex flex-row items-center gap-2 justify-between w-full" onClick={() => onChanegLanguage('cn')}>
                    <div className="flex flex-row items-center gap-2 justify-between">
                        <img src={assets_path('imgs/lang/cn.png')} alt="Chinese flag" className="w-6" />
                        <span className="text-sm">Chinese</span>
                    </div>
                    <i className={`pi ${language === 'cn' ? 'pi-check-circle' : 'pi-circle'} text-md text-pink-500`}></i>
                </div>
                <div className="flex flex-row items-center gap-2 justify-between w-full" onClick={() => onChanegLanguage('pt')}>
                    <div className="flex flex-row items-center gap-2 justify-between">
                        <img src={assets_path('imgs/lang/pt.png')} alt="Portugese flag" className="w-6" />
                        <span className="text-sm">Portugese</span>
                    </div>
                    <i className={`pi ${language === 'pt' ? 'pi-check-circle' : 'pi-circle'} text-md text-pink-500`}></i>
                </div>
            </div>
        </Dialog>
    );
}

export default LanguagePopup;