import { useEffect, useState } from "react";
import { assets_path, generateChatId, getOTP, getUserInfo } from "../../utils/Utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";
import axiosInstance from "../../interceptors/axiosInterceptor";
import { endpoints } from "../../interceptors/endPoints";
import lang from "../../locale/LocalizedString";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "../store/actions/userActions";
import { useTranslation } from "react-i18next";
import { setAuthToken } from "../store/actions/tokenAction";

const accountRegisterValidationSchema = Yup.object({
    email: Yup.string().min(4, 'Username must be at least 4 characters long').required('Username is required'),
    password: Yup.string().min(6, 'Must be at least 6 characters long').required('Password is required'),
    invitation_code: Yup.string().optional()
    // verification_code: Yup.string().min(3, 'Code must be 3 characters long').max(3).required('Verification code is required'),
});

const usernameRegisterValidationSchema = Yup.object({
    email: Yup.string('Enter a valid email').required('Email is required')
});

const extendedUsernameRegisterValidationSchema = Yup.object({
    email: Yup.string().email('Enter a valid email').required('Email is required'),
    password: Yup.string('Enter a valid email').required('Email is required'),
    code: Yup.string('Enter a valid verification code').required('Verification code is required'),
    invitation_code: Yup.string().optional()
});

const AccountRegisterForm = (
    {
        onRegisterUsername,
        error,
        setError,
        t
    }
) => {

    return (
        <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={accountRegisterValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
                setError("");
                onRegisterUsername(values, setSubmitting);
            }}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form className="flex flex-col items-center justify-start w-full gap-2 mt-4">
                    <FormikInput
                        type="text"
                        name="email"
                        placeholder={t('enter_username')}
                        icon="user"
                        onChange={(e) => {
                            setFieldValue('email', e.target.value);
                        }}
                        value={values.email}
                    />
                    <ErrorMessage name="email" component="span" className="text-red-500 text-xs text-start w-full" />
                    <FormikInput
                        type="password"
                        name="password"
                        placeholder={t('enter_password')}
                        icon="lock"
                        onChange={(e) => {
                            setFieldValue('password', e.target.value);
                        }}
                        value={values.password}
                    />
                    <ErrorMessage name="password" id="password" component="span" className="text-red-500 text-xs text-start  w-full" />
                    {/* <FormikInput
                    type="text"
                    name="verification_code"
                    placeholder={t('enter_verification_code')}
                    icon="shield"
                    max="6"
                    min="6"
                />
                <ErrorMessage name="verification_code" id="verification_code" component="span" className="text-red-500 text-xs text-start  w-full" /> */}
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-pink-600 text-white p-2 text-center w-full mt-4 rounded-sm text-sm flex flex-row items-center mx-auto gap-2"

                    >
                        <span className="w-full text-center">{t('create_account')} {isSubmitting && <i className="pi pi-spin pi-spinner text-sm"></i>} </span>
                    </button>
                    {error
                        &&
                        <div className="flex flex-row items-start w-full">
                            <span className="text-red-500 text-sm">{error}</span>
                        </div>
                    }
                </Form>
            )}
        </Formik>
    )
};

const EmailRegisterForm = (
    {
        dispatch,
        navigate,
        chatId,
        t
    }
) => {
    const [emailVerified, setEmailVerified] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        console.log('emailVerified', emailVerified);
    }, [emailVerified, error]);

    return (
        <Formik
            initialValues={{ name: '', password: '', invitation_code: '' }}
            validationSchema={emailVerified ? extendedUsernameRegisterValidationSchema : usernameRegisterValidationSchema}
            onSubmit={async (values) => {
                setError("");

                try {
                    if (!emailVerified) {
                        const encodedEmail = values.email;
                        const response = await getOTP(encodedEmail);
                        console.log(response);
                        if (response.code === 400) {
                            setError(response.data.msg);
                        }
                        if (response.email && response.v_code) {
                            setEmailVerified(response.v_code);
                        }
                    } else if (emailVerified) {
                        console.log(values);
                        const encodedEmail = values.email;
                        const password = values.password;
                        const code = values.code;
                        const encodedLanguage = "en";

                        const queryString = `?email=${encodedEmail}&password=${password}&code=${code}&language=${encodedLanguage}&chat_id=${chatId}&invitation_code=${values.invitation_code}`;
                        const fullUrl = endpoints.EMAIL_REGISTER + queryString;

                        const response = await axiosInstance.post(fullUrl);
                        console.log(response.data);
                        if (response.data.code === 400) {
                            setError(response.data.msg);
                        }
                        const { access_token } = response.data.data.login_info.original;
                        const { user_info } = response.data.data;
                        dispatch(setUserData(user_info));
                        localStorage.setItem("token", JSON.stringify(access_token));
                        navigate('/');
                    }
                }
                catch (error) {
                    console.log(error);
                }
            }}
        >
            {({ isSubmitting, setFieldValue, values }) => (
                <Form className="flex flex-col items-center justify-start w-full gap-2 mt-4">
                    <FormikInput
                        type="email"
                        name="email"
                        placeholder={t('enter_email')}
                        onChange={(e) => {
                            setFieldValue('email', e.target.value);
                        }}
                        value={values.email}
                        icon="user"
                    />
                    <ErrorMessage name="email" component="span" className="text-red-500 text-xs text-start w-full" />
                    {emailVerified !== ""
                        && <>
                            <FormikInput
                                type="password"
                                name="password"
                                placeholder={t('enter_password')}
                                icon="lock"
                                onChange={(e) => {
                                    setFieldValue('password', e.target.value);
                                }}
                                value={values.password}
                            />
                            <ErrorMessage name="password" id="password" component="span" className="text-red-500 text-xs text-start w-full" />
                            <FormikInput
                                type="text"
                                name="code"
                                placeholder={t('enter_verification_code')}
                                icon="shield"
                                value={values.code}
                                onChange={(e) => {
                                    setFieldValue('code', e.target.value);
                                }}
                            />
                            <ErrorMessage name="code" id="code" component="span" className="text-red-500 text-xs text-start w-full" />
                        </>
                    }
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-pink-600 text-white p-2 text-center w-full mt-4 rounded-sm text-sm flex flex-row items-center mx-auto gap-2"
                    >
                        {/* <span className="w-full text-center">{t('create_account')} {isSubmitting && <i className="pi pi-spin pi-spinner text-sm"></i>} </span> */}
                        <span className="w-full text-center">{emailVerified ? t('create_account') : t('get_otp')} {isSubmitting && <i className="pi pi-spin pi-spinner text-sm"></i>} </span>
                    </button>
                    {
                        error && <span className="text-xs text-red-500 w-full text-start">{error}</span>
                    }
                </Form>
            )}
        </Formik>
    )
};

function Register() {

    const { t } = useTranslation();
    const [tab, setTab] = useState(1);
    const [error, setError] = useState("");
    const [chatId, setChatId] = useState("");
    const location = useLocation();
    const [invitationCode, setInvitationCode] = useState('');

    const queryParam = new URLSearchParams(location.search);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuthenticated = useSelector((state) => state.user.userData !== null);

    if (isAuthenticated) {
        navigate('/');
    }

    async function getChatId() {
        const chat_id = await generateChatId("register");
        setChatId(chat_id);
    }

    useEffect(() => {
        setInvitationCode(queryParam.get('invitation_code'));
        // console.log('queryParam', setInvitationCode(queryParam.get('invitation_code')));
        getChatId();
    }, []);

    const onChangeTab = (tab) => {
        setTab(tab);
    }

    async function onRegisterUsername(values, setSubmitting) {
        const encodedEmail = values.email;
        const encodedLanguage = "en";
        const encodedPassword = values.password;
        const invitation_code = invitationCode === null || invitationCode === "" ? "" : invitationCode;
        // const encodedCode = values.verification_code;
        const queryString = `?name=${encodedEmail}&password=${encodedPassword}&language=${encodedLanguage}&chat_id=${chatId}&invitation_code=${invitation_code}`;
        const fullUrl = endpoints.UERNAME_REGISTER + queryString;

        const response = await axiosInstance.post(fullUrl);
        if (response.data.code === 400) {
            setError(response.data.msg);
        }
        // setSubmitting(false);
        // console.log(response.data);
        const { access_token, expires_in } = response.data.data.login_info.original;
        const { user_info } = response.data.data;

        const expirationTime = Date.now() + expires_in * 1000;
        localStorage.setItem("token", JSON.stringify(access_token));
        dispatch(setAuthToken(access_token));
        localStorage.setItem('token_expires_in', JSON.stringify(expirationTime));
        // dispatch(setTokenTime(Date.now().toString()));
        fetchUserInfo(user_info.id);
    }

    async function fetchUserInfo(user_id) {
        const res = await getUserInfo(user_id);
        if (res.data) {
            dispatch(setUserData(res.data));
        }
        navigate('/');
    }

    // const onSubmit = async (values, setSubmitting) => {
    //     const encodedEmail = values.email;
    //     const encodedLanguage = "en";
    //     const encodedPassword = encodeURIComponent(values.password);
    //     const encodedCode = encodeURIComponent(values.verification_code);
    //     const queryString = `?email=${encodedEmail}&password=${encodedPassword}&code=${encodedCode}&language=${encodedLanguage}`;
    //     const fullUrl = endpoints.EMAIL_REGISTER + queryString;

    //     const response = await axiosInstance.post(fullUrl);
    //     console.log(response);
    // }

    return (
        // <div className="flex flex-col items-center justify-center w-full text-dark min-h-screen h-full pb-12 main-bg text-gray-900">
        <div className="flex flex-col items-center justify-center w-full text-dark min-h-screen h-full pb-12 main-bg text-gray-900">
            <img src={assets_path('imgs/logo.svg')} className="w-1/4 mb-4" alt="logo" />

            <div className="flex flex-col items-center justify-start w-full px-4 gap-2 mt-4">
                <div className="flex flex-row items-start justify-center w-full gap-8">
                    <div className="flex flex-col cursor-pointer" onClick={() => onChangeTab(1)}>
                        <span className={`text-sm font-semibold ${tab === 1 ? 'text-pink-600' : 'text-white'}`}>{t('account_registration')}</span>
                        {tab === 1 && <div className="border-b-2 border-w-10 border-h-2 border-pink-600" />}
                    </div>
                    <div className="flex flex-col cursor-pointer" onClick={() => onChangeTab(2)}>
                        <span className={`text-sm font-semibold ${tab === 2 ? 'text-pink-600' : 'text-white'}`}>{t('email_registration')}</span>
                        {tab === 2 && <div className="border-b-2 border-w-10 border-h-2 border-pink-600" />}
                    </div>
                </div>

                {tab === 1
                    ? <AccountRegisterForm dispatch={dispatch} onRegisterUsername={onRegisterUsername} error={error} setError={setError} t={t} />
                    : <EmailRegisterForm dispatch={dispatch} navigate={navigate} chatId={chatId} t={t} />
                }

                <div className="flex flex-col items-start justify-between w-full mt-4 gap-2">
                    <span className="text-sm text-white">{t('already_have_account')}
                        <Link to={ROUTES.LOGIN} className="text-sm font-semibold ms-2">
                            {t('log_in')}
                        </Link>
                    </span>
                    <Link to={ROUTES.HOME} className="text-sm text-white mx-auto mt-8">
                        {t('lets_go_shopping')} <i className="pi pi-chevron-right text-xs"></i>
                    </Link>
                </div>

            </div>
        </div>
    );
}

export default Register;