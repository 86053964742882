export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN';
export const GET_TOKEN_TIME = 'GET_TOKEN_TIME';
export const SET_TOKEN_TIME = 'SET_TOKEN_TIME';

export const setAuthToken = (token) => {
    return {
        type: SET_ACCESS_TOKEN,
        payload: token,
    }
}

export const getAuthToken = () => {
    return {
        type: GET_ACCESS_TOKEN,
    }
}

export const getTokenTime = () => {
    return {
        type: GET_TOKEN_TIME
    }
}

export const setTokenTime = (time) => {
    return {
        type: SET_TOKEN_TIME,
        tokenTime: time
    }
}
