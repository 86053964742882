export const SELECTED_VIDEO = "SELECTED_VIDEO";
export const FETCH_ALL_VIDEOS = "FETCH_ALL_VIDEOS";
export const SET_VIDEO_LIST = "SET_VIDEO_LIST";
export const SET_VIDEO_TYPE = "SET_VIDEO_TYPE";
export const GET_VIDEO_TYPE = "GET_VIDEO_TYPE";

export const selectVideo = (video) => {
    return {
        type: SELECTED_VIDEO,
        payload: video
    }
}

export const getAllVideos = () => {
    return {
        type: FETCH_ALL_VIDEOS
    }
}

export const setVideoList = (videos) => {
    return {
        type: SET_VIDEO_LIST,
        payload: SET_VIDEO_LIST
    }
}

export const setVideoType = (type) => {
    return {
        type: SET_VIDEO_TYPE,
        payload: type
    }
}

export const getVideoType = () => {
    return {
        type: GET_VIDEO_TYPE
    }
}
