import { GET_ACCESS_TOKEN, GET_TOKEN_TIME, SET_ACCESS_TOKEN, SET_TOKEN_TIME } from "../actions/tokenAction"

const initialState = {
    token: null,
    tokenTime: 0
}

const tokenReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACCESS_TOKEN:
            return {
                ...state
            }
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case GET_TOKEN_TIME:
            return state.tokenTime;
        case SET_TOKEN_TIME:
            return {
                ...state,
                tokenTime: action.payload
            }
        default:
            return state;
    }
}

export default tokenReducer;
