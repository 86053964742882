import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import VideoPreviewComponent from "../components/VideoPreviewComponent";
import { Button } from "primereact/button";
import { ROUTES } from "../constants/Routes";
import { assets_path, fetchAllVideoTags, fetchVideoTags, getVideoList, searchVideo } from "../utils/Utils";
import Input from "../components/Input";
import { InputText } from "primereact/inputtext";
import TabView from "../components/TabView";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { useSelector } from "react-redux";

// function SearchContainer({ t }) {



//     return (
//         <div>
//             <div className="flex flex-row items-center justify-between w-full main-bg px-2 py-1">
//                 <Link to={ROUTES.VIDEO}>
//                     <i className="pi pi-arrow-left text-sm me-2" />
//                 </Link>
//                 {/* <input placeholder="Search" className="p-1 rounded-md text-gray-900 text-sm w-3/5" onChange={e => setSearch(e.target.value)} /> */}
//                 {/* <Input placeholder={"Search videos"} icon={'search'} /> */}
//                 <InputText placeholder="Search videos" className="rounded-none text-xs bg-gray-700" onChange={e => setSearch(e.target.value)} />
//                 <span className="text-xs p-1 ms-1 cursor-pointer" label="Search" onClick={debouncedFetch}>Search</span>
//             </div>

//             <TabContent activeTab={activeTab} onChangeTab={onChangeTab} tabsData={tabsData} t={t} videosList={videosList} debouncedFetch={debouncedFetch} />
//         </div>
//     );
// }

function TabContentHeader(
    {
        onChangeTab,
        activeTab,
        tabsData,
        t,
        videosList,
        debouncedFetch,
        videoTags
    }
) {
    return (
        <div className="">
            <div className="flex flex-row items-start justify-start px-2">
                <span className="text-xs mt-3">Everyone is searching</span>
            </div>
            <div className="flex flex-col items-start justify-start gap-2 mt-3">
                <div className="flex flex-row flex-wrap items-start justify-start gap-2 px-2 py-1">
                    <span className="text-white text-xs p-1 px-2 bg-[#292C33] cursor-pointer" onClick={() => debouncedFetch('all')} >{t('all')}</span>
                    {videoTags.length
                        ? videoTags.map((tag) => (
                            <span className="text-white text-xs p-1 px-2 bg-[#292C33] cursor-pointer" onClick={() => debouncedFetch(tag.type_name)} key={tag._id} >{tag.type_name}</span>
                        ))
                        :
                        <></>
                    }
                </div>
            </div>


            <TabView activeTab={activeTab} onChangeTab={onChangeTab} tabStyle="border" data={tabsData} />


            <div className="bg-[#292C33] p-2 flex flex-col items-start justify-center">
                <div className="flex flex-row items-center justify-start gap-1">
                    <img src={assets_path('imgs/fire-icon-100.svg')} />
                    <span className="text-sm font-semibold">{t('top_100_videos')}</span>
                </div>
                <div className="grid grid-cols-2 gap-2 py-2">
                    {videosList.length
                        ? videosList.map((video) => (
                            <div className="w-full" key={video._id}>
                                <VideoPreviewComponent video={video} />
                            </div>
                        ))
                        :
                        <></>
                    }
                </div>
            </div>
        </div>
    )
}


function TabContent({ activeTab, onChangeTab, tabsData, t, videosList, debouncedFetch, videoTags, loading, items, fetchMoreData }) {

    return (
        activeTab === 1 ?
            <TabContentHeader activeTab={activeTab} onChangeTab={onChangeTab} tabsData={tabsData} t={t} videosList={videosList} debouncedFetch={debouncedFetch} videoTags={videoTags} />
            :
            activeTab === 2 ?
                <div className="flex flex-col main-bg">
                    <TabContentHeader activeTab={activeTab} onChangeTab={onChangeTab} tabsData={tabsData} t={t} videosList={videosList} debouncedFetch={debouncedFetch} videoTags={videoTags} />
                </div>
                :
                activeTab === 3 &&
                <div className="flex flex-col main-bg">
                    <TabContentHeader activeTab={activeTab} onChangeTab={onChangeTab} tabsData={tabsData} t={t} videosList={videosList} debouncedFetch={debouncedFetch} videoTags={videoTags} />
                </div>
    );
}

function SearchPage() {
    const { t } = useTranslation();

    const [activeTab, setActiveTab] = useState(1);
    const [search, setSearch] = useState("");
    const [videosList, setVideosList] = useState([]);
    const [videoTags, setVideoTags] = useState([]);

    const userData = useSelector((state) => state.user.userData);

    const { loading, items, fetchMoreData } = useInfiniteScroll();


    const tabsData = [
        {
            label: t("Current Hottest"),
            onChange: () => setActiveTab(1),
            activeTab
        },
        {
            label: t("Monthly Hottest"),
            onChange: () => setActiveTab(2),
            activeTab
        },
        {
            label: t("Last Monthly Hottest"),
            onChange: () => setActiveTab(3),
            activeTab
        }
    ]

    const onChangeTab = value => {
        setActiveTab(value);
    }

    async function fetchVideos() {
        const videosList = await getVideoList();
        // console.log(videosList);
        setVideosList(videosList.list);
    }

    async function getVideoTags() {
        const tags = await fetchAllVideoTags();
        // console.log(tags);
        if (tags.length) {
            setVideoTags(tags);
        }
    }

    useEffect(() => {
        console.log(userData);
        fetchVideos();
        getVideoTags();
    }, []);

    function debounce(func, delay) {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }

    const debouncedFetch = debounce(async function (searchValue) {
        console.log(searchValue);

        let res;
        if (searchValue === 'all') {
            fetchVideos();
        } else {
            const res = await searchVideo(searchValue);
            setVideosList(res.data.list);
        }

        // Set the videos list based on the search result
    }, 500);

    function onClearInput() {
        setSearch("");
        fetchVideos();
    }

    return (
        <div className="min-h-screen main-bg pb-8">
            <div className="flex flex-row items-center justify-between w-full main-bg px-2 py-1">
                <Link to={ROUTES.VIDEO}>
                    <i className="pi pi-arrow-left text-sm me-2" />
                </Link>
                <div className="bg-gray-700 p-0 m-0 text-xs w-full flex flex-row items-center justify-between">
                    <InputText placeholder="Search videos" value={search} className="bg-gray-700 rounded-none text-xs" onChange={e => {
                        if (e.target.value === "") {
                            onClearInput();
                        }
                        else {
                            setSearch(e.target.value)
                        }
                    }} />
                    {search === '' ? <></> : <i className="pi pi-times-circle text-sm mr-1 text-gray-400" onClick={onClearInput}></i>}
                </div>
                {/* <InputText placeholder="Search videos" className="rounded-none text-xs bg-gray-700" onChange={e => {
                    if (e.target.value === "") {
                        fetchVideos();
                    }
                    else {
                        setSearch(e.target.value)
                    }
                }} /> */}
                <span className="text-xs p-1 ms-1 cursor-pointer" label="Search" onClick={() => {
                    if (search === "") {
                        debouncedFetch('all');
                    }
                    else {
                        debouncedFetch(search);
                    }
                }}>Search</span>
            </div>

            <TabContent
                activeTab={activeTab}
                onChangeTab={onChangeTab}
                tabsData={tabsData}
                t={t}
                videosList={videosList}
                debouncedFetch={debouncedFetch} videoTags={videoTags}
                loading={loading}
                items={items}
                fetchMoreData={fetchMoreData}
            />
        </div>
    );
}

export default SearchPage;