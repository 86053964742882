import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/Routes";
import { assets_path } from "../utils/Utils";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setVideoType } from "../screens/store/actions/videoAction";

function TopBar({ t, videoTypes }) {

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState(1);

    const { pathname } = location;
    const onChangeTab = (value, type_id) => {
        if (pathname !== ROUTES.CATEGORY_PAGE) {
            navigate(ROUTES.CATEGORY_PAGE);
        }
        setActiveTab(value);
        dispatch(setVideoType(type_id));
        console.log(value);
    }

    useEffect(() => {
        if (videoTypes.length > 0) {
            setLoading(false);
        }
        console.log(videoTypes);
    }, [videoTypes])


    if (loading) {
        return (
            <></>
        )
    }
    else return (
        <div className="flex flex-col w-full bg-gray-900 p-2">
            <div className="flex flex-row items-center justify-between w-full">
                <img src={assets_path('imgs/logo.svg')} className="w-[40px]" alt="" />
                <div className="ms-2 flex flex-row items-center justify-between gap-2 w-full">
                    <span className="p-input-icon-left text-dark">
                        <i className="pi text-sm my-auto -mt-1 pi-search font-bold" />
                        <InputText placeholder={t("Search")} className="bg-gray-800 text-sm p-1 w-full ps-8 focus:shadow-none"
                            onClick={() => navigate(ROUTES.SEARCH)}
                        />
                    </span>
                    <img src={assets_path('imgs/notify-icon.svg')} className="w-[30px]" alt="" />
                    <Link to={ROUTES.SUPPORT} className="px-1 cursor-pointer text-white" >
                        <img src={assets_path('imgs/support-icon.svg')} className="w-[30px]" alt="" />
                    </Link>
                    <Link to={ROUTES.LANGUAGE_SELECTION} className="px-1 cursor-pointer text-white" >
                        <img src={assets_path('imgs/globe-icon.svg')} className="w-[30px]" alt="language selection" />
                    </Link>
                </div>
            </div>
            <div className="flex flex-row items-center justify-start overflow-x-auto overscroll-contain py-2 w-full">
                <Link to={ROUTES.VIDEO} className="text-white text-[12px] mx-2 whitespace-nowrap">{t('followed')}</Link>
                <Link to={ROUTES.HOME} className={`${pathname === ROUTES.HOME ? 'text-pink-500' : 'text-white'} text-[12px] mx-2 whitespace-nowrap`}>{t('top')}</Link>
                {videoTypes.length
                    ? videoTypes.map((type, i) => (
                        <span
                            onClick={() => onChangeTab(i + 1, type._id)}
                            className={`text-[12px] mx-2 whitespace-nowrap cursor-pointer ${activeTab === i + 1 && pathname !== ROUTES.HOME ? 'text-pink-500' : 'text-white'} `} key={type._id}>{type.type_name}</span>
                    ))
                    :
                    <></>
                }
            </div>
        </div>
    );
}

export default TopBar;
