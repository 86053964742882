import React from 'react';
import ReactDOM from 'react-dom/client';
import 'primeicons/primeicons.css';
import './index.css';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import "./configs/i18.config";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Provider } from 'react-redux';
// import store from './screens/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './screens/store/store';
import { VideoContextProvider } from './screens/store/ContextApi';
// import { AuthProvider } from './providers/AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className='app-drawer'>
      {/* <AuthProvider> */}
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <VideoContextProvider>
            <App />
          </VideoContextProvider>
        </PersistGate>
      </Provider>
      {/* </AuthProvider> */}
    </div>
  </React.StrictMode>
);
