import axios from 'axios';
import { refreshAuth } from '../utils/Utils';
import { ROUTES } from '../constants/Routes';
import moment from 'moment';
// import { useEffect } from 'react';
// import handleAuthHeaders from '../services/authService';
// export const baseURL = "https://yvmmss.top/api/";
export const baseURL = "https://tmlemgran.com/api/";
// export const baseURL = "https://8.217.168.211/api/";
// export const mediaBaseURL = "http://yvmmss.top/storage/";
export const mediaBaseURL = "https://tmlemgran.com/storage/";
// import axios from 'axios';
// // import { isTokenExpired } from '../utils/Utils';
// // import { useAuth } from '../providers/AuthContext';
// import { handleAuthHeaders } from '../services/authService';
// import { useAuth } from '../providers/AuthContext';

// const instance = axios.create();
// // export const baseURL = "https://dummyjson.com/";

// const { refreshAccessToken } = useAuth();

// instance.interceptors.request.use(
//     (config) => handleAuthHeaders(config, refreshAccessToken),
//     // {
//     //     // const { refreshAccessToken } = useAuth();
//     //     // if (isTokenExpired()) {
//     //     //     refreshAccessToken();
//     //     // }
//     //     handleAuthHeaders();
// if (config.headers.requiresAuth) {
//     const token = localStorage.getItem('token'); // Retrieve the authentication token from wherever it is stored
//     if (token) {
//         config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
//     }
// }
//     //     return config;
//     // },
//     (error) => {
//         // Handle the request error
//         return Promise.reject(error);
//     }
// );

// instance.interceptors.response.use(
//     (response) => {
//         // Modify the response data
//         // You can handle success responses here
//         return response;
//     },
//     (error) => {
//         // Handle the response error
//         if (error.response) {
//             // The request was made and the server responded with a status code
//             // that falls out of the range of 2xx

//             if (error.response.status === 500) {
//                 // Handle 500 error here
//                 return error.response.data;
//             }
//         } else if (error.request) {
//             // The request was made but no response was received
//         } else {
//             // Something happened in setting up the request that triggered an Error
//         }

//         return Promise.reject(error);
//     }
// );

// export default instance;


// axiosInstance.js


// const useAxiosInterceptor = () => {
//     const { refreshAccessToken } = useAuth();

//     useEffect(() => {
//         const instance = axios.create();

//         instance.interceptors.request.use(
//             (config) => handleAuthHeaders(config, refreshAccessToken),
//             (error) => {
//                 // Handle the request error
//                 return Promise.reject(error);
//             }
//         );

//         instance.interceptors.response.use(
//             (response) => {
//                 // Modify the response data
//                 // You can handle success responses here
//                 return response;
//             },
//             (error) => {
//                 // Handle the response error
//                 if (error.response) {
//                     // The request was made and the server responded with a status code
//                     // that falls out of the range of 2xx

//                     if (error.response.status === 500) {
//                         // Handle 500 error here
//                         return error.response.data;
//                     }
//                 } else if (error.request) {
//                     // The request was made but no response was received
//                 } else {
//                     // Something happened in setting up the request that triggered an Error
//                 }

//                 return Promise.reject(error);
//             }
//         );

//         // Cleanup function if needed
//         return () => {
//             // Cleanup logic, if any
//         };
//     }, []);
//     // }, [refreshAccessToken]);

//     return null; // No rendering is needed for this component
// };

// export default useAxiosInterceptor;

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    async (config) => {
        // Refresh the access token if it's expired
        // const { refreshAccessToken } = useAuth();

        // try {
        //     const newToken = await refreshAuth();

        //     if (newToken && newToken !== 'Unauthorized') {
        //         // If the token is successfully refreshed, update the original request header
        //         config.headers.Authorization = `Bearer ${newToken}`;
        //         localStorage.setItem('token', JSON.stringify(newToken));
        //         return axiosInstance(config);
        //     }
        // } catch (error) {
        //     // Handle error refreshing access token
        //     console.error('Error refreshing access token:', error);
        //     // You might want to redirect to the login page or handle the error in some way
        // }

        // Add authentication headers
        // return handleAuthHeaders(config);

        const token = localStorage.getItem('token');
        const tokenExpiresIn = localStorage.getItem('token_expires_in');

        const expirationTime = parseInt(JSON.parse(tokenExpiresIn), 10);
        const currentTime = Date.now();

        if (!token || currentTime > expirationTime) {
            // console.log(moment.utc(currentTime).format());
            // console.log(moment.utc(expirationTime).format());
            // console.log('token expired');
            await refreshAuth();
        }
        if (config.headers.requiresAuth) {
            config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
        }

        // if (config.headers.requiresAuth) {
        //     // if (currentTime > expirationTime || (expirationTime - currentTime) < 3000000) {
        //     if (currentTime > expirationTime) {
        //         await refreshAuth();
        //     }
        //     else if (token) {
        //         console.log('currentTime', currentTime, expirationTime);
        //         config.headers.Authorization = `Bearer ${JSON.parse(token)}`;
        //     }
        // }
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

// let refreshFailed = false;
// let isRefreshing = false;
// let retries = 2;

// axiosInstance.interceptors.response.use(
//     async (response) => {
//         // Do something with successful response
//         console.log('config===>>', response.data);
//         if (response.data.msg === 'Unauthorized') {
//             if (!isRefreshing) {
//                 isRefreshing = true;
//                 retries--;

//                 if (retries === 0) {
//                     window.location.href = ROUTES.LOGIN;
//                 }

//                 try {
//                     console.log('Refreshing token...');
//                     const newToken = await refreshAuth();

//                     if (newToken && newToken !== 'Unauthorized') {
//                         // If the token is successfully refreshed, update the original request header
//                         response.config.headers.Authorization = `Bearer ${newToken}`;
//                         return axiosInstance(response.config);
//                     } else {
//                         // If token refresh fails, set a flag to prevent further attempts
//                         refreshFailed = true;
//                         // Navigate to the login page
//                         window.location.href = ROUTES.LOGIN;
//                     }
//                 } catch (refreshError) {
//                     console.error('Error refreshing token:', refreshError);
//                     // Navigate to the login page on refresh error
//                     window.location.href = ROUTES.LOGIN;
//                 } finally {
//                     isRefreshing = false;
//                 }
//             } else {
//                 // If already refreshing, wait for the token refresh to complete
//                 await new Promise((resolve) => setTimeout(resolve, 100));
//             }
//         }
//         return response;
//     },
//     async (error) => {
//         // Do something with response error
//         const originalRequest = error.config;

//         // Check if the error is due to an expired token and refresh token failed
//         if (error.response && error.response.status === 401 && !originalRequest._retry && !refreshFailed) {
//             originalRequest._retry = true;

//             // Attempt to refresh the token
//             return axiosInstance(originalRequest);
//         }

//         return Promise.reject(error);
//     }
// );

// Add a response interceptor

axiosInstance.interceptors.response.use(
    async (response) => {
        // const originalRequest = response.config;
        // console.log(response);
        if (response.data.code === 400 && response.data.error_field === 'Unauthorized') {
            // window.location.href = ROUTES.LOGIN;
        }
        // Do something with successful response
        // if (response.data.msg === 'Unauthorized') {
        //     console.log('refreshing token...');
        // const newToken = await refreshAuth();

        // if (newToken && newToken !== "Unauthorized") {
        //     // If the token is successfully refreshed, update the original request header
        //     originalRequest.headers.Authorization = `Bearer ${newToken}`;
        //     return axiosInstance(originalRequest);
        // } else {
        //     // If token refresh fails, navigate to the login page
        //     window.location.href = ROUTES.LOGIN;
        // }
        // }
        return response;
    },
    async (error) => {
        // Do something with response error
        const originalRequest = error.config;

        // Check if the error is due to an expired token
        // if (error.response.status === 401 && !originalRequest._retry) {
        //     originalRequest._retry = true;

        //     // Attempt to refresh the token
        //     const newToken = await refreshAuth();

        //     if (newToken) {
        //         // If the token is successfully refreshed, update the original request header
        //         originalRequest.headers.Authorization = `Bearer ${newToken}`;
        //         return axiosInstance(originalRequest);
        //     } else {
        //         // If token refresh fails, navigate to the login page
        //         window.location.href = ROUTES.LOGIN;
        //     }
        // }

        return Promise.reject(error);
    }
);

export default axiosInstance;