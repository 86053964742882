import { useEffect, useState } from "react";
import { assets_path, validateEmail } from "../../utils/Utils";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/Routes";
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik } from "formik";
import { useNavigate } from 'react-router-dom';
import SwipeToConfirm from "../../components/Swiper/SwipeToConfirm";
import lang from "../../locale/LocalizedString";
import { endpoints } from "../../interceptors/endPoints";
import axiosInstance from "../../interceptors/axiosInterceptor";
import FormikInput from "../../components/FormikInput";
import { useDispatch, useSelector } from "react-redux";
import {
    checkVerificationCodeTimer,
    disableVerificationCode,
    enableVerificationCodeAfterDelay
} from "../store/actions/verificationCode";
import SuccessPage from "../SuccessPage";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Enter a valid email').required('Email is required'),
    verificationCode: Yup.string('Enter correct verification code').required('Verification code is required'),
    newPassword: Yup.string().min(6, 'Must be at least 6 characters long').required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const initialValues = { email: '', verificationCode: '', newPassword: '', confirmPassword: '' };


const ForgetPasswordForm = (
    {
        onResetPassword,
        dispatch,
        t
    }
) => {

    const [email, setEmail] = useState("");
    const [v_code, setV_code] = useState("");

    const { verificationCodeEnabled } = useSelector((state) => state.v_code);
    // const dispatch = useDispatch();

    const onGetCode = async (email, setFieldValue) => {
        console.log(email);
        dispatch(disableVerificationCode());

        if (email !== "" && validateEmail(email)) {
            const queryString = `?email=${email}&language=${'en'}`;
            const fullUrl = endpoints.GET_EMAL + queryString;

            const response = await axiosInstance.post(fullUrl);
            console.log(response.data);
            if (response.data.code === 200) {
                setV_code(response.data.data.v_code);
                // setFieldValue('verificationCode', response.data.data.v_code);
            }

            if (verificationCodeEnabled) {
                dispatch(enableVerificationCodeAfterDelay());
            }
        }
        else {
            toast.error("Enter a valid email");
        }
    }
    return (
        <Formik
            initialValues={{ email: '', verificationCode: '', newPassword: '', confirmPassword: '' }}
            validationSchema={validationSchema}
            onSubmit={values => {
                onResetPassword(values);
                console.log(values);
            }}
        >
            {({ isSubmitting, errors, touched, setFieldValue, values }) => (
                <Form className="flex flex-col items-center justify-start w-full gap-4 mt-4">
                    <FormikInput
                        type="email"
                        name="email"
                        placeholder={t('enter_email')}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setFieldValue('email', e.target.value);
                        }}
                        value={values.email}
                        icon="user"
                    />
                    <ErrorMessage name="email" component="span" className="text-red-500 text-xs text-start w-full" />
                    <FormikInput
                        type="text"
                        name="verificationCode"
                        placeholder={t('enter_verification_code')}
                        icon="shield"
                        code="true"
                        value={values.verificationCode}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setFieldValue('verificationCode', e.target.value);
                        }}
                        codeEnabled={verificationCodeEnabled}
                        onClick={() => onGetCode(email, setFieldValue)}
                    />
                    <ErrorMessage name="verificationCode" id="verificationCode" component="span" className="text-red-500 text-xs text-start w-full" />
                    <FormikInput
                        type="password"
                        name="newPassword"
                        placeholder={t('enter_password')}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setFieldValue('newPassword', e.target.value);
                        }}
                        value={values.newPassword}
                        icon="lock"
                    />
                    <ErrorMessage name="newPassword" id="newPassword" component="span" className="text-red-500 text-xs text-start w-full" />
                    <FormikInput
                        type="password"
                        name="confirmPassword"
                        placeholder={t('confirm_password')}
                        onChange={(e) => {
                            setEmail(e.target.value);
                            setFieldValue('confirmPassword', e.target.value);
                        }}
                        value={values.confirmPassword}
                        icon="lock"
                    />
                    <ErrorMessage name="confirmPassword" id="confirmPassword" component="span" className="text-red-500 text-xs text-start w-full" />

                    {/* <SwipeToConfirm /> */}

                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="bg-pink-600 text-white p-2 text-center w-full mt-4 rounded-sm text-sm flex flex-row items-center mx-auto gap-2"
                    >
                        <span className="w-full text-center">{t('reset_password')} {isSubmitting && <i className="pi pi-spin pi-spinner text-sm"></i>} </span>
                    </button>
                </Form>
            )}
        </Formik>
    );
}

function ForgetPassword() {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [successPageVisible, setSuccessPageVisible] = useState(false);

    const [error, setError] = useState("");

    async function onResetPassword(values) {
        const email = values.email;
        const password = values.newPassword;
        const password_confirmation = values.confirmPassword;
        const language = "en";
        const code = values.verificationCode;

        const queryString = `?email=${email}&password=${password}&password_confirmation=${password_confirmation}&language=${language}&code=${code}`;
        const fullUrl = endpoints.FORGET_PASSWORD + queryString;
        const response = await axiosInstance.post(fullUrl);
        if (response.data.code == 400) {
            setError("Something went wrong!");
        }
        else {
            console.log("Password reset successfully!");
            setSuccessPageVisible(true);
        }
    }

    const navigateToLogin = () => {
        navigate(ROUTES.LOGIN);
    }

    // checkVerificationCodeTimer();
    useEffect(() => {
        dispatch(checkVerificationCodeTimer());
    }, []);

    if (!successPageVisible) {
        return (
            <div className="flex flex-col items-center justify-center w-full text-dark min-h-screen h-full pb-12 main-bg text-gray-900">
                <div className="flex flex-row items-center justify-between py-2 w-full mb-4 px-2 shadow-md border-b-1 border-b-gray-300">
                    <i className="pi pi-arrow-left text-gray-400 p-2 cursor-pointer text-sm" onClick={() => { navigate(-1) }} />
                    <span className="text-sm text-white">{t('forget_password')}</span>
                    <div className="p-2"></div>
                </div>
                <img src={assets_path('imgs/logo.svg')} className="w-1/4 mb-4" />

                <div className="flex flex-col items-center justify-start px-2 gap-4 mt-4 w-full">
                    <div className="flex flex-col items-start justify-start w-full md:px-0">
                        <span className="text-sm text-red-500">{t('doest_not_bind')}</span>
                    </div>

                    <ForgetPasswordForm onResetPassword={onResetPassword} dispatch={dispatch} t={t} />

                    <Link to={ROUTES.SUPPORT} className="font-bold text-white underline items-end text-sm">{t('Contact Customer Service')}</Link>
                </div>

                <div className="vertical-spacer" />

                <ToastContainer position="top-right" autoClose={2000} />
            </div>
        );
    }
    else {
        return (
            <SuccessPage title="Password reset successfully" btnText="Return to Profile" resetPassword={true} onNavigate={() => navigate(ROUTES.LOGIN)} />
        );
    }
}

export default ForgetPassword;