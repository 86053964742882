import { useContext, useEffect, useState } from "react";
import VideoPreviewComponent from "../../components/VideoPreviewComponent";
import { useTranslation } from "react-i18next";
import { VideoContext } from "../store/ContextApi";
import { VIDEO_TYPE } from "./VideoPage";
import InfiniteScroll from "react-infinite-scroll-component";
import useInfiniteScroll from "../../hooks/useInfiniteScroll";

function TabContentContainer({ type }) {

    const [activeTab, setActiveTab] = useState(1);

    const [next, setNext] = useState(10);
    const [limit] = useState(10);

    const { t } = useTranslation();

    const { loading, items, setItems, fetchMoreData, collection } = useInfiniteScroll();


    const {
        collection: videoList,
        setVideoList,
        fetchMyCollection,
        harborVideosList,
        activeInnerTab,
        setActiveInnerTab,
        ResetList,
        setPage
    } = useContext(VideoContext);


    useEffect(() => {
        if (type === VIDEO_TYPE.allVideos) {
            setVideoList(activeTab);
        }
        else if (type === VIDEO_TYPE.myCollection) {
            fetchMyCollection();
        }
    }, [activeTab])
    console.log("Type===>>", items);

    useEffect(() => {
    }, [type]);

    // useEffect(() => {
    //     console.log(activeInnerTab, activeTab);
    //     if (activeInnerTab === 1) {
    //         setVideoList(activeInnerTab);
    //     }
    //     else if (activeInnerTab === 2) {
    //         fetchMyCollection();
    //     }
    // }, [activeInnerTab])


    const onChangeInnerTab = value => {
        setPage(1);
        setItems([]);
        setActiveInnerTab(value);
        ResetList(true);
        // fetchMoreData();
    }

    async function onFetchMore() {
        setNext(next + 10);
    }

    function LoadingTemplate() {
        return (
            <div className="w-full my-2">
                <span className="text-sm text-gray-500">{loading ? 'Loading...' : ''}</span>
            </div>
        )
    }

    return (
        <div className="flex flex-col main-bg min-h-screen">
            {
                type === VIDEO_TYPE.allVideos
                && <div className="flex flex-col items-start justify-start gap-2">

                    <div className="flex flex-row items-start justify-start gap-1 p-2 flex-wrap">
                        <span className={`text-white text-xs p-1 px-2 cursor-pointer ${activeInnerTab === 2 && 'border-b border-pink-600'}`}
                            onClick={() => onChangeInnerTab(2)}>
                            {t('comprehensive_sorting')}
                        </span>
                        <span className={`text-white text-xs p-1 px-2 cursor-pointer ${activeInnerTab === 3 && 'border-b border-pink-600'}`}
                            onClick={() => onChangeInnerTab(3)}>
                            {t('most_played')}
                        </span>
                        <span className={`text-white text-xs p-1 px-2 cursor-pointer ${activeInnerTab === 4 && 'border-b border-pink-600'}`}
                            onClick={() => onChangeInnerTab(4)}>
                            {t('new_arrival')}
                        </span>
                        <span className={`text-white text-xs p-1 px-2 cursor-pointer ${activeInnerTab === 5 && 'border-b border-pink-600'}`}
                            onClick={() => onChangeInnerTab(5)}>
                            {t('most_collected')}
                        </span>
                    </div>
                </div>
            }

            <div className="grid grid-cols-1 gap-2 p-2 mb-8 w-full">

                {type === VIDEO_TYPE.allVideos ?
                    <InfiniteScroll
                        dataLength={collection.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={<LoadingTemplate />}
                        className=" grid grid-cols-2 gap-2 p-2 w-full"
                    >
                        {collection.map((video, key) => (
                            <div key={key} className="cursor-pointer">
                                <div className="cursor-pointer infinite-scroll gap-2">
                                    <VideoPreviewComponent video={video} />
                                </div>
                            </div>
                        ))
                        }
                    </InfiniteScroll>
                    :
                    type === VIDEO_TYPE.myCollection &&
                        harborVideosList.length
                        ?
                        <InfiniteScroll
                            dataLength={harborVideosList.length}
                            next={fetchMoreData}
                            hasMore={true}
                            loader={<LoadingTemplate />}
                            className=" grid grid-cols-2 gap-2 p-2 w-full"
                        >
                            {harborVideosList.map((video, key) => (
                                <div key={key} className="cursor-pointer">
                                    <div className="cursor-pointer infinite-scroll gap-2">
                                        <VideoPreviewComponent video={video} />
                                    </div>
                                </div>
                            ))
                            }
                        </InfiniteScroll>
                        :
                        <div className="flex flex-row items-center justify-center w-full">
                            <span className="text-gray-500 text-sm w-full text-center">No videos yet</span>
                        </div>
                }
                {/* {type === VIDEO_TYPE.allVideos ?

                    videoList.length > 0 && videoList.map((video, key) => (
                        videoList.length > 0
                            ? (
                                <div className="cursor-pointer">
                                    <div key={key} className="cursor-pointer infinite-scroll gap-2">
                                        <VideoPreviewComponent video={video} />
                                    </div>
                                </div>
                            )
                            :
                            <>
                                <div className="flex flex-row w-full items-center justify-center">
                                    <span className="text-sm text-gray-700 w-full text-center">{t("no_content")}</span>
                                </div>
                            </>
                    ))
                    :
                    type === VIDEO_TYPE.myCollection &&
                        harborVideosList.length > 0
                        ? harborVideosList.map((video, key) => (
                            <div key={key} className="cursor-pointer infinite-scroll gap-2">
                                <VideoPreviewComponent video={video} />
                            </div>
                        ))
                        :
                        <>
                            <div className="flex flex-row w-full items-center justify-center">
                                <span className="text-sm text-gray-700 w-full text-center">{t("no_content")}</span>
                            </div>
                        </>
                } */}
            </div>
        </div>
    );
}

export default TabContentContainer;