import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoPlayer from "../components/VideoPlayer";
import { addToFavoriteVideo, assets_path, attentionUser, cancelAttentionUser, fetchRelatedVideos, fetchVideoTags, likeVideo } from "../utils/Utils";
import { faClock, faEye, faFireAlt } from "@fortawesome/free-solid-svg-icons";
import VideoPreviewComponent from "../components/VideoPreviewComponent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import moment from "moment";
import { selectVideo } from "./store/actions/videoAction";
import { mediaBaseURL } from "../interceptors/axiosInterceptor";
import { ToastContainer, toast } from "react-toastify";
import lang from "../locale/LocalizedString";
import { addToWatchlist, fetchWatchlist, removeFromWatchlist } from "./store/actions/watchlistAction";
import { useTranslation } from 'react-i18next';
import { setInfluencerDetail } from "./store/actions/userActions";
import { ROUTES } from "../constants/Routes";
import { useNavigate } from "react-router";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import InfiniteScroll from "react-infinite-scroll-component";
import useLanguage from "../hooks/useLanguage";

function ChannelPage() {

    const { t } = useTranslation();
    const { language } = useLanguage();
    const navigate = useNavigate();
    const { loading: dataLoading, items, fetchMoreData } = useInfiniteScroll();

    const [loading, setLoading] = useState(true);
    const [relatedLoaded, setRelatedLoaded] = useState(false);
    const [relatedVideos, setRelatedVideos] = useState([]);
    const selectedVideo = useSelector((state) => state.video.selectedVideo);
    // const userData = useSelector((state) => state.user.userData || null);
    const userData = useSelector((state) => state.user.userData) ?? { userData: null };
    // const watchlistUser = useSelector((state) => state.watchlist.filter(u => parseInt(u.target_user_id) === selectedVideo.user_info.id));
    const watchlistUser = useSelector((state) => {
        const selectedUserId = selectedVideo?.user_info?.id;

        if (selectedUserId !== undefined && state.watchlist !== null) {
            return state.watchlist.filter(u => parseInt(u?.target_user_id) === selectedUserId);
        }

        // Handle the case when selectedVideo is null or user_info.id is undefined
        return [];
    });

    const [videoTags, setVideoTags] = useState([]);
    const dispatch = useDispatch();


    const { user_info, ...video } = selectedVideo;

    async function fetchVideos() {
        const videosList = await fetchRelatedVideos(5);

        setRelatedVideos(videosList.list.filter(vid => vid._id !== video._id));
        setRelatedLoaded(true);
    }

    async function getVideoTags() {
        const tags = await fetchVideoTags([...selectedVideo.video_tag]);
        if (tags.list.length) {
            setVideoTags(tags.list);
        }
    }

    useEffect(() => {
        if (userData === null) {
            return;
        }
        dispatch(fetchWatchlist());
    }, [dispatch])

    useEffect(() => {
        fetchVideos();
    }, []);

    useEffect(() => {
        if (selectedVideo) {
            getVideoTags();
            setLoading(false);
        }
    }, [selectedVideo, relatedVideos])

    async function onLikeVideo() {
        const { ...video } = selectedVideo;
        if (userData !== null && userData.id !== undefined) {
            const res = await likeVideo(userData.id, video._id);
            if (res.code === 200) {
                video.like += 1;
                const likedVideo = {
                    ...selectedVideo,
                    like: video.like,
                    is_like: true
                };
                dispatch(selectVideo(likedVideo));
            }
        }
        else {
            toast.error("Log in is required to like the video");
        }
    }

    async function onHarborVideo() {
        const { user_info, ...video } = selectedVideo;
        if (userData !== null && userData.id !== undefined) {
            const res = await addToFavoriteVideo(userData.id, video._id);
            if (res.code === 200) {
                video.harbor += 1;
                const harborVideo = {
                    ...selectedVideo,
                    harbor: video.harbor,
                    is_harbor: true
                };
                dispatch(selectVideo(harborVideo));
            }
        }
        else {
            toast.error("Please log in before adding to favorites.");
        }
    }

    async function onAttentionUser() {
        if (userData !== null && userData.id !== undefined) {
            const { user_info } = selectedVideo;
            const res = await attentionUser(user_info.id);
            if (res.code === 400) {
                toast.error(res.msg);
            }
            else if (res.code === 200) {
                toast.success("User added to focus list");
                dispatch(addToWatchlist(res.data.data));
            }
        }
        else {
            toast.error('Please login before following the user');
        }
    }

    async function cancelAttention() {
        // const { _id } = selectedVideo;
        const { id } = selectedVideo.user_info;
        const res = await cancelAttentionUser(id);
        dispatch(removeFromWatchlist(id));
    }

    async function onViewUserDetail() {
        const { user_info } = selectedVideo;
        dispatch(setInfluencerDetail(user_info));
        navigate(ROUTES.PERSONAL_DETAILS);
    }

    function LoadingTemplate() {
        return (
            <div className="w-full my-2">
                <span className="text-sm text-gray-500">{dataLoading ? 'Loading...' : ''}</span>
            </div>
        )
    }

    if (loading) {
        <div className="card flex flex-col align-items-end justify-content-end main-bg pb-12 min-h-screen">
            <i className="pi pi-spin">Loading...</i>
        </div>
    }
    else return (
        <div className="card flex flex-col align-items-end justify-content-end main-bg pb-12 min-h-screen">

            <ToastContainer position="top-right" autoClose={2000} />
            <VideoPlayer src={video.video_url} />

            <div className="flex flex-row items-center justify-between border-b border-gray-800 mx-2 py-2">
                <div className="flex flex-row items-center justify-start p-1 gap-1 cursor-pointer" onClick={onViewUserDetail}>
                    {user_info.avatar ?
                        <img src={mediaBaseURL + user_info.avatar} className="w-[55px] rounded-full" alt={user_info.name} />
                        :
                        <img src={assets_path("avatar.jpg")} className="w-[55px] rounded-full" alt={user_info.name} />
                    }
                    <div className="flex flex-col items-start justify-start gap-1">
                        <span className="text-sm font-bold">{user_info.name}</span>
                        <div className="flex flex-row items-start justify-start gap-1">
                            <span className="text-xs">{t('Uploaded')}: {moment(video.updated_at).locale(language).fromNow()}</span>
                            <span className="text-xs">{t('Remaining')}: 22m</span>
                        </div>
                    </div>
                </div>
                {watchlistUser.length === 0
                    ? <button className="bg-[#FF1477] p-1 rounded-sm m-0 flex flex-row items-center justify-center gap-1" onClick={onAttentionUser}>
                        <i className="pi pi-heart-fill text-sm"></i>
                        <span className="text-xs">{t('focus_on')}</span>
                    </button>
                    :
                    <button className="bg-[#373C45] p-1 rounded-sm m-0 flex flex-row items-center justify-center gap-1" onClick={cancelAttention}>
                        <span className="text-xs">{t('unsubscribe')}</span>
                    </button>
                }
            </div>
            <div className="flex flex-col items-start justify-start mx-2 mt-2">
                <span className="text-[14px]">{video.video_name}</span>
                <div className="flex flex-row items-start justify-start gap-1">
                    {videoTags.length ? videoTags.map((tag, i) => <span key={i} className="text-[12px] text-gray-400">#{tag.type_name}</span>) : <></>}
                </div>
            </div>
            <div className="flex flex-col items-start justify-center mx-2 mt-2">
                <div className="flex flex-row items-start justify-start gap-1">
                    <span className="text-[10px] text-gray-400 font-semibold">
                        <FontAwesomeIcon icon={faFireAlt} className="me-1" />
                        {video.hot_number} /
                    </span>
                    <span className="text-[10px] text-gray-400 font-semibold">
                        <FontAwesomeIcon icon={faEye} className="me-1" />
                        {video.look_number} /
                    </span>
                    <span className="text-[10px] text-gray-400 font-semibold">
                        <FontAwesomeIcon icon={faClock} className="me-1" />
                        {video.play_number}
                    </span>
                    {video.is_vip
                        ? <>
                            <span className="text-[10px] text-gray-400 font-semibold">/</span>
                            <img src={assets_path("imgs/vip-icon.svg")} alt="vip video" />
                        </>
                        :
                        <></>
                    }

                </div>
            </div>
            <div className="flex flex-col md:flex-row items-start justify-between mt-2 gap-2 mx-2">
                <div className="px-2 py-3 flex flex-row items-center justify-center cursor-pointer w-full bg-gray-800 gap-2 rounded-sm hover:bg-gray-900"
                    onClick={onLikeVideo}
                >
                    {video.is_like
                        ? <img src={assets_path("imgs/heart-fill.svg")} alt="share video icon" />
                        : <img src={assets_path("imgs/heart.svg")} alt="share video icon" />
                    }
                    <span className="text-[12px]">{video.like}</span>
                </div>
                <div className="px-2 py-3 flex flex-row items-center justify-center cursor-pointer w-full bg-gray-800 gap-2 rounded-sm hover:bg-gray-900">
                    <img src={assets_path("imgs/share-icon.svg")} alt="share video icon" />
                    <span className="text-[12px]">{t('Get VIP')}</span>
                </div>
                <div className="px-2 py-3 flex flex-row items-center justify-center cursor-pointer w-full bg-gray-800 gap-2 rounded-sm hover:bg-gray-900"
                    onClick={onHarborVideo}
                >
                    {video.is_harbor
                        ? <img src={assets_path("imgs/favorite-fill.svg")} alt="share video icon" />
                        :
                        <img src={assets_path("imgs/favorite-icon.svg")} alt="share video icon" />
                    }
                    <span className="text-[12px]">{video.harbor}</span>
                </div>
            </div>
            {video.ads_img && <div className="flex flex-row items-center justify-center mt-2">
                <img src={mediaBaseURL + video.ads_img} alt="banner ad" className="w-11/12" />
            </div>}
            <div className="flex flex-col items-start justify-between mx-2 mt-2">
                <span className="text-sm mt-4 mb-2 mx-2">{t('More videos')}</span>
                {loading
                    ? <div className="grid grid-cols-1 gap-2 w-full">
                        <span className="text-sm text-gray-400 font-light mx-auto text-center">Loading...</span>
                    </div>
                    : relatedVideos.length === 0 && relatedLoaded &&

                    <div className="grid grid-cols-1 gap-2 w-full">
                        <span className="text-sm text-gray-400 font-light mx-auto text-center">No related videos</span>
                    </div>
                }
                <div className="grid grid-cols-1 gap-2 mb-8 w-full">
                    <InfiniteScroll
                        dataLength={items.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={<LoadingTemplate />}
                        className=" grid grid-cols-2 gap-2 w-full"
                    >
                        {items.map((video, key) => (
                            <div key={key} className="cursor-pointer">
                                <div className="cursor-pointer infinite-scroll gap-2">
                                    <VideoPreviewComponent video={video} />
                                </div>
                            </div>
                        ))
                        }
                    </InfiniteScroll>
                </div>
                {/* <div className="grid grid-cols-2 gap-2 p-2 mb-24 w-full">
                    {relatedVideos.length > 0 && relatedLoaded ?
                        relatedVideos.map((video, key) =>
                            <VideoPreviewComponent video={video} key={key} />
                        )
                        : <></>
                    }
                </div> */}
            </div>
        </div>
    );
}

export default ChannelPage;