import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { assets_path } from "../utils/Utils";
import { Link } from "react-router-dom";
import { ROUTES } from "../constants/Routes";
import { useTranslation } from "react-i18next";

function InviteToShare() {

    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center justify-start w-full text-dark h-[100vh] main-bg text-gray-900 min-h-screen">
            <div className="flex flex-row items-center justify-between py-2 w-full mb-1 px-2 shadow-md">
                <i className="pi pi-arrow-left text-gray-400 text-sm p-2 cursor-pointer" onClick={() => { navigate(-1) }} />
                <span className="text-sm font-semibold text-white">{t("Invite To Share")}</span>
                <div className="min-w-[30px]"></div>
            </div>
            <div className="flex flex-col items-center justify-between share-link-container p-2 rounded-md relative w-[90%]">
                <div className="flex flex-row gap-1 items-center">
                    <img src={assets_path('imgs/title-icon.svg')} />
                    <span className="text-xs text-dark text-red-800 font-semibold">{t("Share to friends, 1 successful shared, get 3 days VIP")}</span>
                </div>
                <div className="flex flex-row items-center justify-between gap-2 my-2 mb-2 px-4 w-full">
                    <Link to={ROUTES.SHARE_QR} className="flex flex-col items-center justify-center">
                        <FontAwesomeIcon icon={faLink} className="bg-red-500 text-sm p-2 text-white rounded-full" />
                        <span className="text-sm text-gray-700">{t("share_links.link_1")}</span>
                    </Link>
                    <Link to={ROUTES.SHARE_QR} className="flex flex-col items-center justify-center">
                        <FontAwesomeIcon icon={faLink} className="bg-blue-500 text-sm p-2 text-white rounded-full" />
                        <span className="text-sm text-gray-700">{t("share_links.link_2")}</span>
                    </Link>
                    <Link to={ROUTES.SHARE_QR} className="flex flex-col items-center justify-center">
                        <FontAwesomeIcon icon={faLink} className="bg-[#9A6636] text-sm p-2 text-white rounded-full" />
                        <span className="text-sm text-gray-700">{t("share_links.link_3")}</span>
                    </Link>
                </div>
                <div className="rounded-full px-1 absolute -bottom-3 union-bg">
                    <span className="text-xs text-white">{t("Invited")}: 0 {t("people")}</span>
                </div>
            </div>
            <div className="flex flex-row items-center justify-between rounded-md mt-6 w-full">
                <div className="flex flex-row items-center justify-center me-1 py-1 mt-6 w-full">
                    <span className="text-gray-400 text-sm">{t("username")}</span>
                </div>
                <div className="flex flex-row items-center justify-center py-1 mt-6 w-full">
                    <span className="text-gray-400 text-sm">{t("Invitation Time")}</span>
                </div>
            </div>
            <div className="flex flex-col items-center gap-2 justify-center w-full bg-[#262A32] p-4 h-screen">
                <img src={assets_path("imgs/no-items.svg")} />
                <span className="text-gray-400 text-sm">{t("no_content")}</span>
            </div>
        </div>
    );
}

export default InviteToShare;