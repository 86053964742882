import "./swiper.css";

import { Slider } from "primereact/slider";
import { useEffect, useState } from "react";

function SwipeToConfirm() {

    const [range, setRange] = useState(0);
    const [verify, setVerify] = useState("Swipe to verify");
    const [verifyDisabled, setVerifyDisabled] = useState(false);
    
    useEffect(() => {
        if (range == 80) {
            setVerify("Verified");
            setVerifyDisabled(true);
        } else {
            setVerify("Swipe to verify");
            setVerifyDisabled(false);
        }
    }, [range]);

    const handleMouseUp = (e) => {
        // Check the range value here when the mouse button is released
        console.log(range, e.target.value);
        if (range >= 20) {
            setVerify("Verified");
        } else {
            setVerify("Swipe to verify");
        }
    };

    return (
        <section id="alertbox">
            <span>Hold down the slider and drag it to the far right</span>
            <div className="confirmation-slider">
                <div id="status" className="relative">
                    <Slider value={range} onChange={(e) => setRange(e.value)} className="w-full" onMouseUp={handleMouseUp} disabled={verifyDisabled} />
                    <span className="text-success transition-all transform duration-700 absolute top-[7px] left[130px]">{verify}</span>
                </div>
            </div>
        </section>
    );
}

export default SwipeToConfirm;