import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoPreviewComponent from "../components/VideoPreviewComponent";
import { assets_path, getContestBanner, getContestVideo, getVideoList } from "../utils/Utils";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { mediaBaseURL } from "../interceptors/axiosInterceptor";

function PreLoader() {
    return (
        <div role="status" className="animate-pulse md:flex md:items-center w-full px-2">
            <div className="flex items-center justify-center w-full h-36 bg-gray-300 rounded sm:w-90 dark:bg-gray-700">
                <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                    <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
            </div>
        </div>
    )
}

function Hero({ banner, loading }) {
    if (loading) {
        return <PreLoader />
    }
    else return (
        <div className='flex flex-col items-center justify-center max-h-[230px]'>
            <img className="w-5/2 mb-4 object-contain object-center " alt={banner?.remark} src={mediaBaseURL + banner?.img_url} />
            {/* <img className="w-5/2 mb-4 object-cover object-center " alt={banner.remark} src={assets_path('hero.png')} /> */}
            <Announcement />
        </div>
    );
}

function Announcement() {
    return (
        <div className="sliding-text-container flex flex-row gap-12 w-full">
            <div className="sliding-text text-xs">Your sliding text here 1</div>
            <div className="sliding-text text-xs">Your sliding text here 2</div>
            <div className="sliding-text text-xs">Your sliding text here 3</div>
        </div>

    );
}

function ContestOne({ competitionList, loading }) {
    if (loading) {
        return (
            <div className="grid grid-cols-2 px-2 my-2">
                <PreLoader />
                <PreLoader />
            </div>
        )
    }
    else return (
        <>
            {competitionList.length
                ?
                competitionList.map((comp) => (

                    <div className="flex flex-col my-2 gap-1" key={comp._id}>
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-row items-center justify-start gap-1">
                                <span className="text-sm">{comp.type_name}</span>
                            </div>
                        </div>
                        <div className="flex flex-row gap-2 p-2 w-full flex-nowrap overflow-x-auto">
                            {comp.video.length ? (
                                comp.video.map((video) => (
                                    <div key={video._id} className="cursor-pointer flex-none">
                                        <VideoPreviewComponent video={video} />
                                    </div>
                                ))
                            ) : (
                                <div className="flex flex-row w-full items-center justify-center">
                                    <span className="text-sm text-gray-700 w-full text-center">No content yet</span>
                                </div>
                            )}
                        </div>

                    </div>
                ))
                : <></>
            }
        </>
    );
}

function ContestTwo({ videoList }) {
    return (
        <div className="flex flex-col my-2 gap-1">
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center justify-start gap-1">
                    <span className="text-sm">Video contest title</span>
                </div>
                {/* <div className="flex flex-row items-center justify-start gap-1">
                    <span className="text-xs">View all contests</span>
                    <FontAwesomeIcon icon={faArrowAltCircleRight} className="text-sm text-gray-400" />
                </div> */}
            </div>
            <div className="grid grid-cols-2 gap-2 p-2 w-full">
                {videoList.length ? videoList.map((video, key) => (
                    <div key={key} className="cursor-pointer">
                        <VideoPreviewComponent video={video} />
                    </div>
                ))
                    :
                    <>
                        <div className="flex flex-row w-full items-center justify-center">
                            <span className="text-sm text-gray-700 w-full text-center">No content yet</span>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

function MoreVideos({ videoList }) {
    return (
        <div className="flex flex-col items-start justify-between">
            <span className="text-sm mt-4 mb-2">More videos</span>
            <div className="grid grid-cols-2 gap-2">
                {videoList.length ? videoList.map((video, key) => (
                    <div key={key} className="cursor-pointer">
                        <VideoPreviewComponent video={video} />
                    </div>
                ))
                    :
                    <>
                        <div className="flex flex-row w-full items-center justify-center">
                            <span className="text-sm text-gray-700 w-full text-center">No content yet</span>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

function AdvertiseBanner({ banner, url }) {
    return (
        <div className="flex flex-row items-center justify-center p-1 my-4 max-h-[100px] w-full">
            <a href={url} target="_blank" className="w-full">
                <img src={mediaBaseURL + banner.img_url} alt={banner.remark} className="h-auto max-h-[150px] w-full" />
            </a>
        </div>
    )
}

function DailyContest() {

    const [loading, setLoading] = useState(true);
    const [advertise, setAdvertise] = useState(false);
    const [competitionList, setCompetitionList] = useState([]);
    const [videoList, setVideoList] = useState([]);
    const [topBanner, setTopBanner] = useState([]);
    const [bannerLink, setBannerLink] = useState("");

    async function getDailyContestBanners() {
        const res = await getContestBanner('mobile');
        if (res.code === 200) {
            return res.data.list;

        }
        // console.log(res.data.list);
    }

    async function getDailyContestVideos() {
        const res = await getContestVideo('mobile');
        if (res.code === 200) {
            // setAdvertise(res.data.advertise)
            // setCompetitionList(res.data.list);
            console.log(res.data);
            return res.data;
            // setTopBanner(res.data.list);
            // setLoading(false);
        }
    }

    async function fetchVideos() {
        const videosList = await getVideoList();
        // console.log(videosList);
        return videosList.list;

    }

    async function fetchData() {
        try {
            const [videos, banner, contestVideos] = await Promise.all([
                fetchVideos(),
                getDailyContestBanners(),
                getDailyContestVideos()
            ]);
            setVideoList(videos);
            setTopBanner(banner);
            setCompetitionList(contestVideos.list);
            setAdvertise(contestVideos.advertise);
            setBannerLink(contestVideos.advertise.target_url);
            setLoading(false);
        }
        catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        fetchData();
        // fetchVideos();
        // getDailyContestBanners();
        // getDailyContestVideos();
    }, [])

    if (loading) {
        return (
            <div className="card flex flex-col items-center justify-center main-bg p-2 pb-[4rem] min-h-screen">
                <div className="card flex flex-col items-center justify-center">
                    <img src={assets_path('imgs/no-content-icon.svg')} alt="no content found" />
                    <span className="text-sm text-gray-500 mt-2">No content yet</span>
                </div>
            </div>
        )
    }
    else return (
        <div className="card flex flex-col items-center justify-center main-bg p-2 pb-[4rem] min-h-screen">


            <Hero banner={topBanner[0]} />

            {/* <PastCompetition competitionList={videoList} /> */}
            <ContestOne competitionList={competitionList} />
            <AdvertiseBanner banner={advertise} url={bannerLink} />
            {/* <ContestTwo videoList={videoList} />
            <MoreVideos videoList={videoList} /> */}
        </div>
    );
}

export default DailyContest;