import { useContext, useEffect, useState } from "react";
import { getVideoList } from "../utils/Utils";
import { VideoContext } from "../screens/store/ContextApi";

function useInfiniteScroll(initialPage = 1, initialLimit = 10) {
    const { activeInnerTab, resetList, collection, setCollection, setPage, page } = useContext(VideoContext);
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    // const [page, setPage] = useState(initialPage);
    const [limit] = useState(initialLimit);

    const fetchItems = async () => {
        // setLoading(true);
        const videosList = await getVideoList(activeInnerTab, page, limit);
        // if (resetList) {
        //     console.log(':::::::::::::::::LIST RESET:::::::::::::::::');
        //     setItems(videosList.list);
        //     setCollection([...videosList.list]);
        // }
        // else {
        //     console.log(':::::::::::::::LIST NOT RESET:::::::::::::::');
        //     setItems([...items, ...videosList.list]);
        //     setCollection([...collection, ...videosList.list]);
        // }
        setItems([...items, ...videosList.list]);
        setCollection([...collection, ...videosList.list]);
        setLoading(false);
    };

    const fetchMoreData = async () => {
        setPage((prevPage) => prevPage + 1);
    };

    useEffect(() => {
        console.log('getting more data', page);
        fetchItems();
    }, [page]);

    return {
        loading,
        items,
        collection,
        fetchMoreData,
        setItems,
    };
}

export default useInfiniteScroll;
