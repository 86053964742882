import { PrimeReactProvider } from 'primereact/api';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './screens/Home';
import About from './screens/About';
import BottomNav from './components/BottomNav';
import Profile from './screens/Profile';
import { ROUTES } from './constants/Routes';
import Register from './screens/auth/Register';
import Login from './screens/auth/Login';
import ForgetPassword from './screens/auth/ForgetPassword';
import VideoPage from './screens/videoPage/VideoPage';
import SearchPage from './screens/SearchPage';
import VIPUser from './screens/VIPUser';
import ExchangeRecord from './screens/ExchangeRecord';
import FollowPage from './screens/FollowPage';
import ChannelPage from './screens/ChannelPage';
import Checkin from './screens/Checkin';
import DailyContest from './screens/DailyContest';
import InviteToShare from './screens/InviteToShare';
import ShareQR from './screens/ShareQR';
import PrivateRoute from './PrivateRoute';
import BindEmail from './screens/auth/BindEmail';
import SuccessPage from './screens/SuccessPage';
import SupportPage from './screens/SupportPage';
import FollowUp from './screens/FollowUp';
import PersonalDetails from './screens/PersonalDetails';
import ChangePassword from './screens/ChangePassword';
import WheelOfFortune from './screens/WheelOfFortune';
import LanguagePage from './screens/LanguagePage';
import CategoryPage from './screens/CategoryPage';
import 'moment/locale/zh-cn';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng');
    if (lang.includes('-')) {
      localStorage.setItem('i18nextLng', 'en');
    }
  }, [])
  return (
    <Router>
      <BottomNav />
      {/* <Navbar /> */}

      <PrimeReactProvider>
        <Routes>
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route
            path="/"
            element={<PrivateRoute element={<Home />} />}
          />
          <Route path={ROUTES.ABOUT} element={<About />} />
          {/* <Route element={<PrivateRoute element={<VideoPage />} />} /> */}
          <Route path={ROUTES.PROFILE} element={<Profile />} />
          <Route path={ROUTES.REGISTER} element={<Register />} />
          <Route path={ROUTES.LOGIN} element={<Login />} />
          <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />
          <Route path={ROUTES.VIDEO} element={<VideoPage />} />
          <Route path={ROUTES.SEARCH} element={<SearchPage />} />
          <Route path={ROUTES.VIP_USER} element={<VIPUser />} />
          <Route path={ROUTES.EXCHANGE_RECORD} element={<ExchangeRecord />} />
          <Route path={ROUTES.FOLLOW_PAGE} element={<FollowPage />} />
          <Route path={ROUTES.CHANNEL_PAGE} element={<ChannelPage />} />
          <Route path={ROUTES.CHECK_IN} element={<Checkin />} />
          <Route path={ROUTES.DAILY_CONTEST} element={<DailyContest />} />
          <Route path={ROUTES.INVITE_TO_SHARE} element={<InviteToShare />} />
          <Route path={ROUTES.SHARE_QR} element={<ShareQR />} />
          <Route path={ROUTES.BIND_EMAIL} element={<BindEmail />} />
          <Route path={ROUTES.SUCCESS_PAGE} element={<SuccessPage />} />
          <Route path={ROUTES.SUPPORT} element={<SupportPage />} />
          <Route path={ROUTES.FOLLOW_UP} element={<FollowUp />} />
          <Route path={ROUTES.PERSONAL_DETAILS} element={<PersonalDetails />} />
          <Route path={ROUTES.ACCOUNT_PASSWORD} element={<ForgetPassword />} />
          <Route path={ROUTES.WHEEL_OF_FORTUNE} element={<WheelOfFortune />} />
          <Route path={ROUTES.LANGUAGE_SELECTION} element={<LanguagePage />} />
          <Route path={ROUTES.CATEGORY_PAGE} element={<CategoryPage />} />
        </Routes>
      </PrimeReactProvider>
    </Router >
  );
}

export default App;
