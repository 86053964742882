import { Link, useNavigate } from "react-router-dom";
import lang from "../locale/LocalizedString";
import { useEffect, useState } from "react";
import { assets_path } from "../utils/Utils";
import { ROUTES } from "../constants/Routes";

function TabView() {

    const [activeTab, setActiveTab] = useState(1);
    const [search, setSearch] = useState("");

    useEffect(() => {

    }, [activeTab, search]);

    const onChangeTab = value => {
        setActiveTab(value);
    }

    return (
        <div className="flex flex-row gap-2 px-2 items-center justify-start font-semibold w-full mb-2">
            <div className="cursor-pointer" onClick={() => onChangeTab(1)}>
                <span className={`text-xs ${activeTab === 1 && 'bg-pink-600'} p-1 rounded-sm m-0`}>
                    {lang.latest_attention}
                </span>
            </div>
            <div className="cursor-pointer" onClick={() => onChangeTab(2)}>
                <span className={`text-xs ${activeTab === 2 && 'bg-pink-600'} p-1 rounded-sm m-0`}>
                    {lang.earliest_to_follow}
                </span>
            </div>
            <div className="cursor-pointer" onClick={() => onChangeTab(3)}>
                <span className={`text-xs ${activeTab === 3 && 'bg-pink-600'} p-1 rounded-sm m-0`}>
                    {lang.most_fans}
                </span>
            </div>
        </div>
    );
}

function FollowUserCard(
    {
        user = null
    }
) {
    return (
        <div className="flex flex-row items-center justify-between gap-2 w-full px-2 my-2">
            <Link to={{pathname: ROUTES.PERSONAL_DETAILS, state: { user_id: 123 }}} className="flex flex-row items-start justify-start gap-2">
                <img src={assets_path('avatar.jpg')} alt="user avatar" className="w-[65px] rounded-full" />
                <div className="flex flex-col items-start justify-start">
                    <span className="text-xs">{user.name}</span>
                    <span className="text-xs text-gray-500">{lang.fans}: {user.fans}</span>
                    <span className="text-xs text-gray-500">{lang.videos}: {user.videos}</span>
                </div>
            </Link>
            <span className={`text-xs bg-gray-700 p-1 rounded-sm m-0 cursor-pointer`}>{lang.unsubscribe}</span>
        </div>
    )
}

function FollowUp() {

    const navigate = useNavigate();

    const fansData = [
        { name: 'Ms. Marvel', fans: '8.7w', videos: 75 },
        { name: 'Dr. Mystery', fans: '5.2w', videos: 45 },
        { name: 'Captain Enigma', fans: '14.8w', videos: 90 },
        { name: 'Madame Curious', fans: '6.3w', videos: 60 },
        { name: 'Professor Puzzler', fans: '10.1w', videos: 110 }
    ]

    return (
        <div className="flex flex-col items-center main-bg pb-8 w-full mb-8 min-h-screen">
            <div className="flex flex-row items-center justify-between py-2 w-full mb-2 px-2 shadow-md">
                <i className="pi pi-arrow-left text-white p-2 cursor-pointer text-sm" onClick={() => { navigate(-1) }} />
                <span className="text-sm">{lang.followed_up_owners}</span>
                <div className="p-2"></div>
            </div>

            <TabView />
            {fansData.length
                ? fansData.map((user, i) => <FollowUserCard key={i} user={{...user, id: i}} />) : <></>
            }
        </div>
    );
}

export default FollowUp;