import { useNavigate } from "react-router";
import { assets_path } from "../utils/Utils";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";
// import { ShareSocial } from 'react-share-social';
import { RWebShare } from "react-web-share";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function Header() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-row items-center justify-between px-2 absolute w-full top-2 z-10">
            <div className="p-4 cursor-pointer" onClick={() => navigate(-1)}></div>
            {/* <div className="p-4 bg-gray-400"></div> */}
        </div>
    )
}

function ShareQR() {
    const { language } = useLanguage();
    const [shareURL, setShareURL] = useState("");

    const userData = useSelector((state) => state.user?.userData);

    useEffect(() => {
        if (userData) {
            const { invitation_code } = userData;
            const url = `https://video-streaming-app-407214.web.app/register?invitation_code=${invitation_code}`;
            setShareURL(url);
        }
    }, []);

    return (
        <div className="h-screen w-full overflow-scroll relative pb-8">
            <Header />
            <div className="flex flex-col items-center relative">
                {language === 'en'
                    ? <img src={assets_path('imgs/share-bg-en.svg')} className="w-full" />
                    : <img src={assets_path('imgs/share-bg.svg')} className="w-full" />
                }
                <div className="qr-img-container absolute">
                    <img src={assets_path('imgs/qr-img.svg')} />
                </div>
                <RWebShare
                    data={{
                        text: "Visit video streaming platform for fun",
                        url: shareURL,
                        title: "Video Streaming Platform",
                        sites: ['telegram', 'facebook', 'twitter']
                    }}
                    onClick={() => console.log("shared successfully!")}
                >
                    {language === 'en' ?
                        <img src={assets_path('imgs/share-btn-en.svg')} alt="share button" className="share-qr-btn absolute w-[90%] cursor-pointer" />
                        :
                        <img src={assets_path('imgs/share-btn.svg')} alt="share button" className="share-qr-btn absolute w-[90%] cursor-pointer" />
                    }
                </RWebShare>
            </div>
        </div>
    );
}

export default ShareQR;