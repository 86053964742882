import TopBar from '../components/TopBar';
import { assets_path, fetchVideoTypes, getHeroImage, getVideoList, isTokenExpired } from '../utils/Utils';
import VideoPreviewComponent from '../components/VideoPreviewComponent';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../constants/Routes';
import { useEffect, useState } from 'react';
import { Carousel } from 'primereact/carousel';
import { useTranslation } from 'react-i18next';
import { mediaBaseURL } from '../interceptors/axiosInterceptor';
import useInfiniteScroll from '../hooks/useInfiniteScroll';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { onToggleWheel } from './store/actions/wheelAction';

function Hero(
    {
        banners,
        baseURL,
        loading
    }
) {

    function productTemplate(banner) {
        const url = baseURL + banner.img_url;
        return (
            <div className="m-2 text-center">
                <div className="mb-3">
                    <img src={url} alt={"product.name"} className="w-full shadow-2" />
                </div>
            </div>
        );
    };

    return (
        <div className='flex flex-col items-center justify-center'>
            {
                loading
                    ?
                    <div role="status" className="animate-pulse md:flex md:items-center w-full px-2">
                        <div className="flex items-center justify-center w-full h-36 bg-gray-300 rounded sm:w-90 dark:bg-gray-700">
                            <svg className="w-10 h-10 text-gray-200 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                            </svg>
                        </div>
                    </div>
                    : <Carousel value={banners} numVisible={1} numScroll={1} orientation="horizontal" verticalViewPortHeight="360px"
                        autoplayInterval={5000}
                        showIndicators={false}
                        showNavigators={false}
                        itemTemplate={productTemplate} />
            }
            {/* <Announcement /> */}
        </div>
    );
}

function Announcement() {
    return (
        <div className="sliding-text-container flex flex-row gap-12 w-full">
            <div className="sliding-text text-xs">Your sliding text here 1</div>
            <div className="sliding-text text-xs">Your sliding text here 2</div>
            <div className="sliding-text text-xs">Your sliding text here 3</div>
        </div>

    );
}

function EventContest({ t }) {
    return (
        <div className='flex flex-row justify-between items-center px-1'>
            <Link to={ROUTES.VIP_USER} className='flex flex-col justify-start items-center cursor-pointer' style={{ width: '50%' }} >
                <img src={assets_path('imgs/exc-benefits.svg')} className='img-responsive w-full p-1' />
                <span className='text-xs mt-2'>{t('Exclusive Benefits')}</span>
            </Link>
            <Link to={ROUTES.CHECK_IN} className='flex flex-col justify-start items-center cursor-pointer' style={{ width: '50%' }} >
                <img src={assets_path('imgs/checkin-center.svg')} className='img-responsive w-full p-1' />
                <span className='text-xs mt-2'>{t("Check-in center")}</span>
            </Link>
            <Link to={ROUTES.DAILY_CONTEST} className='flex flex-col justify-start items-center cursor-pointer' style={{ width: '50%' }} >
                <img src={assets_path('imgs/daily-contest.svg')} className='img-responsive w-full p-1' />
                <span className='text-xs mt-2'>{t("Daily contest")}</span>
            </Link>
        </div>
    );
}

function Home() {


    const { loading: dataLoading, items, fetchMoreData } = useInfiniteScroll();
    // const { isTokenRefreshing } = useTokenRefresher();

    const { t } = useTranslation();
    // const { language } = useLanguage();
    const navigate = useNavigate();

    // const [ready, setReady] = useState(false);
    const [loading, setLoading] = useState(true);
    // const [videoList, setVideoList] = useState([]);
    const [videoTypes, setVideoTypes] = useState([]);
    const [banners, setBanners] = useState([]);
    const { wheelVisible } = useSelector((state) => state.wheel);
    const dispatch = useDispatch();
    // const [baseURL, setBaseURL] = useState("");

    async function mainBanners() {
        const data = await getHeroImage('mobile');

        const { address, list } = data;
        return data;
        // setBanners(list);
        // setBaseURL(`https://${address}/`);
        // setLoading(false);
    }

    async function fetchVideos() {
        const videosList = await getVideoList();

        
        // if (videosList.msg === 'Unauthorized') {
        //     navigate(ROUTES.LOGIN);
        //     return;
        // }

        return videosList.list;
    }

    async function getVideoTypes() {
        const types = await fetchVideoTypes('en');
        return types.list;
        // setVideoList(videosList.list);
    }

    async function fetchData() {
        const [banner, types] = await Promise.all([
            mainBanners(),
            // fetchVideos(),
            getVideoTypes(),
        ]);

        const { address, list } = banner;
        setBanners(list);
        // setVideoList(videos);
        setVideoTypes(types);
        setLoading(false);
    }

    useEffect(() => {
        // setReady(true);
        fetchData();
        // if (!isTokenRefreshing && ready) {
        // }
    }, [])

    function onNavigateToWheel() {
        navigate(ROUTES.WHEEL_OF_FORTUNE);
    }


    function LoadingTemplate() {
        return (
            <div className="w-full my-2">
                <span className="text-sm text-gray-500">{dataLoading ? 'Loading...' : ''}</span>
            </div>
        )
    }

    if (loading) {
        return (
            <div className="card flex flex-col align-items-end justify-content-end min-h-screen main-bg pb-12">
                <TopBar t={t} videoTypes={[]} />

                <Hero banners={[]} baseURL={mediaBaseURL} loading={loading} />
            </div>
        )
    }
    else return (
        <div className="card flex flex-col align-items-end justify-content-end min-h-screen main-bg pb-12 relative">
            <TopBar t={t} videoTypes={videoTypes} />

            <Hero banners={banners} baseURL={mediaBaseURL} loading={loading} />

            <EventContest t={t} />

            <div className='flex flex-row items-center justify-between'>
                <span className='mt-2 px-2 text-sm'>{t('free_videos')}</span>
                <Link to={ROUTES.VIDEO} className='flex flex-row items-center justify-between mt-2 px-2 gap-1 cursor-pointer'>
                    <span className='text-sm text-pink-600'>{t('more')}</span>
                    <i className='pi pi-chevron-right text-pink-600 text-xs'></i>
                </Link>
            </div>
            <div className="grid grid-cols-1 gap-2 p-2 w-full">
                <InfiniteScroll
                    dataLength={items.length}
                    next={fetchMoreData}
                    hasMore={true}
                    loader={<LoadingTemplate />}
                    className=" grid grid-cols-2 gap-2 p-2 w-full"
                >
                    {items.map((video, key) => (
                        <div key={key} className="cursor-pointer">
                            <div className="cursor-pointer infinite-scroll gap-2">
                                <VideoPreviewComponent video={video} />
                            </div>
                        </div>
                    ))
                    }
                </InfiniteScroll>
            </div>

            <div className='relative'>
                <Dialog visible={wheelVisible} onHide={() => { }} id="wheel-modal"
                    className='absolute bottom-12 bg-transparent border-none shadow-none' resizable={false} draggable={false}>
                    <div className='flex flex-col items-center justify-center gap-2'>
                        <img src={assets_path('imgs/wheel-svg.svg')} alt="wheel of fortune" className='w-300 h-300' onClick={onNavigateToWheel} />
                        <i className='pi pi-times-circle cursor-pointer text-white' onClick={() => dispatch(onToggleWheel(false))}></i>
                    </div>
                </Dialog>
            </div>
        </div>
    )

}

export default Home;