import { useNavigate } from "react-router";
import { assets_path, cancelAttentionUser, getMyWatchlist } from "../utils/Utils";
import { useEffect, useState } from "react";
import lang from "../locale/LocalizedString";
import { mediaBaseURL } from "../interceptors/axiosInterceptor";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { removeFromWatchlist } from "./store/actions/watchlistAction";
import { toast } from "react-toastify";
import { ProgressSpinner } from "primereact/progressspinner";
import { ROUTES } from "../constants/Routes";
import { setInfluencerDetail } from "./store/actions/userActions";

function FollowerCard(
    {
        user,
        cancelAttention,
        openDetails,
        t
    }
) {
    const { user_info } = user;
    return (
        <div className="p-2 flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center justify-start gap-1 w-full" onClick={() => openDetails(user_info)}>
                {user_info.avatar
                    ? <img src={mediaBaseURL + user_info.avatar} alt={user_info.name} className="rounded-full w-2/12" />
                    :
                    <img src={assets_path("avatar.jpg")} alt={user_info.name} className="rounded-full w-2/12" />
                }
                <div className="flex flex-col items-start justify-between w-full">
                    <div className="flex flex-row items-center justify-between mb-1">
                        <span className="text-xs">{user_info.name}</span>
                        {/* <span className="text-xs">{tag}</span> */}
                    </div>
                    <span className="text-xs text-gray-500">{t('fans')}: {user_info.attention}</span>
                    <span className="text-xs text-gray-500">{t('videos')}: {user_info.video_number}</span>
                </div>
            </div>
            <span className="text-xs bg-[#3C4049] px-2 rounded-sm py-1 cursor-pointer w-[120px] text-center" onClick={() => cancelAttention(user_info.id)}>{t('unsubscribe')}</span>
        </div>
    );
}

function FollowPage() {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState(1);

    const { t } = useTranslation();
    const [watchlist, setWatchlist] = useState([]);
    const [loading, setLoading] = useState(true);

    async function getWatchlist() {
        const res = await getMyWatchlist(activeTab);
        setWatchlist(res.data);
        setLoading(false);
    }

    async function cancelAttention(user_id) {
        const res = await cancelAttentionUser(user_id);
        dispatch(removeFromWatchlist(user_id));
        toast(t("User removed from watchlist"));
        console.log(res);
    }

    useEffect(() => {
        getWatchlist();
    }, [activeTab]);

    function openDetails(user) {
        const { id } = user;
        console.log(id);
        dispatch(setInfluencerDetail(user));
        navigate(ROUTES.PERSONAL_DETAILS);
    }

    if (loading) {
        return (
            <div className="card flex flex-col items-center justify-center min-h-screen main-bg pb-12">
                <ProgressSpinner />
                <span className="text-sm text-gray-500 mt-2">Loading users...</span>
            </div>
        )
    }
    else return (
        <div className="card flex flex-col align-items-end justify-content-end bg-[#1c1c1c] pb-12 h-[100vh] min-h-[720px]">
            <div className="flex flex-row items-center justify-between py-1 w-full px-2 shadow-md">
                <i className="pi pi-arrow-left text-sm p-2 cursor-pointer" onClick={() => { navigate(-1) }} />
                <span className="text-sm">{t('followed_up_owners')}</span>
                <span className="text-sm p-2"></span>
            </div>

            <div className="flex flex-col items-start justify-start gap-2">
                <div className="flex flex-row items-start justify-start gap-2 p-2">
                    <span className={` text-xs py-1 px-1 ${activeTab === 1 ? 'bg-pink-600 text-white' : 'text-gray-500'} rounded-sm cursor-pointer`} onClick={() => setActiveTab(1)}>{t('latest_attention')}</span>
                    <span className={`text-xs p-1 px-1 ${activeTab === 2 ? 'bg-pink-600' : 'text-gray-500'} rounded-sm cursor-pointer`} onClick={() => setActiveTab(2)}>{t('earlist_attention')}</span>
                    <span className={`text-xs p-1 px-1 ${activeTab === 3 ? 'bg-pink-600 text-white' : 'text-gray-500'} rounded-sm cursor-pointer`} onClick={() => setActiveTab(3)}>{t('most_fans')}</span>
                </div>
            </div>

            <div className="flex flex-col items-center">
                {watchlist.length ? watchlist.map((watchlist, i) => (
                    <div key={i} className="w-full cursor-pointer">
                        <FollowerCard user={watchlist} cancelAttention={cancelAttention} openDetails={openDetails} t={t} />
                    </div>
                ))
                    :
                    watchlist.length === 0 && loading ?
                        <span className="text-sm text-gray-500 py-8">{t('loading')}...</span>
                        :
                        <>
                            <span className="text-sm text-gray-500 py-8">{t('no_user_found')}</span>
                        </>
                }
            </div>
        </div>
    );
}

export default FollowPage;