import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "../locale/en/en.json";
import ch from "../locale/ch/zh_CN.json";
import ar from "../locale/ar/ar.json";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        resources: {
            en: {
                translation: {
                    ...en
                },
            },
            "zh_CN": {
                translation: {
                    ...ch
                }
            },
            "zh_TW": {
                translation: {
                    ...ch
                }
            },
            ar: {
                translation: {
                    ...ar
                }
            }
            // Add more languages as needed
        },
        // lng: 'en', // Default language
        fallbackLng: 'en', // Fallback language
        interpolation: {
            escapeValue: true, // React already escapes values
        },
    });

export default i18n;
