import { SET_CURRENT_LANGUAGE } from "../actions/languageAction"


const initialState = {
    currentLanguage: 'en'
}

const languageReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CURRENT_LANGUAGE: return {
            ...state,
            currentLanguage: action.payload
        }
        default: return state;
    }
}

export default languageReducer;