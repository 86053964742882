import FollowedUsersList from "../../components/FollowedUsersList";
import TabContentContainer from "./TabContentContainer";
import { VIDEO_TYPE } from "./VideoPage";

function TabContent({ activeTab, watchlist, harborVideoList }) {

    return (
        activeTab === 1 ?
            <TabContentContainer type={VIDEO_TYPE.allVideos} />
            :
            <div className="flex flex-col main-bg min-h-screen">
                <div className="flex flex-row items-start justify-start">
                    <FollowedUsersList watchlist={watchlist} />
                </div>

                <TabContentContainer videoList={harborVideoList} type={VIDEO_TYPE.myCollection} />
            </div>
    );
}

export default TabContent;