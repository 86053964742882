import { useLocation, useNavigate } from "react-router";
import { assets_path } from "../utils/Utils";
import { useEffect } from "react";
import { ROUTES } from "../constants/Routes";
import { useTranslation } from "react-i18next";

function SuccessPage(
    {
        title = "Password reset successfully",
        btnText = "Return to Profile",
        resetPassword,
        path = "",
        onNavigate
    }
) {

    const { t } = useTranslation();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const message = location.state;
        console.log(message);
    }, [])

    // function onNavigate() {
    //     console.log(path);
    //     if (path !== "") {
    //         navigate(path);
    //     }
    //     else {
    //         const binding = location.state?.binding || null;
    //         if (binding) {
    //             navigate(ROUTES.PROFILE);
    //         } else if (resetPassword) {
    //             navigate(ROUTES.LOGIN);
    //         }
    //     }
    // }

    return (
        <div className="flex flex-col items-center justify-center min-h-screen main-bg px-2 gap-4">
            <img src={assets_path("imgs/profile/green-tick.svg")} alt="success screen tick" className="mb-4" />
            <span className="text-sm ">{t(location.state?.message) || t(title) || t("Registration was successful")}</span>
            <button className="bg-pink-600 text-white p-2 text-center w-full mt-4 rounded-sm text-sm" onClick={onNavigate}>
                {t(btnText)}
            </button>
        </div>
    );
}

export default SuccessPage;
