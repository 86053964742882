import { getMyWatchlist } from "../../../utils/Utils";
// import { FETCH_ALL_VIDEOS } from "./actions/videoAction";
import { FETCH_WATCHLIST, setWatchlist } from "../actions/watchlistAction";

const watchlistMiddleware = (store) => (next) => async (action) => {
    if (action.type === FETCH_WATCHLIST) {
        const watchlist = store.getState().watchlist;

        // Check if the watchlist is already in the state
        if (watchlist.length === 0) {
            // If not, make the API call
            try {
                const res = await getMyWatchlist();
                console.log("fetching watchlist...", res.data, store.getState());
                store.dispatch(setWatchlist(res.data));
            }
            catch(e) {
                console.log("Error fetching watchlist");
            }
        }
    }

    return next(action);
};

export default watchlistMiddleware;