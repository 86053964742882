export const SET_WHEEL_VISIBLE = 'SET_WHEEL_VISIBLE';
export const SET_REWARD = 'SET_REWARD';

export function onToggleWheel(value) {
    return {
        type: SET_WHEEL_VISIBLE,
        payload: value
    }
};

export function onSetReward(value) {
    return {
        type: SET_REWARD,
        payload: value
    }
}
