import { useEffect, useState } from "react";
import TopBar from "../components/TopBar";
import { fetchVideoTypes, getVideoList } from "../utils/Utils";
import useLanguage from "../hooks/useLanguage";
import { useTranslation } from "react-i18next";
import VideoPreviewComponent from "../components/VideoPreviewComponent";
import { ProgressSpinner } from "primereact/progressspinner";
import { useSelector } from "react-redux";

function CategoryPage() {

    const { videoType } = useSelector((state) => state.video);

    const language = useLanguage();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [videoTypes, setVideoTypes] = useState([]);
    const [videoList, setVideoList] = useState([]);

    async function getVideoTypes() {
        const types = await fetchVideoTypes(language.language);
        return types.list;
        // setVideoList(videosList.list);
    }

    async function fetchVideos(type_id) {
        const videosList = await getVideoList(type_id);
        return videosList.list;
    }

    async function fetchData() {
        const [videos, types] = await Promise.all([
            fetchVideos(videoType),
            getVideoTypes(),
        ]);

        setVideoList(videos);
        setVideoTypes(types);
        setLoading(false);
    }

    useEffect(() => {
        fetchData(videoType);
    }, [videoType])

    useEffect(() => {
        fetchVideos();
    }, [videoType])
    

    if (loading) {
        return (
            <div className="card flex flex-col items-center justify-center min-h-screen main-bg pb-12">
                <ProgressSpinner />
                <span className="text-sm text-gray-500 mt-2">Loading videos...</span>
            </div>
        )
    }
    else return (
        <div className="card flex flex-col align-items-end justify-content-end min-h-screen main-bg pb-12">
            <TopBar t={t} videoTypes={videoTypes} />

            <div className="grid grid-cols-2 gap-2 p-2">
                {videoList.length ? videoList.map((video, key) => (
                    <div key={key} className="cursor-pointer">
                        <VideoPreviewComponent video={video} />
                    </div>
                ))
                    :
                    <>
                        <div className="flex flex-row w-full items-center justify-center">
                            <span className="text-sm text-gray-700 w-full text-center">{t('no_content')}</span>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default CategoryPage;