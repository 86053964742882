import { combineReducers } from 'redux';
import userReducer from './reducers/userReducer';
import apiReducer from './reducers/apiReducer';
import verificationCodeReducer from './reducers/verificationCodeReducer';
import languageReducer from './reducers/languageReducer';
import videoReducer from './reducers/videoReducer';
import personalDetailsReducer from './reducers/personalDetailsReducer';
import watchlistReducer from './reducers/watchlistReducer';
import tokenReducer from './reducers/tokenReducer';
import wheelReducer from './reducers/wheelReducer';

const rootReducer = combineReducers({
  user: userReducer,
  api: apiReducer,
  v_code: verificationCodeReducer,
  language: languageReducer,
  video: videoReducer,
  personalDetails: personalDetailsReducer,
  watchlist: watchlistReducer,
  token: tokenReducer,
  wheel: wheelReducer
  // Other reducers...
});

export default rootReducer;
