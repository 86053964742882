import { assets_path, checkin, getCheckinList, getUserInfo, isMoreThan24HoursAgo, isTokenExpired, refreshAuth } from "../utils/Utils";
import lang from "../locale/LocalizedString";
import { useNavigate } from "react-router";
import { ROUTES, UN_AUTHORIZED } from "../constants/Routes";
import { useEffect, useState } from "react";
import { mediaBaseURL } from "../interceptors/axiosInterceptor";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useLanguage from "../hooks/useLanguage";
import { ProgressSpinner } from "primereact/progressspinner";
import { setUserData } from "./store/actions/userActions";
// import { fetchUserInfo } from "./store/actions/userActions";

function DiamondCard(
    {
        checkin,
        t
    }
) {
    return (
        <div className={`flex flex-col items-center justify-between py-2 px-1 ${checkin.isCurrentDay ? 'bg-[#373C53]' : 'bg-[#1A1E24]'} rounded-sm cursor-pointer min-w-[80px] max-w-[90px] relative`}>
            <span className={`text-sm text-white font-light`}>{t('Day')} {checkin.weight}</span>
            <img src={mediaBaseURL + checkin.bg_img_url} className="w-4" alt={`${checkin.name} icon`} />
            {checkin.isCheckIn ? <img src={assets_path("imgs/marked-sign.svg")} className="absolute right-0 bottom-0" /> : <></>}
            <span className="text-xs text-white text-center">{checkin.diamond_quantity} {t('diamonds')}</span>
        </div>
    );
}

function DiamondTask(
    {
        days = 3,
        bonus = 20,
        received = false,
        t,
        language
    }
) {
    return (
        <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center justify-start w-full">
                <img src={assets_path(`imgs/calender/calendar-${days}.svg`)} className="p-2" />
                <div className="flex flex-col items-start justify-start">
                    <span className="text-xs font-semibold text-white">{lang.formatString(t('days_extra_bonus'), { 1: days })}</span>
                    <span className="text-xs text-gray-400">{lang.formatString(t('consecutive_day_bonus'), { 1: days, 2: bonus })}</span>
                </div>
            </div>
            <div className={`flex flex-row gap-1 items-center justify-center px-3 py-1 ${received ? 'bg-[#1A1E24]' : 'bg-pink-600'} rounded-sm`}>
                {received
                    ?
                    <span className={`text-xs flex flex-none p-0 ${language === 'en' ? 'px-0' : 'px-2'} m-0 text-white`}>{t('received')}</span>
                    :
                    <>
                        <img src={assets_path("imgs/checkin-diamond.svg")} className="p-0 m-0" />
                        <span className="text-sm text-white">+{bonus}</span>
                    </>
                }
            </div>
        </div>
    );
}

function Checkin() {

    const { t } = useTranslation();
    const { language } = useLanguage();

    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [ready, setReady] = useState(false);
    const [checkinList, setCheckinList] = useState([]);
    const [today, setToday] = useState(null);
    const [checkinData, setCheckinData] = useState([]);


    // async function fetchCheckinList() {
    //     const res = await getCheckinList();
    //     const checkinData = res.data.list;

    //     // Calculate the user's current day based on the length of the checkinData
    //     const dayIndex = checkinData.length % 7;

    //     // Update isCurrentDay property
    //     checkinData.forEach((checkin, index) => {
    //         if (index === dayIndex) {
    //             checkin.isCurrentDay = true;
    //             setToday(checkin);
    //         } else {
    //             checkin.isCurrentDay = false;
    //         }
    //     });

    //     console.log(checkinData);
    //     setCheckinList(checkinData);
    // }


    // async function fetchCheckinList() {
    //     const res = await getCheckinList();
    //     const checkinData = res.data.list;
    //     const today = new Date().toISOString().split('T')[0];

    //     // Find the registration day
    //     const registrationDay = new Date(userRegistrationDate).toISOString().split('T')[0];

    //     // Calculate the user's current day since registration
    //     const currentDate = new Date(today);
    //     const registrationDate = new Date(registrationDay);
    //     const dayIndex = Math.floor((currentDate - registrationDate) / (24 * 60 * 60 * 1000)) % 7;

    //     // Update isCurrentDay property
    //     checkinData.forEach((checkin, index) => {
    //         const checkinDay = new Date(checkin.updated_at).toISOString().split('T')[0];

    //         if (checkin.isCheckIn && index === dayIndex) {
    //             checkin.isCurrentDay = true;
    //             setToday(checkin);
    //         } else {
    //             checkin.isCurrentDay = false;
    //         }
    //     });

    //     setCheckinList(checkinData);
    // }

    const checkinDataList = [
        {
            "_id": "655d1ec5b5826365d403f924",
            "insert_time": 1700601541,
            "current": "en",
            "name": "First Day",
            "diamond_quantity": "10",
            "status": 1,
            "weight": "1",
            "bg_img_url": "images/6d8eaaee688fcdeebe760a675981dd3f.png",
            "updated_at": "2023-11-25T21:19:01.967000Z",
            "created_at": "2023-11-21T21:19:01.967000Z",
            "isCheckIn": false
        },
        {
            "_id": "6560a4b987bdc3d52004bba8",
            "insert_time": 1700832600,
            "current": "en",
            "name": "Second Day",
            "diamond_quantity": "10",
            "status": 1,
            "weight": "2",
            "bg_img_url": "images/0e95d9ed3e74944cc133c71d6bafd649.png",
            "updated_at": "2023-11-26T13:30:00.949000Z",
            "created_at": "2023-11-24T13:27:21.199000Z",
            "isCheckIn": false
        },
        {
            "_id": "6560a5ca87bdc3d52004bba9",
            "insert_time": 1700832600,
            "current": "en",
            "name": "Third Day",
            "diamond_quantity": "10",
            "status": 1,
            "weight": "3",
            "bg_img_url": "images/abbc4b8a9b5bfd91b83f724b98cb19b7.png",
            "updated_at": "2023-11-26T13:31:54.305000Z",
            "created_at": "2023-11-24T13:31:54.305000Z",
            "isCheckIn": false
        },
        {
            "_id": "6561c43587bdc3d52004bbaa",
            "insert_time": 1700832600,
            "current": "en",
            "name": "Fourth day",
            "diamond_quantity": "10",
            "status": 1,
            "weight": "4",
            "bg_img_url": "images/2925004cb8a07b2188478e348a1a7aea.png",
            "updated_at": "2023-11-25T09:53:57.206000Z",
            "created_at": "2023-11-25T09:53:57.206000Z",
            "isCheckIn": false
        },
        {
            "_id": "6561c455c9f4e3c2b50f4cb8",
            "insert_time": 1700906069,
            "current": "en",
            "name": "Fifth day",
            "diamond_quantity": "10",
            "status": 1,
            "weight": "5",
            "bg_img_url": "images/3f1233f822d392c0d2b54c5164d18562.png",
            "updated_at": "2023-11-25T09:54:29.769000Z",
            "created_at": "2023-11-25T09:54:29.769000Z",
            "isCheckIn": false
        },
        {
            "_id": "6561c46f85ede756130c3b57",
            "insert_time": 1700906095,
            "current": "en",
            "name": "Sixth day",
            "diamond_quantity": "10",
            "status": 1,
            "weight": "6",
            "bg_img_url": "images/e38afb7516e28607098b45ad93777e3b.png",
            "updated_at": "2023-11-25T09:54:55.162000Z",
            "created_at": "2023-11-25T09:54:55.162000Z",
            "isCheckIn": false
        },
        {
            "_id": "6561c48f8c16c470c40f8393",
            "insert_time": 1700906127,
            "current": "en",
            "name": "Seventh day",
            "diamond_quantity": "10",
            "status": 1,
            "weight": "7",
            "bg_img_url": "images/4e20faf901d64a0b7ecd91e9d98d66fb.png",
            "updated_at": "2023-11-25T09:55:27.124000Z",
            "created_at": "2023-11-25T09:55:27.124000Z",
            "isCheckIn": false
        }
    ];

    const isToday = (date) => {
        return moment(date).isSame(moment(), 'day');
    };

    const processCheckinData = async () => {
        const res = await getCheckinList();
        if (res === '未授权' || res.toString().toLowerCase() === 'unauthorized') {
            navigate(ROUTES.LOGIN);
        }
        else {
            const checkinData = res.data.list;
            setCheckinData(() =>
                checkinData.map((user, key) => {
                    const firstCheckinDay = moment.unix(userData.created_at);
                    const daysSinceFirstCheckin = moment().diff(firstCheckinDay, 'days');
    
                    // Check if the last check-in is more than 1 week old
                    const isMoreThanOneWeekOld = moment().diff(user.updated_at, 'days') > 7;
    
                    // Calculate the current day based on the user's registration day
                    const currentDay = moment().diff(firstCheckinDay, 'days') % 7;

                    if (user.isCheckIn) {
                        console.log(user.insert_time);
                    }

                    const checkin = {
                        ...user,
                        currentDayHighlighted: isMoreThanOneWeekOld ? true : isToday(firstCheckinDay),
                        label: isMoreThanOneWeekOld ? `Day 1` : `Day ${currentDay + 1}`,
                        isCurrentDay: key === 0 ? true : false, // isMoreThanOneWeekOld ? isToday(firstCheckinDay) : false,
                    };
                    if (key === 0)
                        setToday(checkin);
                    return checkin;
                })
            );
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log(checkinData);
    }, [checkinData]);


    async function fetchCheckinList() {
        const res = await getCheckinList();

        const checkinData = res.data.list;
        const today = new Date().toISOString().split('T')[0];

        res.data.list.map((check_in, index) => {
            // if (check_in.updated_at.split('T')[0] === today) {
            if (moment(check_in.insert_time * 1000).format('YYYY-MM-DD') === today) {
                check_in.isCurrentDay = true;
            } else {
                // Check if the last check_in had isCheckIn true and if its time is more than 24 hours ago
                const lastCheckIn = index > 0 ? checkinData[index - 1] : null;
                if (lastCheckIn && lastCheckIn.isCheckIn && isMoreThan24HoursAgo(lastCheckIn.insert_time)) {
                    check_in.isCurrentDay = true;
                }
            }
        });

        setCheckinList(checkinData);
    }

    // async function fetchCheckinList() {
    //     const res = await getCheckinList();

    //     const checkinData = checkinDataList; //res.data.list;
    //     const today = new Date().toISOString().split('T')[0];

    //     checkinData.map((check_in) => {
    //         if (check_in.updated_at.split('T')[0] === today) {
    //             check_in.isCurrentDay = true;
    //         }
    //     });
    //     setCheckinList(checkinData);

    //     return;
    //     // Find the last check-in with isCheckIn as true
    //     let lastCheckin = null;
    //     for (let i = checkinData.length - 1; i >= 0; i--) {
    //         const checkin = checkinData[i];
    //         const checkinDay = checkin.updated_at.split('T')[0];
    //         console.log(checkinDay);

    //         if (checkin.isCheckIn) {
    //             lastCheckin = checkin;
    //             break;
    //         }
    //     }

    //     // Update isCurrentDay property
    //     checkinData.forEach((checkin) => {
    //         const checkinDay = checkin.updated_at.split('T')[0];

    //         if (checkin === lastCheckin && checkinDay === today) {
    //             checkin.isCurrentDay = true;
    //             setToday(checkin);
    //         } else {
    //             checkin.isCurrentDay = false;
    //         }
    //     });

    //     setCheckinList(checkinData);
    // }


    async function checkAuth() {

        if (isTokenExpired()) {
            const res = await refreshAuth();

            if (res === UN_AUTHORIZED) {
                navigate(ROUTES.LOGIN);
            }
            else {
                // fetchCheckinList();
                processCheckinData();
            }
        }
        else {
            // fetchCheckinList();
            processCheckinData();
        }
    }

    async function onCheckin() {
        console.log(today);
        if (today) {
            const res = await checkin(today._id);

            console.log(res);

            if (typeof res === 'string') {
                toast.error(res);
            }
            else {
                toast.success('Checked in successfully');
                const updatedToday = {
                    ...today,
                    isCheckIn: true
                }
                setCheckinData(prevCheckinData =>
                    prevCheckinData.map(ch => (ch._id === updatedToday._id ? updatedToday : ch))
                );
                setToday(updatedToday);
                // dispatch(fetchUserInfo(userData.id));
                const res = await getUserInfo(userData.id);
                dispatch(setUserData(res.data));
            }
        }
    }

    useEffect(() => {
        const difference = moment.utc(1700601541 * 1000 + 3600 * 1000).fromNow();
        console.log(difference, today);
        setReady(true);
        if (ready) {
            console.log('hook called');
            checkAuth();
        }
    }, [ready]);

    if (loading) {
        return <div className="card flex flex-col items-center justify-center min-h-screen main-bg pb-12">
            <ProgressSpinner />
            <span className="text-sm text-gray-500 mt-2">Loading...</span>
        </div>
    }
    else return (
        <div className="flex flex-col items-center py-2 w-full mb-8 h-auto checkin-container main-bg min-h-screen">
            <div className="flex flex-row items-center justify-between w-full shadow-md">
                <i className="pi pi-arrow-left text-gray-200 p-2 cursor-pointer text-sm" onClick={() => navigate(-1)} />
                <span className="text-sm">{t('checkin')}</span>
                <div className="px-4"></div>
            </div>
            <div className="flex flex-row items-center justify-between  gap-1 w-full px-2">
                <div className="flex flex-row items-center justify-start gap-1 w-full px-2">
                    <img src={assets_path("avatar.jpg")} alt="" className="rounded-full w-[50px]" />
                    <div className="flex flex-col items-start py-8">
                        <span className="text-xs">{t('continue_signin_for_1_day')}</span>
                        <span className="text-xs text-gray-400">{t('tomorrow_checkin_reward')}</span>
                    </div>
                </div>
                <div className="flex flex-row gap-1 items-center justify-center px-3 py-1 rounded-sm bg-[#D9D9D910]">
                    <img src={assets_path("imgs/checkin-diamond.svg")} className="" />
                    <span className="text-sm">{userData?.diamonds}</span>
                </div>
            </div>
            <div className="flex flex-col items-start justify-between gap-1 w-full px-3">
                <div className="flex flex-col items-start justify-between gap-1 w-full p-2 rounded-sm bg-[#262A32] text-gray-700 shadow-sm  shadow-gray-800">
                    <span className="text-sm font-medium text-gray-300 my-1">{t('checkin_and_get')}</span>
                    <div className="grid grid-cols-2 md:grid-cols-4 sm:grid-cols-2 gap-2 w-full mx-auto">
                        {checkinData.length
                            ? checkinData.map((checkin) => (
                                <div key={checkin._id} className="mx-auto">
                                    <DiamondCard checkin={checkin} t={t} />
                                </div>
                            ))
                            : <></>
                        }
                    </div>
                    <button className={`bg-pink-600 text-white p-2 text-center w-full mt-2 rounded-sm text-sm flex flex-row items-center justify-center`}
                        onClick={onCheckin}
                    >
                        {t('sign_in')}
                    </button>
                    {/* {isCurrentDay ? <button className={`bg-pink-600 text-white p-2 text-center w-full mt-2 rounded-sm text-sm flex flex-row items-center justify-center`}
                        onClick={() => navigate(ROUTES.WHEEL_OF_FORTUNE)}
                    >
                        {lang.sign_in}
                    </button>
                        :
                        <button className={`bg-gray-600 text-white p-2 text-center w-full mt-2 rounded-sm text-sm flex flex-row items-center justify-center`}
                            onClick={() => navigate(ROUTES.WHEEL_OF_FORTUNE)}
                            disabled
                        >
                            {lang.sign_in}
                        </button>
                    } */}
                </div>
            </div>
            <div className="flex flex-col items-start justify-between gap-1 w-full px-3 my-2 text-gray-700 p-2">
                <div className="flex flex-col items-start justify-between gap-1 w-full p-2 rounded-sm bg-[#262A32] text-gray-700 shadow-sm  shadow-gray-800">
                    <span className="text-sm font-medium text-gray-300">{t('finish_task_get_more')}</span>
                    <div className="flex flex-col items-start justify-start w-full gap-2">
                        <DiamondTask days="3" bonus="20" t={t} language={language} />
                        <DiamondTask received t={t} language={language} />
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    );
}

export default Checkin;