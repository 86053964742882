import { GET_VIDEO_TYPE, SELECTED_VIDEO, SET_VIDEO_TYPE } from "../actions/videoAction";

const initialState = {
    selectedVideo: null,
    all_videos: [],
    videoType: 1
}

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VIDEO_TYPE: {
            return action.videoType;
        }
        case SELECTED_VIDEO: {
            return {
                ...state,
                selectedVideo: action.payload
            }
        }
        case SET_VIDEO_TYPE: {
            return {
                ...state,
                videoType: action.payload
            }
        }
        default:
            return state;
    }
}

export default videoReducer;