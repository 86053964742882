import { baseURL } from "./axiosInterceptor";

export const endpoints = {
    EMAIL_REGISTER: baseURL + "emailRegister",
    UERNAME_REGISTER: baseURL + "userNameRegister",
    LOGIN: baseURL + "auth/login",
    LOGOUT: baseURL + "auth/logout",
    REFRESH_TOKEN: baseURL + "auth/refresh",
    GET_EMAL: baseURL + "getEmail",
    FORGET_PASSWORD: baseURL + "forgetPassword",
    BOUND_EMAIL: baseURL + "user/boundEmail",
    REGISTER_CHAT: baseURL + "registerChat",
    GET_BANNER: baseURL + "getBanner",
    GET_VIP_LIST: baseURL + "getVipList",
    GET_VIDEO_TYPE: baseURL + "video/getVideoType", // type = category
    GET_VIDEO_LIST: baseURL + "video/getVideoList",
    LIKE_VIDEO: baseURL + "video/likeVideo",
    HARBOR_VIDEO: baseURL + "video/harborVideo", // add to favorite
    DELETE_HARBOR_VIDEO: baseURL + "video/deleteHarborVideo", // delete from favorite
    GET_MY_FAVORITE_VIDEOS: baseURL + "video/getMyLikeVideoList",
    GET_MY_HARBOR_VIDEOS: baseURL + "video/getMyHarborVideoList",
    GET_VIDEO_TAGS: baseURL + "video/getVideoTags",
    GET_VIDEO_TAGS_BY_ID: baseURL + "video/getVideoTagById",
    ATTENTION_USER: baseURL + "user/attentionUser",
    CANCEL_ATTENTION: baseURL + "user/cancelAttention",
    GET_MY_WATCHLIST: baseURL + "user/getMyWatchlist",
    GET_WATCH_MY_LIST: baseURL + "user/getWatchMylist",
    GET_USER_INFO: baseURL + "getUserInfo",
    BROWSE_VIDEO: baseURL + "video/browseVideo",
    CHECK_IN_LIST: baseURL + "check_in/getCheckInList",
    CHECK_IN: baseURL + "check_in/checkIn",
    SEARCH_VIDEO: baseURL + "video/searchVideo",
    OPEN_VIP: baseURL + "vip/runVip",
    GET_CONTEST_BANNER: baseURL + "contest/getBanner",
    GET_CONTEST_VIDEO: baseURL + "contest/getContestVideo",
    GET_INFLUENCER_VIDEOS : baseURL + "video/getInfluencerVideoList",
    GET_USERS_VIDEOS: baseURL + "myVideo"
}
